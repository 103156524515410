/// <reference path="ientity.entity.ts" />

import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/finally';

import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { SearchEntity } from './Search.Entity';
import { IEntity } from './IEntity.Entity';
import { Observable } from 'rxjs';

@Injectable()
export class HttpService<Entity> {
  public PendingRequests: number = 0;
  public ShowLoading: boolean = false;
  public url: string = "";
  public ReportCode: string="";
  constructor(public http: HttpClient) {
  }
  Filter(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<any> {
    SearchParam = SearchParam === undefined ? new SearchEntity() : SearchParam;
    return this.intercept(this.http.get<any>(this.url, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }

  Gets(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<any> {
    SearchParam = SearchParam === undefined ? new SearchEntity() : SearchParam;
    return this.intercept(this.http.get<any>(this.url, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }
  GetCustom(SearchParam?: SearchEntity, IsShowLoading?: boolean, Link?: string): Observable<any> {
    SearchParam = SearchParam === undefined ? new SearchEntity() : SearchParam;
    return this.intercept(this.http.get<any>(this.url + `/${Link}`, {
        observe: 'response',
        headers: this.GetHeaders(),
        params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }
  GetRefNo(): Observable<any> {
    return this.intercept(this.http.get<string>(this.url + '/GetRefNo', {
      observe: 'response',
      headers: this.GetHeaders()
    }), false).map(r => r.body);
  }
  GetRefNoByType(type: number): Observable<any> {
    return this.intercept(this.http.get<string>(this.url + '/GetRefNoByType/' + type.toString(), {
      observe: 'response',
      headers: this.GetHeaders()
    }), false).map(r => r.body);
  }

  GetCurrent(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<Entity[]> {
    SearchParam = SearchParam === undefined ? new SearchEntity() : SearchParam;
    return this.intercept(this.http.get<Entity>(this.url + '/Current', {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }

  Count(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<number> {
    SearchParam = SearchParam === undefined ? new SearchEntity() : SearchParam;
    return this.intercept(this.http.get<number>(this.url + '/Count', {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }


  GetId(Id: string, IsShowLoading?: boolean): Observable<Entity> {
    return this.intercept(this.http.get<Entity>(`${this.url}/${Id}`, {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }

  Create(body: IEntity, IsShowLoading?: boolean): Observable<Entity> {
    return this.intercept(this.http.post<Entity>(this.url, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }

  Approval(body: IEntity, Id: string, IsShowLoading?: boolean): Observable<Entity> {
    return this.intercept(this.http.put<Entity>(this.url + `/${Id}/Approval`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  Reject(body: IEntity, Id: string, IsShowLoading?: boolean): Observable<Entity> {
    return this.intercept(this.http.put<Entity>(this.url + `/${Id}/Reject`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  Update(body: IEntity, IsShowLoading?: boolean): Observable<Entity> {
    return this.intercept(this.http.put<Entity>(`${this.url}/${body.ID}`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }

  Delete(Id: string, IsShowLoading?: boolean): Observable<any> {
    return this.intercept(this.http.delete(`${this.url}/${Id}`, {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }

  Export(data: any) {
    return window.open(`${this.url}/${data.Id}` + "/Export");
  }

  PrintVoucher(data: any) {
    var reporturl = "http://app.megacrm.vn/" + data.Area + "/Report/ShowReport?bsnID=" + data.ID + "&CompanyID=" + data.CompanyID + "&ReportCode=" + this.ReportCode;
    return window.open(reporturl);
  }

  public intercept(observable: Observable<HttpResponse<any>>, isShowLoading?: boolean): Observable<HttpResponse<any>> {
    if (isShowLoading == null) {
      //console.warn('isShowLoading not setted!');
      isShowLoading = true;
    }
    if (isShowLoading) this.turnOnModal();
    return observable
      .do((res: HttpResponse<any>) => {
        //console.log('Response: ' + res);
      }, (err: any) => {
        if (isShowLoading) this.turnOffModal();
        console.log('Caught error: ' + err);
      })
      .finally(() => {
        if (isShowLoading) this.turnOffModal();
      });
  }

  GetHeaders(): HttpHeaders {
    let headers = new HttpHeaders({ 'content-type': 'application/json; charset=utf-8;application/x-www-form-urlencoded' });
    return headers;
  }

  private turnOnModal() {
    this.PendingRequests++;
    if (!this.ShowLoading) {
      this.ShowLoading = true;
      document.body.classList.add("m-page--loading-non-block");
      //console.log('Turned on modal');
    }
    this.ShowLoading = true;
  }

  private turnOffModal() {
    this.PendingRequests--;
    if (this.PendingRequests <= 0) {
      if (this.ShowLoading) {
        this.PendingRequests = 0;
        document.body.classList.remove("m-page--loading-non-block");
      }
      this.ShowLoading = false;
    }
    //console.log('Turned off modal');
  }
}
