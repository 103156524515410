/// <reference path="../modules/newfeed/newfeed.service.ts" />
import { Component, OnInit, NgZone, HostListener } from '@angular/core';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UserInfoEntity } from '../Modules/Admin/Models/UserInfo.Entity';
import { AvartarUserInfoEntity } from '../Modules/Admin/Models/AvartarUserInfo.Entity';
import { ShortUserInfoEntity } from '../Modules/Admin/Models/ShortUserInfo.Entity';
import { CommonCompoenent } from '../app.component';
import { UserInfoService } from '../Modules/Admin/Models/UserInfo.Service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TranslateService } from '@ngx-translate/core';
import { SignalRService } from '../Modules/SignalRMessage/Services/SignalR.Service';
import { MessageEntity } from '../Modules/SignalRMessage/Models/Message.Entity';
import { Title } from '@angular/platform-browser';
import { NewFeedService } from '../Modules/NewFeed/NewFeed.Service';
import { SearchEntity } from '../Modules/Search.Entity';
import { Router } from '@angular/router';
import { globals } from 'src/app/globals';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AccountService } from '../Modules/Account/Models/Account.Service';
import { AnalystUserInfoEntity } from '../Modules/Admin/Models/AnalystUserInfo.Entity';
import { BusinessService } from '../Modules/Account/Models/Business.Service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
@HostListener('scroll', ['$event'])

export class HeaderComponent extends CommonCompoenent<UserInfoEntity> implements OnInit {
  MessageCount: number = 0;
  MessageList: MessageEntity[] = [];
  lang: string = "vn";
  ShowRegistry: boolean = false;
  ShowServiceExtension: boolean = false;
  searchEntity: SearchEntity = new SearchEntity();
  UserInfoEntity: UserInfoEntity = new UserInfoEntity();
  AvartarUserInfoEntity: AvartarUserInfoEntity = new AvartarUserInfoEntity();
  AnalystUserInfoEntity: AnalystUserInfoEntity = new AnalystUserInfoEntity();
  ShortUserInfoEntity: ShortUserInfoEntity = new ShortUserInfoEntity();
  constructor(private router: Router, public UserInfoService: UserInfoService, public ToastrManager: ToastrManager,
    public TranslateService: TranslateService,
    public ngxSmartModalService: NgxSmartModalService,
    public signalrService: SignalRService,
    public newFeedService: NewFeedService,
    public AccountService: AccountService,
    public BusinessService: BusinessService,
    public globalparam: globals,
    public _ngZone: NgZone,
    public title: Title) {
    super(UserInfoService, ToastrManager);
    if (this.UserInfoService.UserInfoEntity == undefined || this.UserInfoService.UserInfoEntity == null) {

      this.UserInfoService.GetCurrentUser();
    }
    this.AccountService.GetUserInfo().subscribe(t => {
      Object.assign(this.UserInfoEntity, t);
      Object.assign(this.AvartarUserInfoEntity, t);
    });

    this.BusinessService.GetLicenseInfo().subscribe(p => {
      this.ShowServiceExtension = p.ShowServiceExtension;
    })

    this.AccountService.GetAnalystUserInfo().subscribe(t => {
      Object.assign(this.AnalystUserInfoEntity, t);
    });

    TranslateService.addLangs(['en', 'vn']);
    TranslateService.setDefaultLang('vn');
    this.LoadNewFeed();
    this.subscribeToEvents();
  }

  ngOnInit() {
    if (window.location.origin.includes("demo.topcrm.vn") || window.location.origin.includes("localhost"))
      this.ShowRegistry = true;
  }
  private subscribeToEvents(): void {
    this.signalrService.messageReceived.subscribe((message: any) => {

      this._ngZone.run(() => {
        let mes: MessageEntity = new MessageEntity();
        mes.UserID = message.userID;
        mes.UserName = message.userName;
        mes.CreatedUserID = message.createdUserID;
        mes.CreatedUserName = message.createdUserName;
        mes.Message = message.message;
        mes.Action = message.action;
        mes.ActionName = message.actionName;
        mes.Type = message.type;
        mes.TypeName = message.typeName;
        mes.TypeLink = message.typeLink;
        mes.RefID = message.refID;
        mes.RefNo = message.refNo;
        mes.RefName = message.refName;
        mes.MessageTime = message.messageTime;
        mes.IsRead = message.isRead;
        this.MessageList.splice(0, 0, mes);
        this.MessageCount++;
        this.title.setTitle("(" + this.MessageCount + ") " + this.globalparam.options.PageTitle);
        if (mes.Type == 99) {
          if (this.globalparam.UserEntity.ID == mes.UserID)
            this.toastr.infoToastr(mes.CreatedUserName + " vừa " + mes.ActionName + " " + mes.TypeName + " cho bạn", 'Thông báo');
          else
            this.toastr.infoToastr(mes.CreatedUserName + " vừa " + mes.ActionName + " " + mes.TypeName + " cho " + mes.UserName, 'Thông báo');
        }
        else if (mes.Type == 86) {
          this.toastr.infoToastr(mes.CreatedUserName + " vừa " + mes.ActionName + " " + mes.TypeName + " cho " + mes.UserName, 'Thông báo');
        }
        else {
          if (this.globalparam.UserEntity.ID != mes.UserID)
            this.toastr.infoToastr(mes.CreatedUserName + " vừa " + mes.ActionName + " " + mes.TypeName + " " + mes.RefNo, 'Thông báo');
        }

      });
    });
  }

  changelanguage(lang) {
    if (lang === 1)
      this.lang = 'vn';
    else
      this.lang = 'en';

    //this.setCookie("lang", LanguageEntity.Code, "");
    this.TranslateService.use(this.lang);

  }
  scrollToEnd(event) {
    // do tracking
    // console.log('scrolled', event.target.scrollTop);
    // Listen to click events in the component
    let tracker = event.target;

    let limit = tracker.scrollHeight - tracker.clientHeight;

    if (event.target.scrollTop === limit) {
      //Load more data here
      this.searchEntity.PageIndex = this.searchEntity.PageIndex + 1;
      this.newFeedService.Filter(this.searchEntity, false).subscribe(p => {
        this.MessageList = [...this.MessageList, ...p.ListEntity];
      }, e => {
        //this.toastr.ShowError(e.error);
        this.toastr.successToastr(e.error, 'Thông báo');

      });
    }
  }
  Reset() {
    this.MessageCount = 0;
    this.title.setTitle(this.globalparam.options.PageTitle);
  }

  SignOut() {
    let mes: string;
    mes = 'Bạn có chắc muốn đăng xuất không?';
    let ConfirmBox = confirm(mes);
    if (ConfirmBox) {

      this.UserInfoService.UserInfoEntity = null;
      //document.cookie = "UserToken= ''" + ";-1";
      this.router.navigate(['/']);
    }
  }
  LoadNewFeed() {
    this.newFeedService.Filter(this.searchEntity, false).subscribe(p => {
      this.MessageList = p.ListEntity;

    }, e => {
      //this.toastr.ShowError(e.error);
      this.toastr.successToastr(e.error, 'Thông báo');

    });
  }

  UploadLogo(event) {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length != 1) {
      event.target.parentNode.reset();
      throw new Error("Cannot upload multiple files on the entry")
    }
    const reader = new FileReader();
    let f = target.files[0];

    reader.readAsDataURL(f);

    reader.onloadend = (e => { //callback after files finish loading
      this.AvartarUserInfoEntity.FileName = f.name;
      this.AvartarUserInfoEntity.FileData = reader.result;
      this.AvartarUserInfoEntity.FileData = this.AvartarUserInfoEntity.FileData.substr(this.AvartarUserInfoEntity.FileData.indexOf(',') + 1);
      //Đoạn này gán soure base64 cho image tag
    });
  }

  ShowUserProfile() {
    this.ngxSmartModalService.getModal('modalUserProfile').open();

  }
  SaveProfile() {
    this.AccountService.UpdateProfile(this.UserInfoEntity).subscribe(p => {
      this.toastr.successToastr("Cập nhật thông tin thành công", 'Thông báo');
    });
  }

  SaveAvartar() {
    this.AccountService.UpdateAvartar(this.AvartarUserInfoEntity).subscribe(p => {
      this.toastr.successToastr("Cập nhật avartar thành công", 'Thông báo');
      this.AvartarUserInfoEntity.FilePath = null;
      this.AvartarUserInfoEntity.FilePath = this.UserInfoEntity.CompanyCode + '/' + this.UserInfoEntity.UserName + '/' + this.AvartarUserInfoEntity.FileName;
      //location.reload();
    });
  }

  ChangePass() {
    if (this.ValidatePass()) {
      this.AccountService.ChangePassword(this.ShortUserInfoEntity).subscribe(p => {
        if (p.MsgCode == "00")
          this.toastr.successToastr("Đổi mật khẩu thành công", 'Thông báo');
        else
          this.toastr.errorToastr(p.MsgName, 'Thông báo');
      });
    }
  }

  ValidatePass() {
    if (this.ShortUserInfoEntity.NewPassword == undefined || this.ShortUserInfoEntity.NewPassword.trim() == "") {
      this.toastr.warningToastr("Bạn chưa nhập mật khẩu mới", 'Thông báo');
      return false;
    }
    if (this.ShortUserInfoEntity.ReNewPassword == undefined || this.ShortUserInfoEntity.ReNewPassword.trim() == "") {
      this.toastr.warningToastr("Bạn chưa nhập lại mật khẩu mới", 'Thông báo');
      return false;
    }
    if (this.ShortUserInfoEntity.ReNewPassword.trim() != this.ShortUserInfoEntity.NewPassword.trim()) {
      this.toastr.warningToastr("Nhập lại mật khẩu không chính xác", 'Thông báo');
      return false;
    }
    return true;
  }
}
