import { IEntity } from "../../IEntity.Entity";
import { formatDate } from "@angular/common";
export class EmailAttachmentEntity extends IEntity {
  ID: string;
  FileName: string;
  FileData: any;

  FilePath: string;
  CompanyID: string;
  EmailID: string;
  public constructor(Partner: any = null) {
    super();
  }
}
