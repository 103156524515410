
import { IEntity } from "../../../IEntity.Entity";

export class UserEntity extends IEntity {
  ID: string;
  UserName: string;
  PassWord: string;
  FullName: string;
  Title: string;
  PhoneNumber: string;
  IDNumber: string;
  Address: string;
  UserGroupID: string;
  CompanyID: string;
  IsVisible: boolean;
  BranchID: string;
  CreatedDate: string;
  IsOnline: boolean;
  LastLogin: string;
  Email: string;
  public constructor() {
    super();

  }
}
