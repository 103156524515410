
import { SearchEntity } from "../../Search.Entity";

export class TotalCustomerRequestEntity extends SearchEntity {
  ContactCategoryID: string;
  ContactCategoryName: string;
  ContactTypeID: string;
  ContactTypeName: string;
  StageID: string;
  StageName: string;
  ContactSourceID: string;
  SourceName: string;
  FromDate: string;
  ToDate: string;
  RefType: number;
  CaseGet: number;
  PageIndex: number;
  PageSize: number;
  public constructor() {
    super();
  }
}
