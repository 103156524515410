import { Pipe, NgModule } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe {
    constructor(private sanitized: DomSanitizer) {
    }
    transform(value: string) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}

@NgModule({
    declarations: [
        SafeHtmlPipe
    ],
    imports: [
      FormsModule, CommonModule, ReactiveFormsModule
    ],
    exports: [
        SafeHtmlPipe    
    ]
  })
  export class SafeHtmlModule { }