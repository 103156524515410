import { Component, OnInit, Input, NgModule, OnChanges } from '@angular/core';
import { EmailEntity } from 'src/app/Modules/Mail/Models/Email.Entity';
import { SafeHtmlModule } from 'src/app/shared/pipe.safehtml';
import { QuillModule } from 'ngx-quill';
import { EmailReceiptService } from 'src/app/Modules/Mail/Models/EmailReceipt.Service';
import { ToastrManager, ToastrModule } from 'ng6-toastr-notifications';
import { EmailAttachmentEntity } from 'src/app/Modules/Mail/models/EmailAttachment.Entity';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CustomInputModule } from '../custominput/custominput.component';
import { NgxSmartModalService } from 'ngx-smart-modal';
@Component({
  selector: 'app-composeemail',
  templateUrl: './composeemail.component.html',
  styleUrls: ['./composeemail.component.css']
})
export class ComposeemailComponent implements OnInit, OnChanges {
  NewEmail: EmailEntity = new EmailEntity();
  @Input() EmailReceiver: string;
  @Input() IDSmartModal: string;
  constructor(public EmailReceiptService: EmailReceiptService, public ToastrManager: ToastrManager,
    private ngxSmartModalService: NgxSmartModalService) {
   
   
  }

  ngOnInit() {

    this.NewEmail.To = this.EmailReceiver;
    this.NewEmail.Errors = [];
  }
  ngOnChanges() {
    this.NewEmail.To = this.EmailReceiver;
    this.NewEmail.Errors = [];
  }
  GetError(Dic, Key: string) {
    if (this.HasOwnProperty(Dic, Key))
      return Dic[Key];
    else
      return null;
  }

  HasOwnProperty(Dic, Key: string) {
    if (Dic !== null && Dic != undefined) {
      return Dic.hasOwnProperty(Key);
    } else {
      return false;
    }
  }
  CloseCompose() {
    this.ngxSmartModalService.getModal(this.IDSmartModal).close();
  }

  SendEmail() {
    if (this.ValidateDate()) {
      this.EmailReceiptService.SendEmail(this.NewEmail).subscribe(p => {
        if (p) {
          this.ToastrManager.successToastr("Gửi email thành công.", "Thông báo");
          this.ngxSmartModalService.getModal(this.IDSmartModal).close();
        }
      });
    }
    else
      this.ToastrManager.warningToastr("Vui lòng nhập đủ thông tin bắt buộc.", "Thông báo");
  }

  ValidateDate() {
    this.NewEmail.Errors = [];
    let bResult: boolean = true;
    if (this.NewEmail.To == null || this.NewEmail.To.trim() == "") {
      bResult = false;
      this.NewEmail.Errors["To"] = "Email người nhận không được để trống!";
    }
    else
      delete this.NewEmail.Errors["To"];

    if (this.NewEmail.Subject == null || this.NewEmail.Subject.trim() == "") {
      bResult = false;
      this.NewEmail.Errors["Subject"] = "Tiêu đề email không được trống!";
    }
    else
      delete this.NewEmail.Errors["Subject"];

    if (this.NewEmail.HtmlContent == null || this.NewEmail.HtmlContent.trim() == "") {
      bResult = false;
      this.NewEmail.Errors["HtmlContent"] = "Nội dung email không được trống!";
    }
    else
      delete this.NewEmail.Errors["HtmlContent"];

    return bResult;
  }

  UploadFile(event) {
    const target: DataTransfer = <DataTransfer>(event.target);
    
    for (let i = 0; i < target.files.length; i++) {
      const reader = new FileReader();
      let f = target.files[i];
      reader.readAsDataURL(f);
      reader.onloadend = (e => { //callback after files finish loading
        let eAttachment: EmailAttachmentEntity = new EmailAttachmentEntity();

        eAttachment.FileName = f.name;;
        eAttachment.FileData = reader.result;
        eAttachment.FileData = eAttachment.FileData.substr(eAttachment.FileData.indexOf(',') + 1);
        this.NewEmail.ListAttachment.push(eAttachment);
        //Đoạn này gán soure base64 cho image tag
      });
    }
  }

}

@NgModule({

  declarations: [
    ComposeemailComponent,
  ],
  imports: [
    FormsModule, CommonModule, ReactiveFormsModule, ToastrModule.forRoot(), SafeHtmlModule, CustomInputModule,
    QuillModule.forRoot()
  ],
  exports: [
    ComposeemailComponent
  ]
})
export class ComposeemailModule { }
