import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './Modules/home/home.component';
import { ServedComponent } from './Modules/Sale/Views/PointOfSale/served/served.component';

const routes: Routes = [
  {
    path: 'Home',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'Home',
    pathMatch: 'full'
  },
  {
    path: '*',
    redirectTo: 'Home',
    pathMatch: 'full'
  },
  {
    path: '#',
    redirectTo: 'Home',
    pathMatch: 'full'
  },
  {
    path: 'Contact',
    loadChildren: './Modules/Contact/Contact.module#ContactModule'
  },
  {
    path: 'Quote',
    loadChildren: './Modules/Quote/Quote.module#QuoteModule'
  },
  {
    path: 'Order',
    loadChildren: './Modules/Order/Order.module#OrderModule'
  },
  {
    path: 'Contract',
    loadChildren: './Modules/Contract/Contract.module#ContractModule'
  },
  {
    path: 'Product',
    loadChildren: './Modules/Product/Product.module#ProductModule'
  },
  {
    path: 'Purchase',
    loadChildren: './Modules/Purchase/Purchase.module#PurchaseModule'
  },
  {
    path: 'Sale',
    loadChildren: './Modules/Sale/Sale.module#SaleModule'
  },
  {
    path: 'CashPayment',
    loadChildren: './Modules/Payment/CashPayment.module#CashPaymentModule'
  },
  {
    path: 'CashReceipt',
    loadChildren: './Modules/Receipt/CashReceipt.module#CashReceiptModule'
  },
  {
    path: 'System',
    loadChildren: './Modules/System/System.module#SystemModule'
  },
  {
    path: 'Account',
    loadChildren: './Modules/Account/Account.module#AccountModule'
  },
  {
    path: 'Dictionary',
    loadChildren: './Modules/Dictionary/Dictionary.module#DictionaryModule'
  },
  {
    path: 'Role',
    loadChildren: './Modules/Role/Role.module#RoleModule'
  },
  {
    path: 'Report',
    loadChildren: './Modules/Report/Report.module#ReportModule'
  },
  {
    path: 'Camp',
    loadChildren: './Modules/Camp/Camp.module#CampModule'
  },
  {
    path: 'Task',
    loadChildren: './Modules/Task/Task.module#TaskModule'
  },
  {
    path: 'Partner',
    loadChildren: './Modules/Partner/Partner.module#PartnerModule'
  },
  {
    path: 'Mail',
    loadChildren: './Modules/Mail/Mail.module#MailModule'
  },
  {
    path: 'Marketing',
    loadChildren: './Modules/Marketing/Marketing.module#MarketingModule'
  },
  {
    path: 'IOStock',
    loadChildren: './Modules/IOStock/IOStock.module#IOStockModule'
  },
  {
    path: 'DeliveryOrder',
    loadChildren: './Modules/DeliveryOrder/DeliveryOrder.module#DeliveryOrderModule'
  },
  {
    path: 'DeliveryOrderReturn',
    loadChildren: './Modules/DeliveryOrderReturn/DeliveryOrderReturn.module#DeliveryOrderReturnModule'
  },
  {
    path: 'SaleReturn',
    loadChildren: './Modules/SaleReturn/SaleReturn.module#SaleReturnModule'
  },
  {
    path: 'Promotion',
    loadChildren: './Modules/Promotion/Promotion.module#PromotionModule'
  },
  {
    path: 'Served',
    component: ServedComponent,
    data: {
      title: 'Sale create',
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
