import { IEntity } from "../../IEntity.Entity";
import { ToDoStepEntity } from "./ToDoStep.Entity";
export class ToDoEntity extends IEntity {
  ID: string;
  TaskTagID: string;
  ToDoName: string;
  CompanyID: string;
  OrderNumber: number;
  IsAddNew: boolean = false;
  ShowDetail: boolean = false;
  TotalComplete: number = 0;
  TotalStep: number = 0;
  TotalWeight: number = 0;
  PercentComplete: number;
  ToDoStepEntity: ToDoStepEntity = new ToDoStepEntity();
  public constructor() {
    super();
    this.TotalStep = 0;
    this.TotalComplete = 0;
    this.TotalWeight = 0;
  }
}
