import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Rx';
import { Http } from '@angular/http';
import { Injectable } from '@angular/core';
import { UserInfoEntity } from './UserInfo.Entity';
import { FullUserInfoEntity } from './FullUserInfo.Entity';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../../HttpService';
import { IEntity } from '../../IEntity.Entity';
import { observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UserInfoService extends HttpService<UserInfoEntity> {
  UserInfoEntity: UserInfoEntity = null;
  FullUserInfoEntity: FullUserInfoEntity = null;
  constructor(private Http: HttpClient) {
    super(Http);
    this.url = "api/Auth";
  }
  GetCurrentUser(): UserInfoEntity {
    if (this.UserInfoEntity == undefined || this.UserInfoEntity == null) {    
      let UJWT = this.GetCookie("ERPUserToken");
      if (UJWT == "''") {
        UJWT = undefined;
      }
      let UJWTFull = this.GetCookie("ERPUserTokenFull");
      if (UJWTFull == "''") {
        UJWTFull = undefined;
      }

      if (UJWT != "" && UJWT != undefined && UJWTFull != "" && UJWTFull != undefined) {
        let User = this.DecodeUserInfo(UJWT);
        let UserFull = this.DecodeUserInfoFull(UJWTFull);
        Object.assign(User.UserEntity, UserFull.UserEntity);
        this.UserInfoEntity = User.UserEntity;
        
      }
      else {
        //console.error("Cannot recognize UserToken!");
        return null;
      }

    }
    return this.UserInfoEntity;
  }

  GetFullUser(UserID: string, IsShowLoading?: boolean): Observable<FullUserInfoEntity> {
   return  this.intercept(this.http.get<FullUserInfoEntity>(this.url + '/' + UserID + '/GetCurrentUser',  {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }

  DecodeUserInfo(data) {
    let User = data.split(".")[1];
    if (User == null) {
      console.error("Cannot recognize ERPUserToken!");
      return;
    }
    let UserInfo = JSON.parse(this.b64DecodeUnicode(User));
    return UserInfo;
  }

  DecodeUserInfoFull(data) {
    let User = data.split(".")[1];
    if (User == null) {
      console.error("Cannot recognize ERPUserToken!");
      return;
    }
    let UserInfo = JSON.parse(this.b64DecodeUnicode(User));
    return UserInfo;
  }

  b64DecodeUnicode(str) {
    str = str.replace('-', '+').replace('_', '/');
    switch (str.length % 4) {
      case 0:
        break;
      case 2:
        str += '==';
        break;
      case 3:
        str += '=';
        break;
      default:
        throw 'Illegal base64url string!';
    }
    return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  }

  GetCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      //Đoạn này sửa lại check trường hợp có 1 cookie trùng tên SIUserToken =''
      //sẽ lấy token chứa giá trị và bỏ qua token không có giá trị
      if (c.indexOf(name) == 0 && c.length > (name.length * 2)) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  ChangePassword(UserInfoEntity: UserInfoEntity, IsShowLoading?: boolean): Observable<UserInfoEntity> {
    return this.intercept(this.http.post<UserInfoEntity>(`${this.url}/ChangePassword`,
      JSON.stringify(UserInfoEntity), {
        observe: 'response',
        headers: this.GetHeaders()
      }), IsShowLoading).pipe(map(r => r.body));

  }
}
