import { HttpParams } from '@angular/common/http';
export class IEntity {
  EmptyGuid = '00000000-0000-0000-0000-000000000000';
  ID: string = this.EmptyGuid;
  IsEdit: boolean = false;
  IsSelected: boolean = false;
  IsActive: boolean = false;
  ListEntity: any[] = [];
  Count: number = 0;
  MsgCode: string;
  MsgName: string;
  VoucherCode: string;
  Errors: any = {} =[];
  constructor() {
    this.Errors = [];
  }

  Clone(Entity: IEntity): IEntity {
    return JSON.parse(JSON.stringify(Entity));
  }
  Init() {
    for (let key in this) {
      if (this.hasOwnProperty(key) && this[key.toString()] != null) {
        this.Errors[key] = this[key.toString()];
      }
    }
  }

  ToParams(): HttpParams {
    let params = new HttpParams();
    for (let key in this) {
      if (this.hasOwnProperty(key) && this[key.toString()] != null && this[key.toString()] != NaN) {
        params = params.set(key, this[key.toString()]);
      }
    }

    return params;
  }
}

