import { isNullOrUndefined } from "util";

export class MenuModal{
  Name: string;
  Code: string;
  ParentCode: string;
  Link: string;
  isShow: boolean;
  isActive: boolean;
  hasEndPart:boolean;
  subLink: MenuModal[] = [];
  icon: string;
  hasAction: boolean;

  constructor(Name: string, Code: string, ParentCode: string, Link: string, hasEndPart: boolean, isActive: boolean, icon?: string, HasAction?:boolean){
    this.Name = Name;
    this.Code = Code;
    this.ParentCode = ParentCode;
    this.Link = Link;
    this.hasEndPart = hasEndPart;
    this.isActive = isActive;
    this.icon = icon ? icon : ""
    this.hasAction = HasAction;
  }

  addSub(Sub: MenuModal){
    this.subLink.push(Sub);
  }

  hasSub(): boolean{
    return (!(isNullOrUndefined(this.subLink)) || this.subLink.length > 0)
  }
}
