import { OptionEntity } from "./Modules/System/Models/Option.Entity";
import { Injectable } from '@angular/core';
import { UserInfoEntity } from "./Modules/Admin/Models/UserInfo.Entity";


Injectable()
export class globals { 
  options: OptionEntity = new OptionEntity();
  UserEntity: UserInfoEntity = new UserInfoEntity();
}
