import {
  NgModule,
  Component,
  EventEmitter,
  OnInit,
  ChangeDetectorRef,
  forwardRef,
  Input,
  Output,
  ContentChild,
  TemplateRef,
  ElementRef,
  ViewChild
} from '@angular/core';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {noop} from 'rxjs/util/noop';
import {IEntity} from '../../../Modules/IEntity.Entity';
import {HttpService} from '../../../Modules/HttpService';
import {CommonModule} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {SearchEntity} from '../../../Modules/Search.Entity';
import {ClickOutSideModule} from '../../AttributeDirective/ClickOutSide.Directive';
import * as $ from 'jquery';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NgSelectCustomComponent),
  multi: true
};

@Component({
  selector: 'custom-select',
  templateUrl: './customselect.component.html',
  styleUrls: ['./customselect.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})

export class NgSelectCustomComponent<T extends IEntity> implements ControlValueAccessor, OnInit {
  @Input() Service: HttpService<IEntity> = null;
  @Input() SearchProperty = 'Name';
  @Input() Placeholder: string = 'Select ' + this.SearchProperty + '...';
  @Input() BindProperty: string = null;
  @Input() BindLabel: any = null;
  @Input() BindValue: string = null;
  @Input() Items: any[];
  @Input() Take = 10;
  @Input() IsSearch = false;
  @Input() Disabled = false;
  @Input() HTML = '';
  @Input() Multiple = false;
  @Input() HideSelected = false;
  @Input() CloseOnSelect = true;
  @Input() ClearSearchOnAdd = false;
  @Input() ErrorEntity: any = null;
  @Input() Type = 'CUSTOM';
  @Input() IsBlur = false;
  @Input() Model: any;
  @Input() BindMultiLabel: string = null;
  @Input() BindMultiValue: string = null;
  @Input() BindEntity: T;
  @Input() ArrayModel: T[];
  @Input() Path: string = null;
  @Input() GroupBy: string = null;
  @Input() AppendTo: string = null;
  @Output() Change: EventEmitter<any> = new EventEmitter();
  @Output() Blur: EventEmitter<any> = new EventEmitter();
  @ContentChild('ngSelectOption') ngSelectOption: TemplateRef<ElementRef>;
  @ContentChild('ngSelectLabel') ngSelectLabel: TemplateRef<ElementRef>;
  @ViewChild('ngSelect') ngSelect: ElementRef;
  Entities: Array<IEntity> = [];
  TempItem: any;
  TypeAhead = new Subject<string>();
  LoadingCount = false;
  IsFirstLoad = true;
  LoadingSearch = false;
  SearchEntity: SearchEntity = new SearchEntity();
  IsMoreItemEntity = false;
  TotalEntity = 0;
  flag = false;
  constant;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  constructor(private cd: ChangeDetectorRef,
              private _elementRef: ElementRef) {
    this.SearchEntity.Take = this.Take;
    if (this.Path == 'Current') {
      this.SearchEntity.Current = true;
    }
    this.TypeAhead.pipe(
      distinctUntilChanged(),
      debounceTime(200),
      switchMap(term => {
        this.LoadingSearch = true;
        this.value = term;
        this.SearchEntity.Skip = 0;
        this.Count();
        this.cd.markForCheck();
        return this.Service.Filter(this.SearchEntity, false);
      })
    ).subscribe(x => {
      if (x.ListEntity.length == this.SearchEntity.Take) {
        this.IsMoreItemEntity = true;
      }
      const temp = {};
      temp[this.BindProperty] = this.SearchEntity[this.SearchProperty];
      this.Entities = this.IsSearch ? [temp as any, ...x.ListEntity] : [...x.ListEntity];
      this.LoadingSearch = false;
      this.cd.markForCheck();
    }, () => {
      this.Entities = [];
    });

  }

  // Input String used by NgModel
  get value(): any {
    return this.SearchEntity[this.SearchProperty];
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    this.SearchEntity[this.SearchProperty] = v;
  }

  @Input()
  set Init(obj: any) {
    if (this.SearchEntity == null) {
      this.SearchEntity = new SearchEntity();
    }
    Object.assign(this.SearchEntity, obj);
    const entity = new IEntity();
    Object.assign(entity, this.SearchEntity);
    if ((entity[this.SearchProperty] !== null && entity[this.SearchProperty] !== undefined) || (entity['ID'] !== null && entity['ID'] !== undefined)) {
      this.value = new SearchEntity();
      Object.assign(this.value, entity);
    }
    this.Entities = [entity];
  }

  writeValue(obj: any): void {
    const temp = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  ngOnInit(): void {
    if (this.Service == null && this.Type !== 'NORMAL') {
      console.error('Service null');
    }
    if (this.IsSearch) {
      const t = {};
      t[this.SearchProperty] = '';
      if (this.IsSearch) {
        this.Entities.push(t as any);
      }
    }
  }

  // Show list
  public FirstLoad(event?) {
    if (this.Type !== 'NORMAL') {
      this.flag = false;
      this.LoadingSearch = true;
      this.SearchEntity.Skip = 0;
      this.SearchEntity.PageIndex = 1;
      if (typeof this.SearchEntity[this.SearchProperty] == 'object' && this.SearchEntity[this.SearchProperty] !== null) {
        this.SearchEntity[this.SearchProperty] = this.SearchEntity[this.SearchProperty][this.SearchProperty];
      }
      if (typeof this.SearchEntity['ID'] == 'object' && this.SearchEntity['ID'] !== null) {
        this.SearchEntity['ID'] = this.SearchEntity['ID']['ID'];
      }
      // this.Count();
      if (this.Path == 'Current') {
        this.SearchEntity.Current = true;
      }
      this.Service.Filter(this.SearchEntity, false).subscribe(x => {
        if (x.ListEntity) {
          if (x.ListEntity.length == this.SearchEntity.Take) {
            this.IsMoreItemEntity = true;
          }
          const temp = {};
          temp[this.BindProperty] = this.SearchEntity[this.SearchProperty];
          this.Entities = this.IsSearch ? [temp as any, ...x.ListEntity] : [...x.ListEntity];
          this.TotalEntity = x.Count;
        } else {
          if (x.length == this.SearchEntity.Take) {
            this.IsMoreItemEntity = true;
          }
          const temp = {};
          temp[this.BindProperty] = this.SearchEntity[this.SearchProperty];
          this.Entities = this.IsSearch ? [temp as any, ...x] : [...x];
          this.TotalEntity = x.length;
        }

        this.LoadingSearch = false;
        this.cd.markForCheck();
      }, () => {
        this.Entities = [];
      });
    }
    console.log(event);
  }

  public onClick(event?) {
      // Todo: Find class .table-responsive and add class .selected-dropdown
      const targetElement = $(this._elementRef.nativeElement).closest('div.table-responsive');
      if (targetElement !== null) {
          targetElement.addClass('selected-dropdown');
      }
      if (event) {
          event.stopPropagation();
      }
  }

  public FirstLoadMulti(event?) {
    // Todo: Find class .table-responsive and add class .selected-dropdown
    const targetElement = $(this._elementRef.nativeElement).closest('div.table-responsive');
    if (targetElement !== null) {
      targetElement.addClass('selected-dropdown');
    }
    this.flag = false;
    this.LoadingSearch = true;
    this.SearchEntity.Skip = 0;
    this.SearchEntity.PageIndex = 1;
    // this.Count();
    if (this.Path == 'Current') {
      this.SearchEntity.Current = true;
    }
    this.Service.Filter(this.SearchEntity, false).subscribe(x => {
      if (x.ListEntity.length == this.SearchEntity.Take) {
        this.IsMoreItemEntity = true;
      }
      const temp = {};
      this.Entities = this.IsSearch ? [temp as any, ...x.ListEntity] : [...x.ListEntity];
      this.TotalEntity = x.Count;
      this.LoadingSearch = false;
      this.cd.markForCheck();
    }, () => {
      this.Entities = [];
    });


    if (event) {
      event.stopPropagation();
    }
  }

  public FetchMore() {
    if (this.IsMoreItemEntity == true) {
      this.SearchEntity.PageIndex++;
      this.SearchEntity.Skip += this.SearchEntity.Take;
      this.LoadingSearch = true;
      if (this.Path == 'Current') {
        this.SearchEntity.Current = true;
      }
      this.Service.Filter(this.SearchEntity, false).subscribe(x => {
        this.Entities = [...this.Entities, ...x.ListEntity];
        if (x.ListEntity.length == 0) {
          this.IsMoreItemEntity = false;
        }
        this.LoadingSearch = false;
        this.cd.markForCheck();
      });
    }
  }

  //public FetchMoreMulti() {
  //    const len = this.Items.length;
  //    const more = this.photos.slice(len, this.bufferSize + len);
  //    this.loading = true;
  //    // using timeout here to simulate backend API delay
  //    setTimeout(() => {
  //        this.loading = false;
  //        this.photosBuffer = this.photosBuffer.concat(more);
  //    }, 200)
  //}

  public Count() {
    this.LoadingCount = true;
    this.Service.Count(this.SearchEntity, false).subscribe(x => {
      this.TotalEntity = x;
      this.LoadingCount = false;
      this.cd.markForCheck();
    });

  }

  public EmitChange(event) {
    const targetElement = $(this._elementRef.nativeElement).closest('div.table-responsive');
    if (targetElement !== null) {
      targetElement.removeClass('selected-dropdown');
    }
    if (event == null || event == undefined) {
      this.flag = false;
      this.value = null;
      this.onChangeCallback(null);
      this.Change.emit(null);
      return;
    } else {
      this.value = {};
      Object.assign(this.value, event);
      this.flag = true;
      this.Change.emit(event);
    }
  }

  public EmitChangeMulti(event) {
    if (event == null || event == undefined) {
      this.flag = false;
      this.ArrayModel = [];
      this.onChangeCallback(null);
      this.Change.emit(null);
      return;
    } else {
      this.ArrayModel = [];
      this.ArrayModel = event;
      this.flag = true;
      this.Change.emit(event);
    }
  }

  public OnClose(value) {
    if (!this.flag) {
      const object = {};
      object[this.SearchProperty] = value;
      const item = this.Entities.filter((i) => {
        return i[this.SearchProperty] == object[this.SearchProperty];
      });
      if (item.length > 0) {
        this.Change.emit(item[0]);
      }
    }
  }

  public OnBlur(event) {
    if (this.Entities.length == 0) {
      this.value = new SearchEntity();
      if (this.IsBlur) {
        this.value[this.SearchProperty] = 'Không chọn được dữ liệu...';
        return;
      }
      this.value[this.SearchProperty] = event;
      this.Blur.emit(event);
      return;
    }
    return;
  }

  public clear(item) {
  }

  public ClickOutSide() {
    const targetElement = $(this._elementRef.nativeElement).closest('div.table-responsive');
    if (targetElement !== null) {
      targetElement.removeClass('selected-dropdown');
    }
  }
}

@NgModule({
  declarations: [
    NgSelectCustomComponent,
  ],
  imports: [
    CommonModule, FormsModule, NgSelectModule, ClickOutSideModule
  ],
  exports: [
    NgSelectCustomComponent
  ]
})
export class NgSelectCustomModule {
}
