import { ToDoStepEntity } from './ToDoStep.Entity';
import { ToDoEntity } from './ToDo.Entity';
import { TaskTagUserEntity } from './TaskTagUser.Entity';
import { TaskTagManagerEntity } from './TaskTagManager.Entity';
import { TaskTagAttachmentEntity } from './TaskTagAttachment.Entity';
import { TaskTagCommentEntity } from './TaskTagComment.Entity';
import { IEntity } from "../../IEntity.Entity";
import { ToDoListEntity } from "../../Dashboard/Models/ToDoList.Entity";

import { UserEntity } from '../../Dictionary/Models/User/User.Entity';
export class TaskTagEntity extends IEntity {
  ID: string;
  TaskTagName: string;
  UserID: string;
  CreatedDate: string;
  ExpiredDate: string;
  Description: string;
  IsComplete: boolean;
  CompleteDate: string;
  CompleteUserID: string;
  IsExpired: boolean;
  IsFollow: boolean;
  CompanyID: string;
  TaskListID: string;
  TaskListName: string;
  TaskTableID: string;
  IsClosed: boolean;
  SeqID: number;
  OrderNumber: number;
  NumberTotal: number = 0;
  NumberComplete: number = 0;
  FromDate: string;
  AssignName: string;
  ToDoEntity: ToDoEntity = new ToDoEntity();
  ListToDo: Array<ToDoEntity> = [];
  ListToDoStep: Array<ToDoStepEntity> = [];
  ListUser: Array<TaskTagUserEntity> = [];
  ListManager: Array<TaskTagManagerEntity> = [];
  ManagerEntities: Array<UserEntity> = [];
  UserEntities: Array<UserEntity> = [];
  OriginManagerEntities: Array<UserEntity> = [];
  OriginUserEntities: Array<UserEntity> = [];
  ListAttachment: Array<TaskTagAttachmentEntity> = [];
  ListComment: Array<TaskTagCommentEntity> = [];
  Priority: string;
  PriorityName: string;
  FinishDate: string;
  State: number = 0;
  IsShowDashboard: boolean;
  IsSendMail: boolean;
  ReviewContent: string;
  ReviewDate: string;
  ReviewModifiedDate: string;
  StatusName: string;
  StarNumber: number;
  OrderNumberByUser: number;
  NumberAttachment: number;
  StepID: string;
  public constructor() {
    super();
    this.NumberTotal = 0;
    this.Priority = "MEDIUM";
  }
}
