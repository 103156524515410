import { IEntity } from "../../IEntity.Entity";
export class AnalystUserInfoEntity extends IEntity {
  TotalCustomer: number;
  TotalContract: number;
  TotalTask: number;
  TotalComment: number;
  public constructor() {
    super();
  }
}
