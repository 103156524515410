
import { SearchEntity } from "../../Search.Entity";

export class AccountSearchEntity extends SearchEntity {

  ID: string;
  CompanyID: string;
  UserName: string;
  PassWord: string;
  FullName: string;
  Title: string;
  PhoneNumber: string;
  IDNumber: string;
  Address: string;
  public constructor() {
    super();
  }
}
