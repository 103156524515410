/// <reference path="tasklist.entity.ts" />
import { IEntity } from "../../IEntity.Entity";

export class TaskTagManagerEntity extends IEntity {
  ID: string;
  TaskTagID: string;
  UserID: string;
  UserName: string;
  FullName: string;
  CreatedUserID: string;
  CreatedDate: string;
  public constructor() {
    super();

  }
}
