import { AutoIDEntity } from "./AutoID.Entity";
import { IEntity } from "../../IEntity.Entity";

export class OptionEntity extends IEntity {
  CompanyName: string;
  CompanyAddress: string;
  CompanyTaxCode: string;
  CompanyTel: string;
  CompanyFax: string;
  CompanyEmail: string;
  CompanyWebsite: string;
  CompanyFacebook: string;
  CompanyBankAccountNumber: string;
  CompanyBankName: string;
  CompanyBankBranch: string;
  CompanyBankAddress: string;
  DirectorName: string;
  ChiefAccountant: string;
  Treasurer: string;
  Reporter: string;
  PrintOnReport: boolean;
  GroupSeparator: string;
  DecimalSeparator: string;
  DecimalOfAmount: number;
  DecimalOfQuantity: number;
  DecimalOfUnitPrice: number;
  DecimalOfRate: number;
  IsMultiTax: boolean;
  IsMultiDiscount: boolean;
  IncommingServer: string;
  IncommingPort: number;
  UsingAccountLogonIncomming: boolean;
  UsingSSLIncommingServer: boolean;
  SettingForEachUser: boolean;
  OutingServer: string;
  OutingPort: number;
  UsingAccountLogon: boolean;
  SendEmail: string;
  PasswordSendEmail: string;
  UsingSSLOutingServer: boolean;
  AccountDisplayName: string = '';
  UsingAWS: boolean;
  AWSAccessKey: string;
  AWSSecretKey: string;
  AWSRegion: string;
  ListAutoID: Array<AutoIDEntity> = [];
  DayEndContract: number;
  DayEndInvoice: number;
  DayEndWork: number;
  ShowInfoContract: boolean;
  ShowInfoCashReceipt: boolean;
  ShowInfoCashPayment: boolean;
  CssLogoData: any;
  LogoWidth: number;
  LogoHeight: number;
  PageTitle: string;
  PageLogoName: string;
  PageLogoPath: string;
  PageLogoData: any;
  PageLogoWidth: number;
  PageLogoHeight: number;
  AllowEditCode: boolean;
  RequiredMarketContact: boolean;
  RequiredFileProcessing: boolean;
  SaleAmountMethod: number;
  ShowServiceExtension: boolean;
  Retail: boolean;//Bán lẻ
  CommercialBusiness: boolean;//Thương mại
  Pharmacy: boolean;//Dược
  Fashion: boolean;//Thời trang
  Spa: boolean;//làm đpẹ
  ConsultingStudy: boolean;//Tư vấn du học
  Food: boolean;// Thực phẩm
  Restaurant: boolean;//Nhà hàng
  ApplyPromotion: boolean

  constructor() {
    super();
    this.DayEndContract = 0;
    this.DayEndInvoice = 0;
    this.DayEndWork = 0;
    this.IncommingPort = 0;
    this.OutingPort = 0;
    this.DecimalOfAmount = 0;
    this.DecimalOfQuantity = 0;
    this.DecimalOfRate = 0;
    this.DecimalOfUnitPrice = 0;
    this.LogoWidth = 100;
    this.LogoHeight = 100;
  }

}

