import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserInfoService } from '../Modules/Admin/Models/UserInfo.Service';
import { MenuModal } from './menuModal'
import { NgIfContext } from '@angular/common';
import { element } from '@angular/core/src/render3';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-headerbottom',
  templateUrl: './headerbottom.component.html',
  styleUrls: ['./headerbottom.component.css']
})
export class HeaderbottomComponent implements OnInit {

  MenuList: MenuModal[] = [];
  constructor(public TranslateService: TranslateService,
    public UserInfoService: UserInfoService,
    public Route: ActivatedRoute,
    public Router: Router) {
    TranslateService.addLangs(['en', 'vn']);
    TranslateService.setDefaultLang('vn');
  }



  ngOnInit() {
    // khách hang
    if (this.UserInfoService.UserInfoEntity.HasContact) {
      let Customer = new MenuModal('Module.Customer', 'Customer', null, 'Contact/Contacts', false, false, "ti-user");
      this.MenuList.push(Customer);
      let CustomerList = new MenuModal('Customer.CustomerList', 'CustomerList', 'Customer', 'Contact/Contacts', false, false);
      Customer.addSub(CustomerList);
      let ContactCreate = new MenuModal('Common.Add', 'ContactCreate', 'Customer', 'Contact/ContactCreate', true, false);
      Customer.addSub(ContactCreate);
      let ContactCategories = new MenuModal('Customer.CustomerCategory', 'ContactCategories', 'Customer', 'Dictionary/ContactCategories', false, false);
      Customer.addSub(ContactCategories);
      let ContactTypes = new MenuModal('Customer.CustomerType', 'ContactTypes', 'Customer', 'Dictionary/ContactTypes', false, false);
      Customer.addSub(ContactTypes);
      let ContactStages = new MenuModal('Customer.CustomerStage', 'ContactStages', 'Customer', 'Dictionary/ContactStages', false, false);
      Customer.addSub(ContactStages);
      let Sources = new MenuModal('Common.Source', 'Sources', 'Customer', 'Dictionary/Sources', false, false);
      Customer.addSub(Sources);
      let dictionaryAreas = new MenuModal('Common.Area', 'Areas', 'Customer', 'Dictionary/Areas', false, false);
      Customer.addSub(dictionaryAreas);
      let dictionaryMarkets = new MenuModal('Common.Market', 'Markets', 'Customer', 'Dictionary/Markets', false, false);
      Customer.addSub(dictionaryMarkets);
      let contactReviewCriterial = new MenuModal('Customer.ContactReviewCriterial', 'ContactReviewCriterial', 'Customer', 'Dictionary/ContactReviewCriterial', false, false);
      Customer.addSub(contactReviewCriterial);
      if (this.UserInfoService.UserInfoEntity.Fields != null && this.UserInfoService.UserInfoEntity.Fields.indexOf('STUDYCONSULTING') > -1) {
        let dictionarySemesters = new MenuModal('Common.Semester', 'dictionarySemesters', 'Customer', 'Dictionary/Semesters', false, false);
        Customer.addSub(dictionarySemesters);
        let dictionaryForeignLanguages = new MenuModal('Common.ForeignLanguages', 'dictionaryForeignLanguages', 'Customer', 'Dictionary/ForeignLanguages', false, false);
        Customer.addSub(dictionaryForeignLanguages);
        let dictionaryStudyPrograms = new MenuModal('Common.StudyProgram', 'dictionaryStudyPrograms', 'Customer', 'Dictionary/StudyPrograms', false, false);
        Customer.addSub(dictionaryStudyPrograms);
        let dictionaryStudyFields = new MenuModal('Common.StudyFields', 'dictionaryStudyFields', 'Customer', 'Dictionary/StudyFields', false, false);
        Customer.addSub(dictionaryStudyFields);
      }
    }
    //Đối tác
    if (this.UserInfoService.UserInfoEntity.HasPartner) {
      let Partner = new MenuModal('Module.Partner', 'Partner', null, 'Partner/Partners', false, false, "ti-user")
      this.MenuList.push(Partner);
      //sub link
      let PartnerList = new MenuModal('Partner.PartnerList', 'PartnerList', 'Partner', 'Partner/Partners', false, false)
      Partner.addSub(PartnerList);
      let PartnerCreate = new MenuModal('Common.Add', 'PartnerCreate', 'Partner', 'Partner/PartnerCreate', true, false)
      Partner.addSub(PartnerCreate);
      let Collabos = new MenuModal('Partner.CollaboList', 'Collabos', 'Partner', 'Partner/Collabos', true, false)
      Partner.addSub(Collabos);
      let Suppliers = new MenuModal('Danh sách nhà cung cấp', 'Suppliers', 'Partner', 'Partner/Suppliers', false, false)
      Partner.addSub(Suppliers);
    }
    //Marketing
    if (this.UserInfoService.UserInfoEntity.HasMarketing) {
      let Marketing = new MenuModal('Module.Marketing', 'Marketing', null, null, false, false, "icofont icofont-brand-target")
      this.MenuList.push(Marketing);
      // sublink
      let promotionprogram = new MenuModal('Quản lý chương trình khuyến mại', 'PromotionPrograms', 'Promotion', 'Promotion/Promotions', false, false)
      Marketing.addSub(promotionprogram);
      let promotionCreate = new MenuModal('Common.Add', 'PromotionCreate', 'Promotion', 'Promotion/PromotionCreate', true, false)
      Marketing.addSub(promotionCreate);

      let Marketings = new MenuModal('Kế hoạch marketing online', 'Marketings', 'Marketing', 'Marketing/Marketings', false, false)
      Marketing.addSub(Marketings);
      let MktOnlineCreate = new MenuModal('Common.Add', 'MktOnlineCreate', 'Marketing', 'Marketing/MktOnlineCreate', true, false)
      Marketing.addSub(MktOnlineCreate);
      let Camps = new MenuModal('Chiến dịch email marketing', 'Camps', 'Marketing', 'Camp/Camps', false, false)
      Marketing.addSub(Camps);
      let CampCreate = new MenuModal('Common.Add', 'CampCreate', 'Marketing', 'Camp/CampCreate', true, false)
      Marketing.addSub(CampCreate);
      let OptinForms = new MenuModal('Optin Form', 'OptinForms', 'Marketing', 'Camp/OptinForms', true, false)
      Marketing.addSub(OptinForms);
      let CampStages = new MenuModal('Camp.CampStage', 'Marketings', 'Marketing', 'Dictionary/CampStages', false, false)
      Marketing.addSub(CampStages);
      let CampTypes = new MenuModal('Camp.CampType', 'CampTypes', 'Marketing', 'Dictionary/CampTypes', false, false)
      Marketing.addSub(CampTypes);
    }
    ////Khuyến mại
    //let promotion = new MenuModal('Module.Promotion', 'Promotion', null, null, false, false, "icofont icofont-price")
    //this.MenuList.push(promotion);
    //// sublink
    //let Promotions = new MenuModal('Danh mục VIP', 'Promotions', 'Promotion', 'Promotion/Promotions', false, false)
    //promotion.addSub(Promotions);

    //let vipcard = new MenuModal('Quản lý thẻ khách hàng', 'VipCards', 'Promotion', 'Promotion/VipCards', false, false)
    //promotion.addSub(vipcard);

    


    //Hợp đồng
    if (this.UserInfoService.UserInfoEntity.HasContract) {
      let Contract = new MenuModal('Module.Contract', 'Contract', null, 'Contract/Contracts', false, false, "icofont icofont-book-alt")
      this.MenuList.push(Contract);
      // sublink
      let ContractList = new MenuModal('Contract.ContractList', 'ContractList', 'Contract', 'Contract/Contracts', false, false)
      Contract.addSub(ContractList);
      let ContractCreate = new MenuModal('Common.Add', 'ContractCreate', 'Contract', 'Contract/ContractCreate', true, false)
      Contract.addSub(ContractCreate);
      let ContractProcess = new MenuModal('Contract.ContractProcess', 'ContractProcess', 'Contract', 'Contract/ContractProcess', true, false)
      Contract.addSub(ContractProcess);
      let ContractStage = new MenuModal('Contract.ContractStage', 'ContractStage', 'Contract', 'Dictionary/ContractStages', false, false)
      Contract.addSub(ContractStage);
      let ContractReasons = new MenuModal('Contract.ContractReason', 'ContractReasons', 'Contract', 'Dictionary/ContractReasons', false, false)
      Contract.addSub(ContractReasons);
      let ContractDurations = new MenuModal('Contract.ContractDuration', 'ContractDurations', 'Contract', 'Dictionary/ContractDurations', false, false)
      Contract.addSub(ContractDurations);
    }
    // Kinh doanh
    if (this.UserInfoService.UserInfoEntity.HasProduct ||
      this.UserInfoService.UserInfoEntity.HasQoute ||
      this.UserInfoService.UserInfoEntity.HasOrder ||
      this.UserInfoService.UserInfoEntity.HasPurchase ||
      this.UserInfoService.UserInfoEntity.HasSale) {
      let Business = new MenuModal('Common.Business', 'Business', null, null, false, false, "ti-shopping-cart-full")
      this.MenuList.push(Business);
      if (this.UserInfoService.UserInfoEntity.HasProduct) {
        let Products = new MenuModal('Module.Product', 'Product', 'Business', null, false, false)
        Business.addSub(Products);
        // add Sub
        let ProductList = new MenuModal('Product.ProductList', 'ProductList', 'Product', 'Product/Products', false, false)
        Products.addSub(ProductList);
        let ProductCreate = new MenuModal('Common.Add', 'ProductCreate', 'Product', 'Product/ProductCreate', true, false)
        Products.addSub(ProductCreate);
        let ProductCategories = new MenuModal('Product.ProductCategory', 'ProductCategories', 'Product', 'Dictionary/ProductCategories', false, false)
        Products.addSub(ProductCategories);
        let ProductTypes = new MenuModal('Product.ProductType', 'ProductTypes', 'Product', 'Dictionary/ProductTypes', false, false)
        Products.addSub(ProductTypes);
      }
      if (this.UserInfoService.UserInfoEntity.HasQoute) {
        let Quotes = new MenuModal('Module.Quote', 'Quotes', 'Business', null, false, false)
        Business.addSub(Quotes);
        // add sub
        let QuoteList = new MenuModal('Module.Quote', 'QuoteList', 'Quotes', 'Quote/Quotes', false, false)
        Quotes.addSub(QuoteList);
        let QuoteCreate = new MenuModal('Common.Add', 'QuoteCreate', 'Quotes', 'Quote/QuoteCreate', true, false)
        Quotes.addSub(QuoteCreate);
        let QuoteStage = new MenuModal('Quote.QuoteStage', 'QuoteStage', 'Quotes', 'Dictionary/QuoteStages', false, false)
        Quotes.addSub(QuoteStage);
      }
      if (this.UserInfoService.UserInfoEntity.HasOrder) {
        let Orders = new MenuModal('Module.Order', 'Orders', 'Business', null, false, false)
        Business.addSub(Orders);
        // add sub
        let QuoteList = new MenuModal('Order.OrderList', 'QuoteList', 'Orders', 'Order/Orders', false, false)
        Orders.addSub(QuoteList);
        let OrderCreate = new MenuModal('Common.Add', 'OrderCreate', 'Orders', 'Order/OrderCreate', true, false)
        Orders.addSub(OrderCreate);
        let OrderStages = new MenuModal('Order.OrderStage', 'OrderStages', 'Orders', 'Dictionary/OrderStages', false, false)
        Orders.addSub(OrderStages);
      }
      if (this.UserInfoService.UserInfoEntity.HasPurchase) {
        let Purchase = new MenuModal('Module.Purchase', 'Purchase', 'Business', null, false, false)
        Business.addSub(Purchase);
        // add sub
        let PurchaseList = new MenuModal('Purchase.PurchaseList', 'PurchaseList', 'Purchase', 'Purchase/Purchases', false, false)
        Purchase.addSub(PurchaseList);
        let PurchaseCreate = new MenuModal('Common.Add', 'PurchaseCreate', 'Purchase', 'Purchase/PurchaseCreate', true, false)
        Purchase.addSub(PurchaseCreate);
        let PurchaseStages = new MenuModal('Purchase.PurchaseStage', 'PurchaseCreate', 'Purchase', 'Dictionary/PurchaseStages', false, false)
        Purchase.addSub(PurchaseStages);
      }
      if (this.UserInfoService.UserInfoEntity.HasSale) {
        let Sales = new MenuModal('Module.Sale', 'Sales', 'Business', null, false, false)
        Business.addSub(Sales);
        //add sub
        let SaleList = new MenuModal('Sale.SaleList', 'SaleList', 'Sales', 'Sale/Sales', false, false)
        Sales.addSub(SaleList);
        let SaleCreate = new MenuModal('Common.Add', 'SaleCreate', 'Sales', 'Sale/SaleCreate', true, false)
        Sales.addSub(SaleCreate);
        let SaleReturnList = new MenuModal('SaleReturn.SaleReturnList', 'SaleReturnList', 'SaleReturns', 'SaleReturn/SaleReturns', false, false)
        Sales.addSub(SaleReturnList);
        let SaleReturnCreate = new MenuModal('Common.Add', 'SaleReturnCreate', 'SaleReturns', 'SaleReturn/SaleReturnCreate', true, false)
        Sales.addSub(SaleReturnCreate);
        let SaleStage = new MenuModal('Sale.SaleStage', 'SaleStage', 'Sales', 'Dictionary/SaleStages', false, false)
        Sales.addSub(SaleStage);
      }

      let DeliveryOrders = new MenuModal('Module.DeliveryOrder', 'DeliveryOrders', 'Business', null, false, false)
      Business.addSub(DeliveryOrders);
      //add sub
      let DeliveryOrderList = new MenuModal('DeliveryOrder.DeliveryOrderList', 'DeliveryOrderList', 'DeliveryOrders', 'DeliveryOrder/DeliveryOrders', false, false)
      DeliveryOrders.addSub(DeliveryOrderList);
      let DeliveryOrderCreate = new MenuModal('Common.Add', 'DeliveryOrderCreate', 'DeliveryOrders', 'DeliveryOrder/DeliveryOrderCreate', true, false)
      DeliveryOrders.addSub(DeliveryOrderCreate);
      let DeliveryOrderReturnList = new MenuModal('DeliveryOrderReturn.DeliveryOrderReturnList', 'DeliveryOrderReturnList', 'DeliveryOrders', 'DeliveryOrderReturn/DeliveryOrderReturns', false, false)
      DeliveryOrders.addSub(DeliveryOrderReturnList);
      let DeliveryOrderReturnCreate = new MenuModal('Common.Add', 'DeliveryOrderReturnCreate', 'DeliveryOrders', 'DeliveryOrderReturn/DeliveryOrderReturnCreate', false, false)
      DeliveryOrders.addSub(DeliveryOrderReturnCreate);
      if (this.UserInfoService.UserInfoEntity.Fields != null && this.UserInfoService.UserInfoEntity.Fields.indexOf('RESTAURANT') > -1) {
        let floor = new MenuModal('Quản lý tầng', 'Floors', 'Business', 'Dictionary/Floors', false, false,'',true)
        Business.addSub(floor);
        let room = new MenuModal('Quản lý phòng/bàn', 'Rooms', 'Business', 'Dictionary/Rooms', false, false,'',true)
        Business.addSub(room);
      }

    }
    //Tài chính
    if (this.UserInfoService.UserInfoEntity.HasCash) {
      let CashReceipt = new MenuModal('Module.CashReceipt', 'CashReceipt', null, null, false, false, "ti-money")
      this.MenuList.push(CashReceipt);
      //add sub
      let CashReceiptList = new MenuModal('CashReceipt.CashReceiptList', 'CashReceiptList', 'CashReceipt', 'CashReceipt/CashReceipts', false, false)
      CashReceipt.addSub(CashReceiptList);
      let CashReceiptCreate = new MenuModal('Common.Add', 'CashReceiptCreate', 'CashReceipt', 'CashReceipt/CashReceiptCreate', true, false)
      CashReceipt.addSub(CashReceiptCreate);
      let CashPaymentList = new MenuModal('CashPayment.CashPaymentList', 'CashReceipt', 'CashReceipt', 'CashPayment/CashPayments', false, false)
      CashReceipt.addSub(CashPaymentList);
      let CashPaymentCreate = new MenuModal('Common.Add', 'CashPaymentCreate', 'CashReceipt', 'CashPayment/CashPaymentCreate', true, false)
      CashReceipt.addSub(CashPaymentCreate);
      let BudgetItems = new MenuModal('CashPayment.ReceiptAndExpendItem', 'BudgetItems', 'CashReceipt', 'Dictionary/BudgetItems', false, false)
      CashReceipt.addSub(BudgetItems);
      let Currencies = new MenuModal('Common.CurrencyType', 'Currencies', 'CashReceipt', 'Dictionary/Currencies', false, false)
      CashReceipt.addSub(Currencies);
      let AccountingItems = new MenuModal('Common.AccountingItem', 'AccountingItems', 'CashReceipt', 'Dictionary/AccountingItems', false, false)
      CashReceipt.addSub(AccountingItems);
    }
    // Công việc
    if (this.UserInfoService.UserInfoEntity.HasTask) {
      let Task = new MenuModal('Công việc', 'Task', null, null, false, false, "ti-view-list-alt")
      this.MenuList.push(Task);
      //add sub
      let Projects = new MenuModal('Quản lý dự án', 'Projects', 'Task', 'Task/Projects', true, false)
      Task.addSub(Projects);
      let Workflows = new MenuModal('Quy trình làm việc', 'Workflows', 'Task', 'Task/Workflows', false, false)
      Task.addSub(Workflows);
    }

    if (this.UserInfoService.UserInfoEntity) {
      //Report
      let Report = new MenuModal('Module.Report', 'Report', null, null, false, false, "ti-files")
      this.MenuList.push(Report);
      //add sub report
      let ReportTotalCustomer = new MenuModal('Report.GeneralCustomer', 'ReportTotalCustomer', 'Report', 'Report/ReportTotalCustomer', false, false)
      Report.addSub(ReportTotalCustomer);
      let ReportCustomerIncreament = new MenuModal('Report.CustomerGrowth', 'ReportCustomerIncreament', 'Report', 'Report/ReportCustomerIncreament', false, false)
      Report.addSub(ReportCustomerIncreament);
      let ReportConversionRate = new MenuModal('Report.ConversionRate', 'ReportConversionRate', 'Report', 'Report/ReportConversionRate', true, false)
      Report.addSub(ReportConversionRate);
      let ReportSaleByContactCategory = new MenuModal('Report.SaleByCustomerGroup', 'ReportSaleByContactCategory', 'Report', 'Report/ReportSaleByContactCategory', false, false)
      Report.addSub(ReportSaleByContactCategory);
      let ReportSaleByProductCategory = new MenuModal('Report.SaleByGoodGroup', 'ReportSaleByProductCategory', 'Report', 'Report/ReportSaleByProductCategory', false, false)
      Report.addSub(ReportSaleByProductCategory);
      let ReportSaleByEmployee = new MenuModal('Report.SaleByEmployee', 'ReportSaleByEmployee', 'Report', 'Report/ReportSaleByEmployee', false, false)
      Report.addSub(ReportSaleByEmployee);
      let ReportSaleByContact = new MenuModal('Report.SaleByCustomer', 'ReportSaleByContact', 'Report', 'Report/ReportSaleByContact', false, false)
      Report.addSub(ReportSaleByContact);
      let ReportIncreamentSaleAmount = new MenuModal('Report.SaleGrowth', 'ReportIncreamentSaleAmount', 'Report', 'Report/ReportIncreamentSaleAmount', true, false)
      Report.addSub(ReportIncreamentSaleAmount);
      //Báo cáo hàng ký gửi
      let ReportDeliveryOrderSummary = new MenuModal('Report.DeliveryOrderSummary', 'ReportDeliveryOrderSummary', 'Report', 'Report/ReportDeliveryOrderSummary', true, false)
      Report.addSub(ReportDeliveryOrderSummary);
      //let ReportDeliveryOrderDetail = new MenuModal('Report.DeliveryOrderDetail', 'ReportDeliveryOrderDetail', 'Report', 'Report/ReportDeliveryOrderDetail', true, false)
      //Report.addSub(ReportDeliveryOrderDetail);

      let ReportTotalSaleAmount = new MenuModal('Report.SummaryOfRevenue', 'ReportTotalSaleAmount', 'Report', 'Report/ReportTotalSaleAmount', false, false)
      Report.addSub(ReportTotalSaleAmount);
      let ReportTotalExpense = new MenuModal('Report.AggreateCostTable', 'ReportTotalExpense', 'Report', 'Report/ReportTotalExpense', true, false)
      Report.addSub(ReportTotalExpense);
      let ReportTask = new MenuModal('Report.ReportTask', 'ReportTask', 'Report', 'Report/ReportTask', false, false)
      Report.addSub(ReportTask);

      //System
      let System = new MenuModal('Module.System', 'System', null, null, false, false, "ti-settings")
      this.MenuList.push(System);
      //add sub System
      let Branchs = new MenuModal('Common.Branch', 'Branchs', 'System', 'Dictionary/Branchs', true, false)
      System.addSub(Branchs);
      let AccountManagement = new MenuModal('Common.AccountManagement', 'AccountManagement', 'System', 'Account/Accounts', false, false)
      System.addSub(AccountManagement);
      let RoleManagement = new MenuModal('Common.RoleManagement', 'RoleManagement', 'System', 'Role/Roles', true, false)
      System.addSub(RoleManagement);
      let ContactWorkflows = new MenuModal('Quy trình đánh giá Khách hàng', 'ContactWorkflows', 'System', 'Dictionary/ContactWorkflows', false, false)
      System.addSub(ContactWorkflows);
      let FileProcessing = new MenuModal('Quy trình xử lý hồ sơ hợp đồng', 'FileProcessing', 'System', 'System/FileProcessing', true, false)
      System.addSub(FileProcessing);
      let mailConfig = new MenuModal('Cấu hình gửi email', 'MailingConfig', 'System', 'Mail/MailingConfigs', true, false)
      System.addSub(mailConfig);
      let Option = new MenuModal('Common.Setting', 'Option', 'System', 'System/Option', false, false)
      System.addSub(Option);
      let PageSetting = new MenuModal('Thiết lập trang', 'PageSetting', 'System', 'System/PageSetting', false, false)
      System.addSub(PageSetting);
    }
    console.log(this.MenuList);
    setTimeout(() => {
      let currentPathName = window.location.pathname.replace('/', '')
      console.log(currentPathName);
      this.determineActiveMenu(this.MenuList, currentPathName);
      setTimeout(() => {
        console.log(this.MenuList);
      })
    }, 300)
    // this.determineActiveMenu(this.MenuList)
  }

  onActiveMenu(Menu: MenuModal) {
    this.disactivateMenu(this.MenuList);
    setTimeout(() => {
      if (Menu.Link !== null) {
        Menu.isActive = true;
        this.activeParentMenu(this.MenuList, Menu.ParentCode);
        document.getElementById('mobile-collapse').click();
      }

    }, 200);
  }

  disactivateMenu(MenuList: MenuModal[]) {
    MenuList.forEach(x => {
      x.isActive = false;
      if (x.subLink && x.subLink.length > 0) {
        this.disactivateMenu(x.subLink);
      }
    })
  }

  activeParentMenu(MenuList: MenuModal[], parentCode: string) {
    MenuList.forEach(elm => {
      if (elm['Code'] === parentCode) {
        elm.isActive = true;
        if (elm.ParentCode !== null) {
          this.activeParentMenu(this.MenuList, elm.ParentCode);
        }
      } else {
        if (elm.subLink && elm.subLink.length > 0) {
          this.activeParentMenu(elm.subLink, parentCode);
        }
      }
    });
  }

  determineActiveMenu(MenuList: MenuModal[], Link: string) {
    MenuList.forEach(x => {
      if (Link.includes(x.Link) || (x.Link == null && Link.includes(x.Code))) {
        x.isActive = true;
        if (x.ParentCode !== null) {
          this.activeParentMenu(this.MenuList, x.ParentCode)
        }
      }
      if (x.subLink && x.subLink.length > 0) {
        this.determineActiveMenu(x.subLink, Link);
      }
    })
  }
}
