/// <reference path="tasktotal.entity.ts" />
import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from "../../HttpService";
import { IEntity } from '../../IEntity.Entity';
import { TaskTableEntity } from './TaskTable.Entity';
import { BoardStatisticsEntity } from './boardstatistics.entity';
import { TaskTotalEntity } from './TaskTotal.Entity';
import { SearchEntity } from '../../Search.Entity';
import { TaskListEntity } from './TaskList.Entity';
import { TaskTagEntity } from './TaskTag.Entity';
import { ToDoEntity } from './ToDo.Entity';
import { ToDoStepEntity } from './todostep.entity';
import { UserAndTagEntity } from './UserAndTag.Entity';
import { TaskTagAttachmentEntity } from './TaskTagAttachment.Entity';
import { WorkflowConnEntity } from './WorkflowConn.Entity';
@Injectable({
  providedIn: 'root'
})

export class TaskService extends HttpService<TaskTableEntity>{
  public url: string;

  constructor(private Http: HttpClient) {
    super(Http);    
    this.url = "api/Tasks";
  }

  BoardStatistics(IsShowLoading?: boolean): Observable<BoardStatisticsEntity> {
    return this.intercept(this.http.get<BoardStatisticsEntity>(this.url + '/BoardStatistics', {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);

  }
  GetTaskTotal(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<TaskTotalEntity> {
    return this.intercept(this.http.get<TaskTotalEntity>(this.url + '/TaskTotal', {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);

  }
  CreateBoardTable(body: TaskTableEntity, IsShowLoading?: boolean): Observable<TaskTableEntity> {
    return this.intercept(this.http.post<TaskTableEntity>(this.url, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);

  }
  DeleteBoardTable(ID: string, IsShowLoading?: boolean): Observable<boolean> {
    return this.intercept(this.http.delete(`${this.url}/${ID}`, {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);

  }
  CreateTaskList(body: TaskListEntity, IsShowLoading?: boolean): Observable<TaskListEntity> {
    return this.intercept(this.http.post<TaskListEntity>(this.url + '/AddTaskList', JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);

  }
  UpdateTaskList(body: TaskListEntity, IsShowLoading?: boolean): Observable<TaskListEntity> {
    return this.intercept(this.http.put<TaskListEntity>(`${this.url}/UpdateTaskList/${body.ID}`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  DeleteTaskList(ID: string, IsShowLoading?: boolean): Observable<boolean> {
    return this.intercept(this.http.delete(`${this.url}/DeleteTaskList/${ID}`, {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);

  }
  CreateTaskTag(body: TaskTagEntity, IsShowLoading?: boolean): Observable<TaskTagEntity> {
    return this.intercept(this.http.post<TaskTagEntity>(this.url + '/AddTaskTag', JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);

  }
  TaskTagChange(body: TaskTagEntity, IsShowLoading?: boolean): Observable<TaskTagEntity> {
    return this.intercept(this.http.put<TaskTagEntity>(`${this.url}/UpdateTaskTag/${body.ID}`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  UpdateTaskTag(body: TaskTagEntity, IsShowLoading?: boolean): Observable<TaskTagEntity> {
    return this.intercept(this.http.put<TaskTagEntity>(`${this.url}/TaskTagChange/${body.ID}`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  UpdateTaskTagOrderByUser(body: UserAndTagEntity, IsShowLoading?: boolean): Observable<boolean> {
    return this.intercept(this.http.post<boolean>(`${this.url}/UpdateTaskTagOrderByUser`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  UpdateTaskTagUser(body: TaskTagEntity, IsShowLoading?: boolean): Observable<TaskTagEntity> {
    return this.intercept(this.http.put<TaskTagEntity>(`${this.url}/UpdateTaskTagUser/${body.ID}`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  DeleteTaskTag(ID: string, IsShowLoading?: boolean): Observable<boolean> {
    return this.intercept(this.http.delete(`${this.url}/DeleteTaskTag/${ID}`, {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);

  }
  GetTaskTagDetail(entity?: IEntity, IsShowLoading?: boolean): Observable<TaskTagEntity> {
    return this.intercept(this.http.get<TaskTagEntity>(`${this.url}/GetTaskTagDetail/${entity.ID}`, {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);

  }
  CreateToDo(body: ToDoEntity, IsShowLoading?: boolean): Observable<ToDoEntity> {
    return this.intercept(this.http.post<ToDoEntity>(this.url + '/AddToDo', JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);

  }
  UpdateToDo(body: ToDoEntity, IsShowLoading?: boolean): Observable<ToDoEntity> {
    return this.intercept(this.http.put<ToDoEntity>(`${this.url}/UpdateToDo/${body.ID}`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  DeleteToDo(ID: string, IsShowLoading?: boolean): Observable<boolean> {
    return this.intercept(this.http.delete(`${this.url}/DeleteToDo/${ID}`, {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);

  }
  CreateToDoStep(body: ToDoStepEntity, IsShowLoading?: boolean): Observable<ToDoStepEntity> {
    return this.intercept(this.http.post<ToDoStepEntity>(this.url + '/AddToDoStep', JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);

  }
  UpdateToDoStep(body: ToDoStepEntity, IsShowLoading?: boolean): Observable<ToDoStepEntity> {
    return this.intercept(this.http.put<ToDoStepEntity>(`${this.url}/UpdateToDoStep/${body.ID}`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  DeleteToDoStep(ID: string,ToDoID:string, IsShowLoading?: boolean): Observable<boolean> {
    return this.intercept(this.http.delete(`${this.url}/DeleteToDoStep/${ID}/${ToDoID}`, {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);

  }
  GetTaskTableRecent(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<TaskTableEntity[]> {
    return this.intercept(this.http.get<TaskTableEntity[]>(this.url + '/GetRecentTable', {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);

  }
  UpdateTaskTagChangeList(body: TaskTagEntity, IsShowLoading?: boolean): Observable<TaskTagEntity> {
    return this.intercept(this.http.put<TaskTagEntity>(`${this.url}/UpdateTaskTagChangeList/${body.ID}`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  UpdateTaskTagInfo(body: TaskTagEntity, IsShowLoading?: boolean): Observable<TaskTagEntity> {
    return this.intercept(this.http.put<TaskTagEntity>(`${this.url}/UpdateTaskTagInfo/${body.ID}`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  UpdateTaskTagAttachment(body: TaskTagAttachmentEntity, IsShowLoading?: boolean): Observable<TaskTagAttachmentEntity> {
    return this.intercept(this.http.put<TaskTagAttachmentEntity>(`${this.url}/UpdateTaskTagAttachmentShowOnTag/${body.ID}`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  UpdateTaskTagUserFollow(body: TaskTagEntity, IsShowLoading?: boolean): Observable<TaskTagEntity> {
    return this.intercept(this.http.put<TaskTagEntity>(`${this.url}/UpdateTaskTagUserFollow/${body.ID}`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  UpdateTaskTagIsSendMail(body: TaskTagEntity, IsShowLoading?: boolean): Observable<TaskTagEntity> {
    return this.intercept(this.http.put<TaskTagEntity>(`${this.url}/UpdateTaskTagIsSendMail/${body.ID}`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  DeleteTaskTagAttachment(TaskTagID: string,ID:string,FileName:string, IsShowLoading?: boolean): Observable<boolean> {
    return this.intercept(this.http.delete(`${this.url}/${TaskTagID}/DeleteTaskTagAttachment/${ID}/${FileName}`, {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);

  }
  DeleteTaskTagLinkAttachment(TaskTagID: string, ID: string,  IsShowLoading?: boolean): Observable<boolean> {
    return this.intercept(this.http.delete(`${this.url}/${TaskTagID}/DeleteTaskTagLinkAttachment/${ID}`, {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);

  }
  ReviewTaskTag(body: TaskTagEntity, IsShowLoading?: boolean): Observable<boolean> {
    return this.intercept(this.http.put<boolean>(`${this.url}/ReviewTaskTag/${body.ID}`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  Download(data: any) {
    return window.open(`${this.url}/${data.ID}` + "/DownloadAttach/" + data.TaskTagID);
  }

  GetTaskList(TaskTableID:string, IsShowLoading?: boolean): Observable<TaskListEntity[]> {
    return this.intercept(this.http.get<TaskListEntity[]>(this.url + '/GetTaskList/' + TaskTableID, {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);

  }

  GetWorkflowConn(TaskTableID: string, IsShowLoading?: boolean): Observable<WorkflowConnEntity[]> {
    return this.intercept(this.http.get<WorkflowConnEntity[]>(this.url + '/GetWorkflowConn/' + TaskTableID, {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);

  }

}
