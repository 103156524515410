import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../../Report/Models/Report.Service';
import { ReportBaseRequestEntity } from 'src/app/Modules/Report/Models/ReportBaseRequest.Entity';
@Component({
  selector: 'app-sale-by-product-group',
  templateUrl: './sale-by-product-group.component.html',
  styleUrls: ['./sale-by-product-group.component.css']
})
export class SaleByProductGroupComponent implements OnInit {
  LoadChart: boolean = false;
  public pieChartLabels: string[] = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '',''];
  public pieChartData: number[] = [0, 0, 0,0,0,0,0,0,0,0,0,0,0,0,0,0];
  public pieChartType: string = 'pie';
  ChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'top',
      align: 'center'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          label += ': ' + data.datasets[0].data[tooltipItem.index].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return label;
        },
      }
    }
  };
  constructor(public ReportService: ReportService) { this.LoadData();}

  ngOnInit() {
  }
  LoadData() {
    this.ReportService.GetReportSaleByProductCategory(new ReportBaseRequestEntity()).subscribe(p => {
      this.pieChartLabels = [];
      this.pieChartData = [];
      p.forEach(data => {
        this.pieChartLabels.push(data.name);
        this.pieChartData.push(data.y);
      });
      this.LoadChart = true;
    })
  }
}
