import { IEntity } from "../../../../Modules/IEntity.Entity";
export class DiscussionEntity extends IEntity{
	ObjectID: string;
	UserID: string;
	Comment: string;
	ParentID: string;
	CreatedDate: string;
	RefType: number;
	ObjectName: string;
	UserName: string;
	IsShowComment: boolean;
	IsParent: boolean;
	ObjectCode: string;
	ReplyEntity: DiscussionEntity;
	ReplyEntities: DiscussionEntity[];
  TempReplyEntities: DiscussionEntity[];
  PathAvatar: string;
	public constructor(){
		super();
	}
}

export class ResponseDiscussionEntity{
	Count: number;
	ListEntity: DiscussionEntity[];
	constructor(){}
  }
