import { URLSearchParams } from '@angular/http';
import { HttpParams } from '@angular/common/http';

export class SearchEntity {
  Skip: number;
  Take: number;
  PageIndex: number;
  PageSize: number;
  OrderBy: string;
  Type: string;
  Current: boolean;
  BranchSearchID: string;
  BasicSearchString: string;
  PeriodReportYear: number;
  PeriodReportType: number;

  constructor(SearchEntity: any = null) {
    if (SearchEntity == null) {
      this.Skip = 0;
      this.Take = 15;
      this.PageSize = 10;
      this.PageIndex = 1;
      this.OrderBy = 'Id';
      this.Type = 'None';
      this.Current = false;
    } else {
      this.Skip = SearchEntity.Skip;
      this.Take = SearchEntity.Take;
      this.OrderBy = SearchEntity.OrderBy;
      this.Type = SearchEntity.Type;
      this.PageIndex = SearchEntity.PageIndex;
      this.Current = SearchEntity.Current;
    }
  }

  ToParams(): HttpParams {
    let params = new HttpParams();
    for (let key in this) {
      if (this.hasOwnProperty(key) && this[key.toString()] != null && this[key.toString()] != NaN) {
        params = params.set(key, this[key.toString()]);
      }
    }

    return params;
  }
}

