import { Pipe, PipeTransform, NgModule } from '@angular/core'

@Pipe({ name: 'filterList' })
export class FilterListPipe implements PipeTransform {
  transform(items: any[], Searching: string, Type: string) {
    if (!items || !Searching)
      return items;
    return items.filter(item => item[Type].toString().indexOf(Searching) !== -1 );
  }
}
@NgModule({
  declarations: [
    FilterListPipe
  ],
  imports: [
  ],
  exports: [
    FilterListPipe
  ],
  schemas: [],
})
export class FilterListPipeModule { }
