import { IEntity } from "../../IEntity.Entity";
export class ToDoStepEntity extends IEntity {
  ID: string;
  ToDoID: string;
  StepName: string;
  IsComplete: boolean = false;
  OrderNumber: number;
  CompanyID: string;
  TaskTagID: string;
  Weight:number;
  public constructor() {
    super();
    this.IsComplete = false;
    this.Errors = [];
  }
}
