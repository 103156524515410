import { Component, OnInit, Input, NgModule, OnChanges } from '@angular/core';
import {DiscussionEntity, ResponseDiscussionEntity} from './../Models/Discussion.Entity'
import {DiscussionSearchEntity} from './../Models/Discussion.SearchEntity'
import {DiscussionService} from './../Models/Discussion.Service'
import { MegacrmWysiwygComponent, MegacrmWysiwygModule } from '../../megacrm-wysiwyg/megacrm-wysiwyg.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ng6-toastr-notifications';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SafeHtmlModule } from 'src/app/shared/pipe.safehtml';
import { QuillModule } from 'ngx-quill';
import "quill-mention";
import { AccountService } from 'src/app/Modules/Account/Models/Account.Service';
import { AccountSearchEntity } from 'src/app/Modules/Account/Models/Account.SearchEntity';
@Component({
  selector: 'app-customdiscussion',
  templateUrl: './customdiscussion.component.html',
  styleUrls: ['./customdiscussion.component.css']
})



export class CustomdiscussionComponent implements OnInit, OnChanges{
  DiscussionEntity: DiscussionEntity = new DiscussionEntity();
  ReplyEntity: DiscussionEntity = new DiscussionEntity();
  DiscussionSearchEntity: DiscussionSearchEntity = new DiscussionSearchEntity();
  ReplySearchEntity: DiscussionSearchEntity = new DiscussionSearchEntity();
  MegacrmWysiwygComponent: MegacrmWysiwygComponent = new MegacrmWysiwygComponent();
  PostEntities: DiscussionEntity[] = [];
  CommentMegacrmWysiwygComponent: Array<MegacrmWysiwygComponent> = [];
  @Input() ObjectID: string;
  @Input() RefType: number;
  @Input() ObjectCode: string;
  @Input() ObjectName: string;
  atValues = [
    { id: 1, value: "Fredrik Sundqvist" },
    { id: 2, value: "Patrik Sjölin" }
  ];
  hashValues = [

  ];
  AccountSearchEntity: AccountSearchEntity = new AccountSearchEntity();
  quillConfig={
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö0-9]*$/,
      mentionDenotationChars: ["@", "#"],
      source: (searchTerm, renderList, mentionChar) => {
        let values;
        if (mentionChar === "@" && searchTerm.length == 0) {
          this.AccountService.Gets().subscribe(x => {
            values = x.ListEntity;
            for(let i = 0; i < values.length; i++){
              values[i].value = values[i]['FullName'];
              values[i].id = values[i]['ID'];
              delete values[i].FullName;
            }
            renderList(values, searchTerm);
          })
        } else {
          values = this.hashValues;
        }

        if (mentionChar === "@" && searchTerm.length > 0) {
          this.AccountSearchEntity.FullName = searchTerm;
          this.AccountService.Gets(this.AccountSearchEntity).subscribe(x => {
            const matches = [];
            values = x.ListEntity;
            for(let i = 0; i < values.length; i++){
              values[i].value = values[i]['FullName'];
              values[i].id = values[i]['ID'];
              delete values[i].FullName;
            }
            for (var i = 0; i < values.length; i++)
            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
            renderList(matches, searchTerm);
          })

        }
      },
    },
    keyboard: {
      bindings: {
        enter:{
          key:13,
          handler: (range, context)=>{
            console.log("enter");
            return true;
          }
        }
      }
    }
  }
  constructor(public DiscussionService: DiscussionService,
              public toastr: ToastrManager,
              public AccountService: AccountService) { }

  ngOnInit() {
    this.DiscussionSearchEntity.ObjectID = this.ObjectID;
    this.DiscussionSearchEntity.RefType = this.RefType;
  }

  ngOnChanges(){
    this.GetDiscussion();
  }
  SendPost(ParentID?: string, Index?: number) {
    if (this.DiscussionEntity.Comment == null || this.DiscussionEntity.Comment == undefined || this.DiscussionEntity.Comment.trim() == "") {
      this.toastr.warningToastr("Bạn chưa nhập nội dung thảo luận", "Thông báo");
    }
    else {
      this.DiscussionEntity.ObjectID = this.ObjectID;
      this.DiscussionEntity.RefType = this.RefType;
      this.DiscussionEntity.ObjectCode = this.ObjectCode ? this.ObjectCode : null;
      this.DiscussionEntity.ObjectName = this.ObjectName ? this.ObjectName : null;

      this.DiscussionService.Create(this.DiscussionEntity).subscribe(res => {
        this.DiscussionEntity = new DiscussionEntity();
        this.PostEntities.push(res);
      }, e => {
        console.log('Error');
      })
    }
  }
  // SendPost(ID: string){
  //   this.DiscussionEntity.ObjectID = this.ObjectID;
  //   this.DiscussionEntity.RefType = this.RefType;
  //   this.DiscussionEntity.Comment = document.getElementById(ID).innerHTML;
  //   document.getElementById(ID).innerHTML = '';
  //   this.DiscussionService.Create(this.DiscussionEntity).subscribe(res =>{
  //     this.DiscussionEntity = res;
  //     this.GetDiscussion();
  //   }, e => {
  //     console.log('Error');
  //   })
  // }
  GetDiscussion(){
    this.DiscussionSearchEntity.ParentID = null;
    this.PostEntities = [];
    if(Boolean(this.ObjectID) && Boolean(this.RefType)){
      this.DiscussionSearchEntity.ObjectID = this.ObjectID;
      this.DiscussionSearchEntity.RefType = this.RefType;
      this.DiscussionService.GetResponseDiscussion(this.DiscussionSearchEntity).subscribe(res => {
        let responseDiscussion: ResponseDiscussionEntity = new ResponseDiscussionEntity();
        responseDiscussion = res;
        this.PostEntities = res.ListEntity;
        this.PostEntities.forEach(x => {
          this.CommentMegacrmWysiwygComponent.push(new MegacrmWysiwygComponent());
          x.IsShowComment = false;

        })
      })
    }
  }

  Reply(indexOfPost: number){
    this.PostEntities[indexOfPost].IsShowComment = true;
    this.PostEntities[indexOfPost].ReplyEntity = new DiscussionEntity();
  }
  SendReply(ReplyEntity: DiscussionEntity,ParentID: string, Index: number){
    ReplyEntity.ParentID = ParentID;
    ReplyEntity.ObjectID = this.ObjectID;
    ReplyEntity.RefType = this.RefType;
    this.DiscussionService.Create(ReplyEntity).subscribe(res =>{
      this.PostEntities[Index].ReplyEntity = new DiscussionEntity();
      this.DiscussionSearchEntity.ParentID = ParentID;
        this.DiscussionService.GetResponseDiscussion(this.DiscussionSearchEntity).subscribe(res => {
          let responseDiscussion: ResponseDiscussionEntity = new ResponseDiscussionEntity();
          responseDiscussion = res;
          this.PostEntities[Index].ReplyEntities = JSON.parse(JSON.stringify(res.ListEntity)) ;
          this.PostEntities[Index].TempReplyEntities = JSON.parse(JSON.stringify(res.ListEntity));
          this.DiscussionSearchEntity.ParentID = null;
        })

    }, e => {
      console.log('Error');
    })
  }

  ViewAllReplies(ParentID: string, index: number){
    if(this.PostEntities[index].TempReplyEntities != undefined){
      this.PostEntities[index].ReplyEntities = JSON.parse(JSON.stringify(this.PostEntities[index].TempReplyEntities)) ;
    }
    else{
      this.DiscussionSearchEntity.ParentID = ParentID;
      this.DiscussionService.GetResponseDiscussion(this.DiscussionSearchEntity).subscribe(res => {
        let responseDiscussion: ResponseDiscussionEntity = new ResponseDiscussionEntity();
        responseDiscussion = res;
        this.PostEntities[index].ReplyEntities = JSON.parse(JSON.stringify(res.ListEntity)) ;
        this.PostEntities[index].TempReplyEntities = JSON.parse(JSON.stringify(res.ListEntity));
        this.DiscussionSearchEntity.ParentID = null;
        console.log(res);
      })
    }

  }

  CollapseAllReplies(ParentID: string, index: number){
    this.PostEntities[index].ReplyEntities = [];
  }
}

@NgModule({

  declarations: [
    CustomdiscussionComponent,
  ],
  imports: [
    FormsModule, CommonModule, ReactiveFormsModule,ToastrModule.forRoot(),SafeHtmlModule, MegacrmWysiwygModule,
    QuillModule.forRoot()
  ],
  exports: [
    CustomdiscussionComponent
  ]
})
export class CustomDiscussionModule { }
