import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ContentChild,
  TemplateRef,
  ElementRef,
  ViewChild
} from '@angular/core';
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ClickOutSideModule } from '../../AttributeDirective/ClickOutSide.Directive';
import { FilterListPipeModule } from '../../FilterList.pipe';
import { SearchEntity } from '../../../Modules/Search.Entity';
// import {FilterListPipeModule} from '../../Pipe/FilterList.pipe';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { IEntity } from '../../../Modules/ientity.entity';
import { HttpService } from '../../../Modules/HttpService';
import * as $ from 'jquery';

@Component({
  selector: 'ngx-select-multiple',
  templateUrl: './ngx-select-multiple.component.html',
  styleUrls: ['./ngx-select-multiple.component.css'],
  providers: []
})
export class NgXSelectMultipleComponent implements OnInit {
  //The external data:
  @Input() ErrorEntity: any = null; //Show error value;
  @Input() Placeholder: string = ""; //Set placeholder for input field;
  @Input() Disabled: boolean = false; //Set disabled for input field;
  @Input() SearchProperty: string = ""; //Set property for searching;
  @Input() BindLabel: string = "Name"; //Set property for viewing in input filed;
  @Input() Service: HttpService<IEntity> = null; //Set API for get data;
  @Input() StyleWidth: number; //Set style width data box;
  @Input() Type: string = "Custom"; //Set type of select box (only 2 types are 'Custom' and 'Normal');
  @Input() ListItem: Array<IEntity> = [] //Set list item when select drop is normal type;
  @Input() InitValue: any[] = [];
  @Input() idOfTable: string;
  @Input() //Set SearchEntity for searching
  set InitSearch(obj: any) {
    Object.assign(this.SearchEntity, obj);
  }

  @Output() Change: EventEmitter<any> = new EventEmitter();

  // Read in our structural directives as TemplateRefs:
  @ContentChild('ngXSelectOption') ngXSelectOption: TemplateRef<ElementRef>;
  @ViewChild("inputSelect") inputSelect: ElementRef;

  // The internal data model
  private innerValue: any;
  IsShow: boolean = false;
  IsShowLoading: boolean = false;
  Model: string = null;
  @Input() ModelArray: any[] = [];
  Entities: Array<IEntity> = [];
  SearchEntity: SearchEntity = new SearchEntity();
  TotalEntity: number = 0;
  IDBox: string = this.MakeRandomId();
  IDNormalBox: string = this.MakeRandomId();

  ngOnInit() {
    if (this.Service == null || this.Type == "Normal") {
      this.Entities = this.ListItem;
      return;
    } else {
      this.Service.Gets(this.SearchEntity, false).subscribe(x => {
        this.TotalEntity = x.Count;
      });
    }
    if (this.InitValue !== undefined && this.InitValue.length > 0) this.ModelArray = JSON.parse(JSON.stringify(this.InitValue));
  }

  GetData(SearchEntity: SearchEntity) {
    setTimeout(() => {
      this.Service.Gets(SearchEntity, false).subscribe(x => {
        if (x.ListEntity !== undefined && x.ListEntity !== null) {
          if (this.ModelArray !== undefined && this.ModelArray.length > 0) {
            for (let Item of x.ListEntity) {
              let check = this.ModelArray.some(e => {
                return e.ID == Item.ID;
              });
              if (check) Item.IsSelected = true;
              else Item.IsSelected = false;
            }
          } else {
            if (this.ModelArray === undefined) {
              this.ModelArray = [];
            }
            x.ListEntity = x.ListEntity.map(item => {
              item.IsSelected = false;
              return item;
            });
          }
          this.Entities = x.ListEntity;
        } else {
          if (this.ModelArray !== undefined && this.ModelArray.length > 0) {
            for (let Item of x) {
              let check = this.ModelArray.some(e => {
                return e.ID == Item.ID;
              });
              if (check) Item.IsSelected = true;
              else Item.IsSelected = false;
            }
          } else {
            if (this.ModelArray === undefined) {
              this.ModelArray = [];
            }
            x = x.map(item => {
              item.IsSelected = false;
              return item;
            });
          }
          this.Entities = x;
        }


        this.IsShowLoading = false;
      });
    }, 700);
  }

  SearchingData(event) {
    if (this.CheckKeyPress(event)) {
      if (this.Type == "Custom") {
        this.IsShowLoading = true;
        if (this.SearchProperty.includes(".")) {
          let Array = this.SearchProperty.split(".");
          this.SearchEntity[Array[0]][Array[1]] = this.Model;
        } else {
          this.SearchEntity[this.SearchProperty] = this.Model;
        }
        this.SearchEntity.Skip = 0;
        this.SearchEntity.Take = 10;
        this.SearchEntity.PageIndex = 1;
        debounceTime(800);
        distinctUntilChanged();
        this.GetData(this.SearchEntity);
      } else return;
    }
  }

  FirstLoad() {
    if (this.Disabled) return;
    this.IsShow = !this.IsShow;
    this.inputSelect.nativeElement.focus();
    this.SearchEntity.Skip = 0;
    this.SearchEntity.PageIndex = 1;
    if (this.Type == "Custom") {
      this.IsShowLoading = true;
      this.GetData(this.SearchEntity);
    } else {
      this.Entities = this.ListItem;
      this.TotalEntity = this.Entities.length;
    }
  }

  ClickToInput(event) {
    if (this.IsShow) event.stopPropagation();
    else {
      event.stopPropagation();
      this.IsShow = true;
      if (this.Entities === undefined || this.Entities.length === 0) {
        this.GetData(this.SearchEntity);
      } else {
        for (let Item of this.Entities) {
          let check = this.ModelArray.some(e => {
            return e.ID == Item.ID;
          });
          if (check) Item.IsSelected = true;
          else Item.IsSelected = false;
        }
      }
      //setTimeout(() => {
      //  let dropdownDiv =  document.getElementById('dropdownDiv').offsetHeight;
      //  let calTableHeight = dropdownDiv + document.getElementById(this.idOfTable).offsetHeight;
      //  document.getElementById(this.idOfTable).style.height = calTableHeight.toString() + 'px';
      //}, 300)
    }
  }

  CheckKeyPress(event) {
    if (event.code !== "ArrowDown") return true;
    if (this.Type == 'Custom') (<HTMLElement>$("#" + this.IDBox)[0].firstElementChild).focus();
    else (<HTMLElement>$("#" + this.IDNormalBox)[0].firstElementChild).focus();
    return false;
  }

  Move(event, Entity) {
    switch (event.code) {
      case "Enter":
        this.SelectEntity(Entity);
        break;
      case "ArrowDown":
        if (event.target.nextElementSibling !== null)
          event.target.nextElementSibling.focus();
        event.preventDefault();
        break;
      case "ArrowUp":
        if (event.target.previousElementSibling !== null)
          event.target.previousElementSibling.focus();
        event.preventDefault();
        break;
    }
    return;
  }

  CheckScroll() {
    let scrollTop = $("#" + this.IDBox)[0].scrollTop;
    let clientHeight = $("#" + this.IDBox)[0].clientHeight;
    let scrollHeight = $("#" + this.IDBox)[0].scrollHeight;
    if ((scrollHeight - scrollTop) <= clientHeight + 1) {
      if (this.Entities.length < this.TotalEntity) {
        this.SearchEntity.Skip = this.SearchEntity.Skip + 10;
        this.SearchEntity.PageIndex++;
        this.Service.Gets(this.SearchEntity, false).subscribe(x => {
          if (x.ListEntity !== undefined && x.ListEntity !== null) {
            this.Entities = [...this.Entities, ...x.ListEntity];
            document.getElementById(this.IDBox).scrollTo(0, scrollTop);
          }
          else {
            this.Entities = [...this.Entities, ...x];
            document.getElementById(this.IDBox).scrollTo(0, scrollTop);
          }
          for (let Item of this.Entities) {
            let check = this.ModelArray.some(e => {
              return e.ID == Item.ID;
            });
            if (check) Item.IsSelected = true;
            else Item.IsSelected = false;
          }
          document.getElementById(this.IDBox).scrollTo(0, scrollTop);
        });
      }
    }
  }

  SelectEntity(Entity) {
    let FilterEntity = this.ModelArray.filter(item => item.ID === Entity.ID)[0];
    if (FilterEntity !== undefined) {
      Entity.IsSelected = false;
      let index = this.ModelArray.indexOf(FilterEntity);
      this.ModelArray.splice(index, 1);
    } else {
      Entity.IsSelected = true;
      this.ModelArray.push(Entity);
    }
    this.Change.emit(this.ModelArray);
    this.Model = null;
    this.inputSelect.nativeElement.focus();
    this.SearchEntity[this.SearchProperty] = this.Model;
    this.GetData(this.SearchEntity);
  }

  RemoveItem(item, event) {
    if (this.Disabled === true) {
      return;
    } else {
      let indexOfArray = this.ModelArray.indexOf(item);
      this.ModelArray.splice(indexOfArray, 1);
      for (let Entity of this.Entities) {
        if (Entity.ID === item.ID) Entity.IsSelected = false;
      }
      this.Change.emit(this.ModelArray);
      event.stopPropagation();
    }
  }

  MakeRandomId(): string {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }
}

@NgModule({
  declarations: [
    NgXSelectMultipleComponent
  ],
  imports: [
    FormsModule, CommonModule, ClickOutSideModule, FilterListPipeModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  exports: [
    NgXSelectMultipleComponent
  ]
})
export class NgXSelectMultipleModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
