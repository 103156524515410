import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { LoginEntity } from '../Models/Login.Entity';
import { UserInfoService } from '../Models/UserInfo.Service';
import { HttpService } from "../../HttpService";
import { LoginService } from "../Models/Login.Service";
import { Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { all } from "q";
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullUserInfoEntity } from '../Models/FullUserInfo.Entity';
import { UserInfoEntity } from '../Models/UserInfo.Entity';
// const SME= 'SME';
// const RETAIL= 'RETAIL';
// const RESTAURANT = 'RESTAURANT';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  LoginEntity: LoginEntity = new LoginEntity();
  ResetPasswordEntity: LoginEntity = new LoginEntity();
  IsReset: boolean = false;
  backgroundImg = '';
  Field: string;//SME: Doanh nghiệp SME, RETAIL: Bán lẻ, RESTAURANT: Nhà hàng, cafe

  @ViewChild('UserName') inputUserName: ElementRef;
  constructor(private router: Router, private HttpService: HttpService<LoginEntity>,
    public ToastrManager: ToastrManager,
    public UserInfoService: UserInfoService, public LoginService: LoginService) {
    this.HttpService.url = "api/Auth/SignIn";
    this.UserInfoService.UserInfoEntity = null;
    document.cookie = "ERPUserToken= ''" + ";-1";
  }

  ngOnInit() {
    this.GetField();
  }

  ngAfterViewInit() {
    setTimeout(() => this.inputUserName.nativeElement.focus());

  }

  SignIn() {

    this.LoginService.SignIn(this.LoginEntity).subscribe(res => {

      let data = (res as any);

      let UserInfo = this.DecodeUserInfo(data);
      let UserInfoFull = this.DecodeFullUserInfo(data);

      this.setCookie("ERPUserToken", data.token, UserInfo.exp);
      this.setCookie("ERPUserTokenFull", data.tokenFull, UserInfoFull.exp);
      let full: UserInfoEntity = UserInfo.UserEntity;
      Object.assign(full, UserInfoFull.UserEntity)
      this.UserInfoService.UserInfoEntity = full;

      location.reload();
      this.router.navigate(['Home']);

    }, e => {
      this.ToastrManager.errorToastr("Tài khoản hoặc mật khẩu không chính xác!", "Thông báo");
    })

  }

  DecodeUserInfo(data) {

    let User = data.token.split(".")[1];
    if (User == null) {
      console.error("Cannot recognize JWT token!");
      return;
    }
    let user = JSON.parse(this.b64DecodeUnicode(User));
    console.log(user);
    return user;
  }
  DecodeFullUserInfo(data) {

    let User = data.tokenFull.split(".")[1];
    if (User == null) {
      console.error("Cannot recognize JWT token!");
      return;
    }
    let user = JSON.parse(this.b64DecodeUnicode(User));
    console.log(user);
    return user;
  }

  setCookie(cname, cvalue, exp) {
    var d = new Date();
    d.setTime(d.getTime() + exp);
    var expires = "expires=" + exp;
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  b64DecodeUnicode(str) {
    str = str.replace('-', '+').replace('_', '/');
    switch (str.length % 4) {
      case 0:
        break;
      case 2:
        str += '==';
        break;
      case 3:
        str += '=';
        break;
      default:
        throw 'Illegal base64url string!';
    }
    return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  }
  openModalRegister() {
    return;
  }

  switchBachground(){
    switch(this.Field){
      case 'SME':
      case 'SALE':
        return this.backgroundImg = '../../../../assets/temp/images/loginbgr.jpg';
      case 'RETAIL':
        return this.backgroundImg = '../../../../assets/temp/images/loginbgr.jpg';
      case 'RESTAURANT':
        return this.backgroundImg = '../../../../assets/temp/images/Restaurant.jpeg';
      default:
        return this.backgroundImg = '../../../../assets/temp/images/loginbgr.jpg';
    }
  }

  GetField() {
    this.LoginService.GetField().subscribe(p => {
      this.Field = p.CompanyField;
    });
  }

}
