
import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BusinessEntity } from './Business.Entity';
import { HttpService } from "../../HttpService";
import { IEntity } from '../../IEntity.Entity';
import { PackageProductEntity } from './PackageProduct.Entity';
import { ServiceExtensionEntity } from './ServiceExtension.Entity';
import { CompanyRegistryEntity } from './CompanyRegistry.Entity';
@Injectable({
  providedIn: 'root'
})

export class BusinessService extends HttpService<BusinessEntity>{
  public url: string;

  constructor(private Http: HttpClient) {
    super(Http);
    this.url = "api/Businesses";
  }

  // Register
  Register(body: IEntity, IsShowLoading?: boolean): Observable<BusinessEntity> {
    return this.intercept(this.http.post<BusinessEntity>(this.url + '/SetupCompany', JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  ApproveBusiness(body: BusinessEntity, IsShowLoading?: boolean): Observable<BusinessEntity> {
    return this.intercept(this.http.put<BusinessEntity>(this.url + `/ApproveBusiness`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  ApproveRenewBusiness(body: BusinessEntity, IsShowLoading?: boolean): Observable<BusinessEntity> {
  return this.intercept(this.http.put<BusinessEntity>(this.url + `/ApproveRenewBusiness`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  PendingBusiness(body: BusinessEntity, IsShowLoading?: boolean): Observable<BusinessEntity> {
    return this.intercept(this.http.put<BusinessEntity>(this.url + `/PendingBusiness`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
 
  GetPackageProduct(): Observable<PackageProductEntity[]>  {
    return this.intercept(this.http.get<PackageProductEntity[]>(this.url + '/GetPackageProduct', {
      observe: 'response',
      headers: this.GetHeaders()
    }), false).map(r => r.body);
  }
  GetLicenseInfo(): Observable<CompanyRegistryEntity>  {
    return this.intercept(this.http.get<CompanyRegistryEntity>(this.url + '/GetLicenseInfo', {
      observe: 'response',
      headers: this.GetHeaders()
    }), false).map(r => r.body);
  }

  ServiceExtension(body: ServiceExtensionEntity, IsShowLoading?: boolean): Observable<ServiceExtensionEntity> {
    return this.intercept(this.http.put<ServiceExtensionEntity>(this.url + `/ServiceExtension`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  GetTokenKey(): Observable<any> {
    return this.intercept(this.http.get<any>(this.url + '/GenerateTokenKey', {
      observe: 'response',
      headers: this.GetHeaders()
    }), false).map(r => r.body);
  }
}
