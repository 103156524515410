import { IEntity } from "../../IEntity.Entity";
export class UserInfoEntity extends IEntity {
  ID: string;
  UserName: string;
  CompanyCode: string;
  PassWord: string;
  FullName: string;
  Title: string;
  PhoneNumber: string;
  IDNumber: string;
  Address: string;
  UserGroupID: string;
  CompanyID: string;
  IsVisible: boolean;
  BranchID: string;
  CreatedDate: string;
  IsOnline: boolean;
  IsAdmin: boolean;
  LastLogin: string;
  Email: string;
  HasEmail: boolean;
  HasContact: boolean;
  HasQoute: boolean;
  HasMarketing: boolean;
  HasOrder: boolean;
  HasContract: boolean;
  HasPurchase: boolean;
  HasSale: boolean;
  HasCash: boolean;
  HasTask: boolean;
  HasProduct: boolean;
  HasPartner: boolean;
  Fields: string;
  FileData: any;
  FileName: string;
  FilePath: string;
  public constructor() {
    super();
  }
}
