import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Rx';
import { Http } from '@angular/http';
import { Injectable } from '@angular/core';
import { UserInfoEntity } from './UserInfo.Entity';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../../HttpService';
import { IEntity } from '../../IEntity.Entity';


@Injectable({
  providedIn: 'root'
})
export class LoginService extends HttpService<UserInfoEntity> {
  UserInfoEntity: UserInfoEntity;

  constructor(private Http: HttpClient) {
    super(Http);
    this.url = "api/Auth";
  }

  SignIn(body: IEntity, IsShowLoading?: boolean): Observable<UserInfoEntity> {
    return this.intercept(this.http.post<UserInfoEntity>(this.url + '/SignIn', JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }

  SignOut(body: IEntity, IsShowLoading?: boolean): Observable<UserInfoEntity> {
    return this.intercept(this.http.post<UserInfoEntity>(this.url + '/SignOut', JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }

  ResetPassword(body: IEntity, IsShowLoading?: boolean): Observable<UserInfoEntity> {
    return this.intercept(this.http.post<UserInfoEntity>(this.url + '/reset-password', JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  GetField(): Observable<any> {
    return this.intercept(this.http.get<any>(this.url + '/GetCompanyField', {
      observe: 'response',
      headers: this.GetHeaders()
    }), false).map(r => r.body);
  }
  //SignIn(): UserInfoEntity {
  //  if (this.UserInfoEntity == null) {
  //    let <a href="/"><b> H? th?ng Qu?n lý Mua S?m</b></a>JWT = this.GetCookie("VendorToken");
  //    let Vendor = VJWT.split(".")[1];
  //    if (Vendor == null) {
  //      console.error("Cannot recognize JWT token of Vendor!");
  //      return;
  //    }
  //    let vendor = JSON.parse(window.atob(Vendor));
  //    this.UserInfoEntity = vendor.VendorEntity;
  //  }
  //  return this.UserInfoEntity;
  //}

  //GetCookie(cname) {
  //  let name = cname + "=";
  //  let decodedCookie = decodeURIComponent(document.cookie);
  //  let ca = decodedCookie.split(';');
  //  for (let i = 0; i < ca.length; i++) {
  //    let c = ca[i];
  //    while (c.charAt(0) == ' ') {
  //      c = c.substring(1);
  //    }
  //    if (c.indexOf(name) == 0) {
  //      return c.substring(name.length, c.length);
  //    }
  //  }
  //  return "";
  //}
}
