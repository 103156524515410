import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from "../HttpService";
import { IEntity } from '../IEntity.Entity';
import { MessageEntity } from '../SignalRMessage/Models/Message.Entity';
@Injectable({
  providedIn: 'root'
})

export class NewFeedService extends HttpService<MessageEntity>{
  public url: string;

  constructor(private Http: HttpClient) {
    super(Http);

    this.url = "api/NewFeeds";
  }


}
