import { Component, OnInit, ViewChild } from '@angular/core';
import { OptionEntity } from './Modules/System/Models/Option.Entity';
import { Router } from '@angular/router';
import { globals } from './globals';
import { UserInfoService } from './Modules/Admin/Models/UserInfo.Service';
import { IEntity } from './Modules/IEntity.Entity';
import { SearchEntity } from './Modules/Search.Entity';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpService } from './Modules/HttpService';
import { OptionService } from './Modules/System/Models/Option.Service';
import { MegacrmDropdownComponent } from './shared/controls/megacrm-dropdown/megacrm-dropdown.component';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  nodes = [];

  connections = [];

  public doughnutChartLabels = ['Sales Q1', 'Sales Q2', 'Sales Q3', 'Sales Q4'];
  public doughnutChartData = [120, 150, 180, 90];
  public doughnutChartType = 'doughnut';
  constructor(private globalparam: globals,
    private router: Router,
    public title: Title,
    public UserInfoService: UserInfoService,
    public OptionService: OptionService,
    ) {
    this.globalparam.UserEntity = this.UserInfoService.GetCurrentUser();

    this.OptionService.GetOption().subscribe(p => {
      if (p.PageTitle == "" || p.PageTitle == null || p.PageTitle == undefined)
        p.PageTitle = "TopCRM";
      this.globalparam.options = p;
      this.title.setTitle(p.PageTitle);
    });

  }
  ngOnInit() {

  }

}
export class CommonCompoenent<T extends IEntity>{
  OptionEntity: OptionEntity = new OptionEntity();
  DefaultCCY: string = "VND";
  Entities: T[];
  Entity: T;
  RefNoEntity: any;
  EdittedEntity: T;
  Count: number;
  page: number = 1;
  Area: string = "";
  temp: T;
  EmtyGuid: string = "00000000-0000-0000-0000-000000000000";
  SearchEntity: SearchEntity = new SearchEntity();
  ListObjectType: Array<any> = [{ Name: "Khách hàng", ObjectType: "CUSTOMER" }, { Name: "Đối tác", ObjectType: "PARTNER" }, { Name: "Cộng tác viên", ObjectType: "COLLABO" }];
  constructor(public HttpService: HttpService<T>,
    public toastr: ToastrManager,
    public Router?: Router,
    public globalpara?: globals
  ) {
    this.OptionEntity = new OptionEntity();
  }

  FilterData(SearchEntity?: SearchEntity, OutEntities?: IEntity[], Total?: number) {
    let promise = new Promise((resolve, reject) => {
      SearchEntity.Skip = 10;//IsPaging ? this.PagingModel.Take * this.PagingModel.Active : 0;
      SearchEntity.Take = 100;//this.PagingModel.Take;
      this.HttpService.Filter(SearchEntity).subscribe(p => {
        OutEntities = p.ListEntity;
        Total = p.Count;
        resolve(p);
      }, e => {
        //this.toastr.ShowError(e.error);
        this.toastr.successToastr(e.error, 'Thông báo');

      });
      //this.Count(SearchEntity);
    })
    return promise;
  }

  Filter(SearchEntity?: SearchEntity, IsPaging?: boolean) {
    let promise = new Promise((resolve, reject) => {
      SearchEntity.Skip = 10;//IsPaging ? this.PagingModel.Take * this.PagingModel.Active : 0;
      SearchEntity.Take = 100;//this.PagingModel.Take;
      this.HttpService.Filter(SearchEntity).subscribe(p => {
        if (p.ListEntity != null && p.ListEntity != undefined) {
          this.Entities = p.ListEntity;
          this.Count = p.Count;
          resolve(p);
        }
        else
          this.toastr.errorToastr(p.MsgName, 'Thông báo');
      }, e => {
        //this.toastr.ShowError(e.error);
        this.toastr.errorToastr(e.error, 'Thông báo');

      });
    });
    return promise;
  }

  Search(SearchEntity?: SearchEntity, IsPaging?: boolean) {
    let promise = new Promise((resolve, reject) =>{
      SearchEntity.Skip = 10;//IsPaging ? this.PagingModel.Take * this.PagingModel.Active : 0;
      SearchEntity.Take = 100;//this.PagingModel.Take;
      this.HttpService.Gets(SearchEntity).subscribe(p => {
        let o: any = p;
        if (o.MsgCode == null || o.MsgCode == undefined) {
          if (p.ListEntity != null && p.ListEntity != undefined)
            this.Entities = p.ListEntity
          else
            this.Entities = p;

          resolve(this.Entities);
        }
        else {
          this.Entities = [];
          this.toastr.errorToastr(o.MsgName, 'Thông báo');
        }
      }, e => {
        //this.toastr.ShowError(e.error);
        this.toastr.errorToastr(e.error, 'Thông báo');

      });
    })
    return promise;
    //this.Count(SearchEntity);


  }

  GetId(Id: string) {
    let promise = new Promise((resolve, reject) => {
      this.HttpService.GetId(Id).subscribe(p => {
        this.Entity = p;
        resolve(this.Entity);
      });
    });
    return promise;
  }

  PrintVoucher(ID: string, CompanyID: string) {
    let param: any = { Area: this.Area, ID: ID, CompanyID: this.globalpara.UserEntity.CompanyID };
    this.HttpService.PrintVoucher(param);
  }

  Edit(T: T) {
    this.temp = JSON.parse(JSON.stringify(T));
    T.IsEdit = true;
  }

  Delete(T: T) {
    if (T != null) {
      this.HttpService.Delete(T.ID).subscribe(p => {
        let o: any;
        o = p;
        if (o.MsgCode == null || o.MsgCode == undefined) {
          let indexOf = this.Entities.indexOf(T);
          this.Entities.splice(indexOf, 1);
          this.Search(this.SearchEntity);
          this.toastr.successToastr('Xóa dữ liệu thành công.', 'Thông báo');
        }
        else
          this.toastr.errorToastr(o.MsgName, 'Thông báo');
        //this.toastr.ShowSuccess();
      }, e => {
        //this.toastr.ShowError(e);
        this.toastr.errorToastr('Xóa dữ liệu không thành công.', 'Thông báo');
      });
    }
    else
      this.toastr.warningToastr('Bạn chưa chọn dữ liệu cần xóa.', 'Thông báo');

  }

  DeleteBusiness(T: T) {
    if (T != null) {
      this.HttpService.Delete(T.ID).subscribe(p => {
        let o: any;
        o = p;
        if (o.MsgCode == null || o.MsgCode == undefined) {
          let indexOf = this.Entities.indexOf(T);
          this.Entities.splice(indexOf, 1);
          this.Filter(this.SearchEntity);
          this.toastr.successToastr('Xóa dữ liệu thành công.', 'Thông báo');
        }
        else
          this.toastr.errorToastr(o.MsgName, 'Thông báo');
        //this.toastr.ShowSuccess();
      }, e => {
        //this.toastr.ShowError(e);
        this.toastr.errorToastr('Xóa dữ liệu không thành công.', 'Thông báo');
      });
    }
    else
      this.toastr.warningToastr('Bạn chưa chọn dữ liệu cần xóa.', 'Thông báo');

  }

  Save(T: T, Type?: string, path?: string, index?: number) {
    if (T.ID === undefined || T.ID === null || T.ID == this.EmtyGuid) {
      this.HttpService.Create(T).subscribe(p => {

        if (p.MsgCode == "00" || p.MsgCode == "" || p.MsgCode == undefined) {
          if (this.Entities != null) {
            this.Entities[index] = p;
            this.Entities[index].IsEdit = false;
          } else {
            this.Entity = p;
          }

          this.toastr.successToastr('Cập nhật dữ liệu thành công.', 'Thông báo');
          this.Search(this.SearchEntity);
          T.IsEdit = false;
        }
        else {
          if (this.Entities != undefined && this.Entities != null) {
            if (index == undefined || index == null) index = 0;
            (<any>Object).assign(this.Entities[index], p);
          }
          else {
            (<any>Object).assign(this.Entity, p);
          }
          this.toastr.errorToastr(p.MsgName, 'Thông báo');
        }

        //}
        if (path != undefined && path != null && path != '') {
          setTimeout(() => { this.Router.navigate([path]) }, 1500);
        }
      }, e => {
        if (this.Entities != undefined && this.Entities != null) {
          if (index == undefined || index == null) index = 0;
          (<any>Object).assign(this.Entities[index], e.error);
        }
        else {
          (<any>Object).assign(this.Entity, e.error);
        }
        this.toastr.warningToastr('Bạn cần nhập đúng các trường dữ liệu.', 'Thông báo');

      });
    } else {
      this.HttpService.Update(T).subscribe(p => {

        if (p.MsgCode == "00") {
          T.IsEdit = false;
          if (this.Entities != null) {
            for (let i = 0; i < this.Entities.length; i++) {
              if (this.Entities[i].ID == p.ID) {
                this.Entities[i] = p;
                this.Entities[i].IsEdit = false;
              }
            }
          } else {
            this.Entity = p;
          }
          this.toastr.successToastr('Cập nhật dữ liệu thành công.', 'Thông báo');
          this.Search(this.SearchEntity);
        }
        else {
          if (this.Entities != undefined && this.Entities != null) {
            if (index == undefined || index == null) index = 0;
            (<any>Object).assign(this.Entities[index], p);
          }
          else {
            (<any>Object).assign(this.Entity, p);
          }
          this.toastr.errorToastr(p.MsgName, 'Thông báo');
        }
        //if (Type == 'Modal') {
        //  document.getElementById("TrickButtonAdd").click();
        this.Search(this.SearchEntity);
        //}
        if (path != undefined && path != null && path != '')
          setTimeout(() => { this.Router.navigate([path]) }, 1500);
      }, e => {
        if (this.Entities != undefined && this.Entities != null) {
          if (index == undefined || index == null) index = 0;
          (<any>Object).assign(this.Entities[index], e.error);
        } else {
          (<any>Object).assign(this.Entity, e.error);
        }
        this.toastr.warningToastr('Bạn cần nhập đúng các trường dữ liệu.', 'Thông báo');
        console.log(this.Entity);
      });
    }
  }
  Cancel(T: T, index?: number) {
    if (T.ID === this.EmtyGuid) {
      this.Entities.splice(index, 1);
    } else {
      for (let i = 0; i < this.Entities.length; i++) {
        if (this.Entities[i].ID == this.temp.ID) {
          //this.Entities[i] = this.temp;
          this.Entities[i].IsEdit = false;
        }
      }
    }
  }
  Remove(Ts: any[], T: any) {
    let index = Ts.indexOf(T);
    Ts.splice(index, 1);
  }

  AddFirst(Ts: T[], T: any) {
    T.IsEdit = true;
    T.Errors = {};
    Ts.unshift(T);
  }

  AddLast(Ts: any[], T: any) {
    T.IsEdit = true; T.Errors = {}; Ts.push(T);
  }

  HasOwnProperty(Dic, Key: string) {
    if (Dic !== null && Dic != undefined) {
      return Dic.hasOwnProperty(Key);
    } else {
      return false;
    }
  }

  GetError(Dic, Key: string) {
    if (this.HasOwnProperty(Dic, Key))
      return Dic[Key];
    else
      return null;
  }

  CheckIfFileIsImage(fileName: string) {
    if (fileName.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
      return true;
    } else {
      return false;
    }
  }
  //AddLast(Ts: any[], T: any, PagingEntity?: PagingModel) {
  //  T.IsEdit = true;
  //  T.Errors = {};
  //  Ts.push(T);
  //  if (PagingEntity != null) {
  //    this.FrontEndPagingModel(Ts, PagingEntity);
  //  }
  //}

}
