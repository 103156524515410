import { SearchEntity } from "../../../../Modules/Search.Entity";
export class DiscussionSearchEntity extends SearchEntity {

  ID: string;
  ObjectID: string;
  ObjectCode: string;
  ObjectName: string;
  ParentID: string;
  RefType: number;
  public constructor(ContactTaskSearchEntity: any = null) {
    super(ContactTaskSearchEntity);
  }
}
