import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ContentChild,
  TemplateRef,
  ElementRef,
  ViewChild,
  OnChanges,
  ChangeDetectorRef
} from '@angular/core';
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ClickOutSideModule } from '../../AttributeDirective/ClickOutSide.Directive';
import { HttpService } from '../../../Modules/HttpService';
import { IEntity } from '../../../Modules/IEntity.Entity';
import { SearchEntity } from '../../../Modules/Search.Entity';
import { TooltipModule } from 'primeng/components/tooltip/tooltip';

import { FilterListPipeModule } from '../../FilterList.pipe';
import * as $ from 'jquery';
//import {FilterListPipeModule} from '../../Pipe/FilterList.pipe';
import { debounceTime } from 'rxjs/operators';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export type DropdownPosition = 'bottom' | 'top' | 'auto';
@Component({
  selector: 'ngx-select',
  templateUrl: './ngx-select.component.html',
  styleUrls: ['./ngx-select.component.css'],
  providers: []
})
export class NgXSelectComponent implements OnInit, OnChanges {
  // The external data:
  @Input() ErrorEntity: any = null; // Show error value;
  @Input() Placeholder: string = ""; // Set placeholder for input field;
  @Input() Disabled: boolean = false; // Set disabled for input field;
  @Input() SearchProperty: string = ""; // Set property for searching;
  @Input() CopyValue: string = "Name"; // Set property for viewing in input filed;
  @Input() Service: HttpService<IEntity> = null; // Set API for get data;
  @Input() StyleWidth: number; // Set style width data box;
  @Input() IsBlur: boolean = false;
  @Input() Type: string = "Custom"; // Set type of select box (only 2 types are 'Custom' and 'Normal');
  @Input() ListItem: Array<IEntity> = []; // Set list item when Type is Normal ;
  @Input() Scroll: boolean = true;
  @Input() HasAllValue: boolean = false;
  @Input() HasNoneValue: boolean = false;
  @Input() Tooltip: any = null;
  @Input() TooltipPosition: any = null;
  @Input() Link: string; // Input Link Custom
  @Input() Clearable: boolean = false; // Clear data in input
  @Input() ClearInputData: boolean = false; // auto clear Data when action
  @Input() InputStyle: string = "NORMAL";
  @Input() idOfTable: string;
  @Input() // Set innit value for input box
  set InitValue(obj: any) {
    Object.assign(this.SelectedEntity, obj);
  }

  @Input() // Set SearchEntity for searching
  set InitSearch(obj: any) {
    Object.assign(this.SearchEntity, obj);
  }

  @Output() Change: EventEmitter<any> = new EventEmitter();
  @Output() Blur: EventEmitter<any> = new EventEmitter();

  // Read in our structural directives as TemplateRefs:
  @ContentChild('ngXSelectOption') ngXSelectOption: TemplateRef<ElementRef>;
  @ContentChild('ngXSelectLabel') ngXSelectLabel: TemplateRef<ElementRef>;
  @ViewChild('inputSelect') inputSelect: ElementRef;
  // The internal data model
  Value: any = {};
  IsShow: boolean = false;
  IsShowLoading: boolean = false;
  Model: string = '';
  SelectedEntity: any = {};
  DraftModel: string = '';
  Entities: Array<IEntity> = [];
  SearchEntity: SearchEntity = new SearchEntity();
  TotalEntity: number = 0;
  IDBox: string = this.MakeRandomId();
  IDNormalBox: string = this.MakeRandomId();
  constructor(
    public _elementRef: ElementRef<HTMLElement>,
    private _cd: ChangeDetectorRef,
) {

}
  ngOnInit() {
    if (this.Service == null || this.Type === 'Normal') return;
  }

  ngOnChanges() {
    if (this.ClearInputData) {
      setTimeout(() => {
        this.ClearData(this.SelectedEntity);
      }, 300)

    }
  }

  GetData(SearchEntity: SearchEntity) {
    if (this.Link == null || this.Link === undefined) {
      let promise = new Promise((resolve, reject) => {
        this.Service.Gets(SearchEntity, false).subscribe(x => {
          if (x.ListEntity !== undefined && x.ListEntity !== null) {
            this.Entities = x.ListEntity;
          } else {
            this.Entities = x;
          }
          this.TotalEntity = x.Count;
          if (this.HasAllValue) {
            this.Value[this.CopyValue] = 'ALL';
            this.Entities.unshift(this.Value);
          }
          if (this.HasNoneValue) {
            this.Value[this.CopyValue] = 'NONE';
            this.Entities.unshift(this.Value);
          }
          this.IsShowLoading = false;
          resolve(this.Entities);
        });
      });
      return promise;
    }
    // setTimeout(() => {
    //   if (this.Link == null || this.Link === undefined) {
    //     let promise = new Promise((resolve, reject) => {
    //       this.Service.Gets(SearchEntity, false).subscribe(x => {
    //         if (x.ListEntity !== undefined && x.ListEntity !== null) {
    //           this.Entities = x.ListEntity;
    //         } else {
    //           this.Entities = x;
    //         }
    //         this.TotalEntity = x.Count;
    //         if (this.HasAllValue) {
    //           this.Value[this.CopyValue] = 'ALL';
    //           this.Entities.unshift(this.Value);
    //         }
    //         if (this.HasNoneValue) {
    //           this.Value[this.CopyValue] = 'NONE';
    //           this.Entities.unshift(this.Value);
    //         }
    //         this.IsShowLoading = false;
    //         resolve(this.Entities);
    //       });
    //     });
    //     return promise;
    //   }

    //   //else {
    //   //    this.Service.GetCustom(SearchEntity, false, this.Link).subscribe(x => {
    //   //       // this.Entities = x.ListEntity;
    //   //        if(x.ListEntity !== undefined && x.ListEntity !== null){
    //   //            this.Entities = x.ListEntity;
    //   //        }else{
    //   //            this.Entities = x;
    //   //        }
    //   //        if (this.HasAllValue) {
    //   //            this.Value[this.CopyValue] = 'ALL';
    //   //            this.Entities.unshift(this.Value);
    //   //        }
    //   //        if (this.HasNoneValue) {
    //   //            this.Value[this.CopyValue] = 'NONE';
    //   //            this.Entities.unshift(this.Value);
    //   //        }
    //   //        this.IsShowLoading = false;
    //   //    });
    //   //}
    // }, 400);
  }


  SearchingData(event) {
    if (this.CheckKeyPress(event)) {
      if (this.Type === 'Custom') {
        this.IsShowLoading = true;
        if (this.SearchProperty.includes('.')) {
          let Array = this.SearchProperty.split('.');
          this.SearchEntity[Array[0]][Array[1]] = this.Model;
        } else {
          this.SearchEntity[this.SearchProperty] = this.Model;
        }
        debounceTime(400);
        this.SearchEntity.Skip = 0;
        this.SearchEntity.Take = 10;
        this.SearchEntity.PageIndex = 1;
        this.GetData(this.SearchEntity);
      } else return;
    }
  }

  FirstLoad(event?) {
    if (this.Disabled) return;
    this.IsShow = !this.IsShow;
      this.Model = '';
      // TODO: Find class .table-responsvive
      const targetElement = $(this._elementRef.nativeElement).closest('div.table-responsive');
      if (targetElement !== null) {
          targetElement.addClass('selected-dropdown');
      }
    if (this.SearchProperty.includes(".")) {
      let Array = this.SearchProperty.split(".");
      this.SearchEntity[Array[0]] = new Object();
      this.SearchEntity[Array[0]][Array[1]] = null;
    } else {
      this.SearchEntity[this.SearchProperty] = null;
    }
    this.SearchEntity.Skip = 0;
    this.SearchEntity.PageIndex = 1;
    if (this.Type === "Custom") {
      this.IsShowLoading = true;
        this.GetData(this.SearchEntity);
    } else {
      this.Entities = this.ListItem;
      this.TotalEntity = this.Entities.length;
    }


    if (event) {
      event.stopPropagation();
    }
  }

  BlurInput() {
    if (this.IsBlur && this.Entities.length === 0 && this.Model.length > 0) {
      let NewObject = new Object();
      NewObject[this.CopyValue] = this.Model;
      Object.assign(this.SelectedEntity, NewObject);
      this.Blur.emit(this.Model);
    }
    return;
  }

  CheckKeyPress(event) {
    if (event.code !== "ArrowDown") return true;
    if (this.Type === 'Custom') (<HTMLElement>document.getElementById(this.IDBox).firstElementChild).focus();
    else (<HTMLElement>document.getElementById(this.IDNormalBox).firstElementChild).focus();
    return false;
  }

  Move(event, Entity) {
    switch (event.code) {
      case "Enter":
        this.SelectEntity(Entity);
        break;
      case "ArrowDown":
        if (event.target.nextElementSibling !== null)
          event.target.nextElementSibling.focus();
        event.preventDefault();
        break;
      case "ArrowUp":
        if (event.target.previousElementSibling !== null)
          event.target.previousElementSibling.focus();
        event.preventDefault();
        break;
    }
    return;
  }

  CheckScroll() {
    if (this.Scroll) {
      let scrollTop = $("#" + this.IDBox)[0].scrollTop;
      let clientHeight = $("#" + this.IDBox)[0].clientHeight;
      let scrollHeight = $("#" + this.IDBox)[0].scrollHeight;
      if ((scrollHeight - scrollTop) <= clientHeight + 1) {
        if (this.Entities.length < this.TotalEntity) {
          this.SearchEntity.Skip = this.SearchEntity.Skip + 10;
          this.SearchEntity.PageIndex++;
          if (this.Link == null || this.Link === undefined) {
            this.Service.Gets(this.SearchEntity, false).subscribe(x => {
              if (x.ListEntity !== undefined && x.ListEntity !== null) {
                this.Entities = [...this.Entities, ...x.ListEntity];
                document.getElementById(this.IDBox).scrollTo(0, scrollTop);
              }
              else {
                this.Entities = [...this.Entities, ...x];
                document.getElementById(this.IDBox).scrollTo(0, scrollTop);
              }
            });
          }
          //else {
          //    this.Service.GetCustom(this.SearchEntity, false, this.Link).subscribe(x => {
          //        if(x.ListEntity !== undefined && x.ListEntity !== null){
          //            this.Entities = [...this.Entities, ...x.ListEntity];
          //            document.getElementById(this.IDBox).scrollTo(0, scrollTop);
          //        }
          //        else{
          //            this.Entities = [...this.Entities, ...x];
          //            document.getElementById(this.IDBox).scrollTo(0, scrollTop);
          //        }
          //    });
          //}
        }
      }
    } else return;
  }

  SelectEntity(Entity, event?) {
    let EmitEntity = JSON.parse(JSON.stringify(Entity));
      this.SelectedEntity = JSON.parse(JSON.stringify(Entity));
      const targetElement = $(this._elementRef.nativeElement).closest('div.table-responsive');
      if (targetElement !== null) {
          targetElement.removeClass('selected-dropdown');
      }
    this.Change.emit(EmitEntity);
    this.ErrorEntity = null;
    if (event !== null && event !== undefined) event.stopPropagation();
    this.IsShow = false;
  }

  ClearData(Entity, event?) {
    let EmitEntity = new Object();
    let NewObject = new Object();
    Object.assign(this.SelectEntity, NewObject);
    this.SelectedEntity = new Object();
    Object.assign(Entity, NewObject);
    this.Model = '';
    NewObject[this.CopyValue] = this.Model;
    this.Change.emit(EmitEntity);
    if (Boolean(event)) {
      event.stopPropagation();
    }

  }

  ClickOutSide() {
   
    this.IsShow = false;
    const targetElement = $(this._elementRef.nativeElement).closest('div.table-responsive');
    if (targetElement !== null) {
        targetElement.removeClass('selected-dropdown');
    }
    this.BlurInput();
  }

  Copy(event) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.SelectedEntity[this.CopyValue] || null;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    event.stopPropagation();
  }

  MakeRandomId(): string {
    let text = '';
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }
}

@NgModule({
  declarations: [
    NgXSelectComponent
  ],
  imports: [
    FormsModule, CommonModule, ClickOutSideModule, TooltipModule, FilterListPipeModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [],
  exports: [
    NgXSelectComponent
  ]
})
export class NgXSelectModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
