import { IEntity } from "../../IEntity.Entity";

export class MessageEntity extends IEntity {
  UserID: string;
  UserName: string;
  CreatedUserID: string;
  CreatedUserName: string;
  Message: string;
  Action: string;
  ActionName: string;
  CompanyID: string;
  Type: number;
  TypeName: string;
  TypeLink: string;
  RefID: string;
  RefNo: string;
  RefName: string;
  MessageTime: string
  IsRead: boolean;
  TotalAmountOC: number;
  TotalAmount: number;
  constructor() {
    super();
  }
}  
