/// <reference path="../models/message.entity.ts" />
import { EventEmitter, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { MessageEntity } from '../Models/Message.Entity';
import { globals } from '../../../globals';
@Injectable()
export class SignalRService {
  ConnectNumber: number = 0;//Số lần try reconnect hub
    //Kết nối lại tối đa bao nhiêu lần, nếu ko thiết lập có thể dẫn đến treo trình duyệt do két nối liên tục
  MaxReconnectNumber: number = 100;
  messageReceived = new EventEmitter<MessageEntity>();
  connectionEstablished = new EventEmitter<Boolean>();
    private connectionIsEstablished = false;
  private _hubConnection: HubConnection;

  constructor(private globalparam: globals) {
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();

    this._hubConnection.onclose(() => {
      if (this.ConnectNumber <= this.MaxReconnectNumber) {
        this._hubConnection
          .start()
          .then(() => {
            this.connectionIsEstablished = true;
            console.log('Hub connection started');
            this.connectionEstablished.emit(true);
          })
          .catch(err => {
            this.ConnectNumber++;
            console.log('Error while establishing connection, retrying...');
            setTimeout(this.startConnection(), 10000);
          });
      }
      else {
        setTimeout(function () {
          this.ConnectNumber = 0;
        }, 10000)
      }

    });
  }

  sendMessage(message: MessageEntity) {
    this._hubConnection.invoke('SendMessage', message);
  }

  private createConnection() {    
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(window.location.origin + '/messagehub')
      .build();
  }

  private startConnection(): any {
    if (this.ConnectNumber <= this.MaxReconnectNumber) {
      this._hubConnection
        .start()
        .then(() => {
          this.connectionIsEstablished = true;
          console.log('Hub connection started');
          this.connectionEstablished.emit(true);
        })
        .catch(err => {
          console.log('Error while establishing connection, retrying...');
          setTimeout(this.startConnection(), 10000);
        });
    }
  }

  private registerOnServerEvents() {
    if (this.globalparam.UserEntity != null) {
      this._hubConnection.on(this.globalparam.UserEntity.CompanyID + '&' + this.globalparam.UserEntity.ID, (data: any) => {
        this.messageReceived.emit(data);
      });
    }
   
  }



}
