import { Component, forwardRef, Input, Output, EventEmitter, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule } from '@angular/forms';
import { noop } from "rxjs/util/noop";
import { CommonModule } from '@angular/common';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpService } from '../../../Modules/HttpService';
import { IEntity } from '../../../Modules/IEntity.Entity';
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => custominputcomponent),
  multi: true
};

@Component({
  selector: 'custom-input',
  templateUrl: './custominput.component.html',
  styleUrls: ['./custominput.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class custominputcomponent implements ControlValueAccessor {
  @Input() Service: HttpService<IEntity> = null;
  //The internal data model
  @Input() ErrorEntity: any;
  @Input() placeholder: string = "";
  @Input() Type: string;
  @Input() Disabled: boolean;
  @Input() Suffix: any = '';
  @Input() Option: any;
  @Input() Rows: any = 2;
  @Input() Align: any;
  @Input() Thousands: any;
  @Output() Change: EventEmitter<any> = new EventEmitter();
  @Output() Blur: EventEmitter<any> = new EventEmitter();
  @Output() Enter: EventEmitter<any> = new EventEmitter();
  private innerValue: any = '';

  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //get accessor
  get value(): any {
    return this.innerValue;

  };

  GetRefNo() {
    this.Service.GetRefNo().subscribe(p => {
      this.innerValue = p.RefNo;
      this.onChangeCallback(this.innerValue);
    });
  }

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  //Set touched on blur
  onBlur(value) {
    this.Blur.emit(value);
    this.onTouchedCallback();
  }

  //Set value default:
  Keypress(value) {
    if (value === undefined || value === null || value === NaN || value === "")
      value = 0;
    else value = parseFloat(value);
    this.value = value;
    this.onTouchedCallback();
  }

  //Set enter:
  KeypressEnter(value) {
    this.Enter.emit(value);
    this.onTouchedCallback();
  }

  //Set Change:
  onChange(value) {
    this.Change.emit(value);
    this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

}
@NgModule({
  declarations: [
    custominputcomponent,
  ],
  imports: [
    FormsModule, CommonModule, CurrencyMaskModule, ReactiveFormsModule
  ],
  exports: [
    custominputcomponent
  ]
})
export class CustomInputModule { }
