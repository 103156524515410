/// <reference path="../../../report/models/report.service.ts" />
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ReportService } from '../../../Report/Models/Report.Service';
import { TotalCustomerRequestEntity } from 'src/app/Modules/Report/Models/TotalCustomerRequest.Entity';
import { window } from 'rxjs/operators';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-salebymarket',
  templateUrl: './salebymarket.component.html',
  styleUrls: ['./salebymarket.component.css']
})
export class SalebymarketComponent implements OnInit {

  @ViewChild("divchart") divchart: ElementRef;
  ChartHeight: number = 170;
  LoadChart: boolean = false;
  polarAreaChartLabels: string[] = ['', '', '', '', '', '', '', '', '', '', '', ''];
  polarAreaChartData: any[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  polarAreaLegend = true;
  polarAreaChartType = 'doughnut';

  ChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    responsiveAnimationDuration: 0,
    legend: {
      display: true,
      position: 'top',
      align: 'center'

    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          label += ': ' + data.datasets[0].data[tooltipItem.index].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return label;
        },
      }
    },
    //scales: {
    //  yAxes: [
    //    {
    //      stacked: true,
    //      ticks: {
    //        beginAtZero: false,
    //        callback: function (value, index, values) {
    //          if (parseInt(value) > 999) {
    //            return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //          } else if (parseInt(value) < -999) {
    //            return '-$' + Math.abs(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //          } else {
    //            return '$' + value;
    //          }
    //        }
    //      },
    //      gridLines: {
    //        display: false,
    //        color: 'rgba(255,99,132,0.2)',
    //      },

    //    },

    //  ],
    //  xAxes: [
    //    {
    //      stacked: true,
    //      ticks: {
    //        // beginAtZero: true,
    //      },
    //      gridLines: {
    //        display: false,
    //      },
    //    },
    //  ],
    //},
  };

  constructor(public ReportService: ReportService) {
    this.LoadData();

    //this.ChartHeight = this.divchart.nativeElement.offsetHeight;
  }
  ngAfterViewInit() {

  }
  ngOnInit() {

  }

  LoadData() {
    this.ReportService.GetReportSaleByMarket(new TotalCustomerRequestEntity()).subscribe(p => {
      this.polarAreaChartLabels = [];
      this.polarAreaChartData = [];
 
      p.forEach(data => {
        this.polarAreaChartLabels.push(data.name);
        this.polarAreaChartData.push(data.y.toString());
      });

      this.LoadChart = true;
    })
  }
}
