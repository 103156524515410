import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountEntity } from './Account.Entity';
import { HttpService } from "../../HttpService";
import { IEntity } from '../../IEntity.Entity';
import { ShortUserInfoEntity } from '../../Admin/Models/ShortUserInfo.Entity';
import { AnalystUserInfoEntity } from '../../Admin/Models/AnalystUserInfo.Entity';
import { UserInfoEntity } from '../../Admin/Models/UserInfo.Entity';
@Injectable({
  providedIn: 'root'
})

export class AccountService extends HttpService<AccountEntity>{
  public url: string;

  constructor(private Http: HttpClient) {
    super(Http);
    this.url = "api/Accounts";
  }

  GetUserByRole(RoleID: string, IsShowLoading?: boolean): Observable<AccountEntity[]> {
    return this.intercept(this.http.get<AccountEntity[]>(`${this.url}/${RoleID}` + '/GetUserByRole', {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  GetUserForAddRole(RoleID: string, IsShowLoading?: boolean): Observable<AccountEntity[]> {
    return this.intercept(this.http.get<AccountEntity[]>(`${this.url}/${RoleID}` + '/GetUserForAddRole', {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  GetUserByProject(ProjectID: string, IsShowLoading?: boolean): Observable<AccountEntity[]> {
    return this.intercept(this.http.get<AccountEntity[]>(`${this.url}/${ProjectID}` + '/GetUserByProject', {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  GetUserForAddProject(ProjectID: string, IsShowLoading?: boolean): Observable<AccountEntity[]> {
    return this.intercept(this.http.get<AccountEntity[]>(`${this.url}/${ProjectID}` + '/GetUserForAddProject', {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }

  ChangePassword(body: IEntity, IsShowLoading?: boolean): Observable<ShortUserInfoEntity> {
    return this.intercept(this.http.post<Array<IEntity>>(this.url + '/ChangePassword', JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }

  UpdateProfile(body: IEntity, IsShowLoading?: boolean): Observable<boolean> {
    return this.intercept(this.http.post<Array<IEntity>>(this.url + '/UpdateProfile', JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  UpdateAvartar(body: IEntity, IsShowLoading?: boolean): Observable<boolean> {
    return this.intercept(this.http.post<Array<IEntity>>(this.url + '/UpdateAvartar', JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }

  GetUserInfo(IsShowLoading?: boolean): Observable<UserInfoEntity> {
    return this.intercept(this.http.get<UserInfoEntity>(`${this.url}` + '/GetUserInfo', {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  GetAnalystUserInfo(IsShowLoading?: boolean): Observable<AnalystUserInfoEntity> {
    return this.intercept(this.http.get<AnalystUserInfoEntity>(`${this.url}` + '/GetAnalystUserInfo', {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
}
