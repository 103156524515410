import { Directive, ElementRef, EventEmitter, Output, HostListener, NgModule} from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutSideDirective {
  constructor(private _elementRef: ElementRef) {

  }
  @Output()
  public clickOutside: EventEmitter<any> = new EventEmitter();
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit(null);

    }

  }
}
@NgModule({
  declarations: [
    ClickOutSideDirective
  ],
  imports: [
  ],
  exports: [
    ClickOutSideDirective
  ],
  schemas: [],
})
export class ClickOutSideModule { }
