/// <reference path="../../date-formater.ts" />
import {Component, ElementRef, forwardRef, Input, OnInit, ViewChild, NgModule} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from "@angular/forms";
import {noop} from "rxjs/util/noop";
import {NgbDateISOParserFormatter} from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-parser-formatter";
import { CommonModule } from '@angular/common';
import { NgbModule, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateParserFormatterEsMX } from '../../date-formater';
import * as $ from 'jquery';
import { ClickOutSideModule } from '../../AttributeDirective/ClickOutSide.Directive';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatetimeComponent),
    multi: true
};

@Component({
    selector: 'datetime',
    templateUrl: './datetime.component.html',
    styleUrls: ['./datetime.component.css'],
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateParserFormatterEsMX }, CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class DatetimeComponent implements ControlValueAccessor, OnInit {
  @Input() Type: string = 'CUSTOM';
  @Input() ErrorEntity: any;
  @Input() placeholder: string = "";
  @Input() Disabled: boolean;
  @Input() NgModel: any;
    @ViewChild("d3") d3;
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;
  private ngbDateISOParserFormatter = new NgbDateParserFormatterEsMX();
    private innerValue: any = '';

    constructor(private el: ElementRef) {

    }

    //get accessor
    get value(): any {
        return this.innerValue;
    };

    //set accessor including call the onchange callback
  set value(v: any) {   
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(this.ngbDateISOParserFormatter.format(v));
        }
    }
  
  //Set touched on blur
  onBlur(value) {
    if (value == null) {
      this.onTouchedCallback();
      return
    }
    let StringDate = value;
    let NewDate;
    switch (StringDate.length) {
      case 8:
        if (parseInt(StringDate.substring(2, 4)) > 12) {
          StringDate = 'error';
          break
        } else {
          StringDate = StringDate.substr(-4) + '/' + StringDate.substring(2, 4) + '/' + StringDate.substring(0, 2);
          break
        }
      case 6:
        if (parseInt(StringDate.substring(2, 4)) > 12) {
          StringDate = 'error';
          break
        } else {
          StringDate = '20' + StringDate.substr(-2) + '/' + StringDate.substring(2, 4) + '/' + StringDate.substring(0, 2);
          break
        }
      case 10:
        if (StringDate.substring(2, 3) == ' ' || StringDate.substring(2, 3) == '/') {
          StringDate = 'error';
        } else {
          StringDate = StringDate.substr(-4) + '/' + StringDate.substring(3, 5) + '/' + StringDate.substring(0, 2);
        }
        break;
      default:
        StringDate = 'error';
        break
    }
   
    if (StringDate !== 'error') {
      this.innerValue = this.ngbDateISOParserFormatter.parse(StringDate);
      this.value = this.ngbDateISOParserFormatter.parse(StringDate);
    } else {
      this.innerValue = null;
    }
  }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        if (this.ngbDateISOParserFormatter.parse(value) !== this.innerValue) {
            this.innerValue = this.ngbDateISOParserFormatter.parse(value);
        }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    ngOnInit() {
        window.addEventListener("click", (event) => {
            if (!this.isDescendant(this.el.nativeElement, event.target)) {
                this.d3.close();
            }
        });
    }

    isDescendant(parent, child) {
        let node = child.parentNode;
        while (node != null) {
            if (node == parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    firstload() {
        // TODO: Find class .table-responsvive
        const targetElement = $(this.el.nativeElement).closest('div.table-responsive');
        if (targetElement !== null) {
            targetElement.addClass('selected-datetime');
        }
        if (event) {
            event.stopPropagation();
        }
    }

    RemoveClass() {
        const targetElement = $(this.el.nativeElement).closest('div.table-responsive');
        if (targetElement !== null) {
            if (targetElement.hasClass('selected-datetime')) {
                targetElement.removeClass('selected-datetime');
            }
        }
    }

  ClickOutSide() {
   
        const targetElement = $(this.el.nativeElement).closest('div.table-responsive');
        if (targetElement !== null) {
            if (targetElement.hasClass('selected-datetime')) {
                targetElement.removeClass('selected-datetime');
            }
        }
    }
}

@NgModule({
    declarations: [
        DatetimeComponent,
    ],
    imports: [
      FormsModule, CommonModule, NgbModule, ClickOutSideModule
    ],
    exports: [
        DatetimeComponent,
    ]
})

export class DatetimeModule { }
