import { IEntity } from "../../IEntity.Entity";
export class AvartarUserInfoEntity extends IEntity {
  ID: string;
  FilePath: string;
  FileName: string;
  FileData: any;
  public constructor() {
    super();
  }
}
