import 'rxjs/Rx';
import {Observable} from 'rxjs/Rx';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { HttpService } from "./../../../../Modules/HttpService";
import { IEntity } from '../../../../Modules/IEntity.Entity';
import { SearchEntity } from '../../../../Modules/Search.Entity';
import {DiscussionEntity, ResponseDiscussionEntity} from './../Models/Discussion.Entity'
import {DiscussionSearchEntity} from './../Models/Discussion.SearchEntity'
@Injectable({
    providedIn: 'root'
})

export class DiscussionService extends HttpService<DiscussionEntity>{
    public url: string;

    constructor(private Http: HttpClient) {
        super(Http);
        this.url = "api/Discussions";
    }
    
    DeleteComment(Id: string,RefType: any, IsShowLoading?: boolean): Observable<boolean>{
      return this.intercept(this.http.delete(`${this.url}/${Id}/${RefType}`, {
        observe: 'response',
        headers: this.GetHeaders()
      }), IsShowLoading).map(r => r.body);
    }

    GetResponseDiscussion(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<ResponseDiscussionEntity> {
      SearchParam = SearchParam === undefined ? new SearchEntity() : SearchParam;
      return this.intercept(this.http.get<ResponseDiscussionEntity>(this.url, {
        observe: 'response',
        headers: this.GetHeaders(),
        params: SearchParam.ToParams()
      }), IsShowLoading).map(r => r.body);
    }
}
