import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OptionEntity } from './Option.Entity';
import { HttpService } from "../../HttpService";
import { IEntity } from '../../IEntity.Entity';
@Injectable({
  providedIn: 'root'
})

export class OptionService extends HttpService<OptionEntity>{
  public url: string;

  constructor(private Http: HttpClient) {
    super(Http);

    this.url = "api/Options";
  }

  GetOption(IsShowLoading?: boolean): Observable<OptionEntity> {    
    return this.intercept(this.http.get<OptionEntity>(`${this.url}/GetOption`, {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  GetPageSetting(IsShowLoading?: boolean): Observable<OptionEntity> {    
    return this.intercept(this.http.get<OptionEntity>(`${this.url}/GetPageSetting`, {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  UpdatePageSetting(body: OptionEntity, IsShowLoading?: boolean): Observable<OptionEntity> {
    return this.intercept(this.http.post<OptionEntity>(`${this.url}/UpdatePageSetting`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  TestConnection(body: OptionEntity, IsShowLoading?: boolean): Observable<boolean> {
    return this.intercept(this.http.put<boolean>(this.url + `/TestConnection`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
}
