import { IEntity } from "../../IEntity.Entity";
import { formatDate } from "@angular/common";
import { EmailAttachmentEntity } from './EmailAttachment.Entity';
export class EmailEntity extends IEntity {
  To: string;
  From: string;
  Subject: string;
  Cc: string;
  HtmlContent: string;
  ListAttachment: Array<EmailAttachmentEntity> = [];
  EmailReplyID: string;
  EmailForwardID: string;
  HtmlContentReply: string;
  public constructor(Partner: any = null) {
    super();
  }
}
