import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../Models/Dashboard.Service';
import { EventInMonthEntity } from '../../Models/EventInMonth.Entity';
import { SearchEntity } from 'src/app/Modules/Search.Entity';
import { NgxSmartModalService } from 'ngx-smart-modal';
@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit {
  Entities: Array<EventInMonthEntity> = [];
  sEmailReceiver: string;
  constructor(public DashboardService: DashboardService,
    public ngxSmartModalService: NgxSmartModalService) { this.LoadData(); }

  ngOnInit() {
  }
  LoadData() {
    this.DashboardService.GetEventInMonth(new SearchEntity()).subscribe(p => {
      this.Entities = p;
    })
  }
  ComposeMail(email: string) {
    this.sEmailReceiver = email;
    this.ngxSmartModalService.getModal('composeemailmodal').open();
  }
}
