/// <reference path="taskreportresponse.entity.ts" />
import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from "../../HttpService";
import { IEntity } from '../../IEntity.Entity';
import { TotalCustomerResponseEntity } from './TotalCustomerResponse.Entity';
import { SearchEntity } from '../../Search.Entity';
import { CustomerIncreamentResponseEntity } from './CustomerIncreamentResponse.Entity';
import { SaleByProductCategoryResponseEntity } from './SaleByProductCategoryResponse.Entity';
import { ReportBaseResponseEntity } from './ReportBaseResponse.Entity';
import { ReportBaseEmployeeResponseEntity } from './ReportBaseEmployeeResponse.Entity';
import { ReportBaseContactResponseEntity } from './ReportBaseContactResponse.Entity';
import { SaleAmountIncreamentResponseEntity } from './SaleAmountIncreamentResponse.Entity';
import { ReportTaskEmployeeResponseEntity } from './ReportTaskEmployeeResponse.Entity';
import { TaskReportResponseEntity } from './TaskReportResponse.Entity';
import { ReportTaskRequestEntity } from './reporttaskrequest.entity';
import { ReportTaskEmployeeDetailEntity } from './ReportTaskEmployeeDetail.Entity';
import { ProjectEntity } from '../../Task/Models/Project.Entity';
import { TaskTagEntity } from '../../task/models/TaskTag.Entity';
import { ReportTaskByEmployeeEntity } from './ReportTaskByEmployee.Entity';
import { DeliveryOrderSummaryEntity } from './DeliveryOrderSummary.Entity';
import { DeliveryOrderDetailEntity } from '../../DeliveryOrder/Models/DeliveryOrderDetail.Entity';
import { ReportConversionRateEntity } from './ReportConversionRate.Entity';

@Injectable({
  providedIn: 'root'
})

export class ReportService{
  public url: string;
  public PendingRequests: number = 0;
  public ShowLoading: boolean = false;
  constructor(private Http: HttpClient) {

    this.url = "api/Reports";
  }

  GetReportTotalCustomer(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<TotalCustomerResponseEntity[]> {
    return this.intercept(this.Http.get<TotalCustomerResponseEntity[]>(`${this.url}/ReportTotalCustomer`, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }

  GetReportCustomerIncreament(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<CustomerIncreamentResponseEntity[]> {
    return this.intercept(this.Http.get<CustomerIncreamentResponseEntity[]>(`${this.url}/ReportCustomerIncreament`, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }

  GetReportSaleByProductCategory(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<SaleByProductCategoryResponseEntity[]> {
    return this.intercept(this.Http.get<SaleByProductCategoryResponseEntity[]>(`${this.url}/ReportSaleByProductCategory`, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }

  GetReportSaleByContactCategory(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<ReportBaseResponseEntity[]> {
    return this.intercept(this.Http.get<ReportBaseResponseEntity[]>(`${this.url}/ReportSaleByContactCategory`, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }
  GetReportSaleByEmployee(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<ReportBaseEmployeeResponseEntity[]> {
    return this.intercept(this.Http.get<ReportBaseEmployeeResponseEntity[]>(`${this.url}/ReportSaleByEmployee`, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }
  GetReportSaleByContact(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<ReportBaseContactResponseEntity[]> {
    return this.intercept(this.Http.get<ReportBaseContactResponseEntity[]>(`${this.url}/ReportSaleByContact`, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }
  GetReportIncreamentSaleAmount(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<SaleAmountIncreamentResponseEntity[]> {
    return this.intercept(this.Http.get<SaleAmountIncreamentResponseEntity[]>(`${this.url}/ReportIncreamentSaleAmount`, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }
  GetTaskReport(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<TaskReportResponseEntity[]> {
    return this.intercept(this.Http.get<SaleAmountIncreamentResponseEntity[]>(`${this.url}/GetTaskReport`, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }
  PrintReportSaleAmount(data: any) {
    return window.open(`${this.url}` + "/ReportTotalSaleAmount/?PeriodType=" + data.PeriodType + "&PeriodValue=" + data.PeriodYear + "&DebitAccount=" + data.DebitAccount + "&CreditAccount=" + data.CreditAccount + "&BranchSearchID=" + data.BranchSearchID + "&ReportCode=ReportTotalSaleAmount");
  }
  ReportTotalExpense(data: any) {
    return window.open(`${this.url}` + "/ReportTotalExpense/?PeriodType=" + data.PeriodType + "&PeriodValue=" + data.PeriodYear + "&DebitAccount=" + data.DebitAccount + "&CreditAccount=" + data.CreditAccount + "&BranchSearchID=" + data.BranchSearchID + "&ReportCode=ReportTotalExpense");
  }

  GetReportSaleByMarket(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<ReportBaseResponseEntity[]> {
    return this.intercept(this.Http.get<ReportBaseResponseEntity[]>(`${this.url}/ReportSaleByMarket`, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }
  GetReportTaskByProject(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<ProjectEntity[]> {
    return this.intercept(this.Http.post<ProjectEntity[]>(`${this.url}/GetReportTaskByProject`, JSON.stringify(SearchParam), {
      observe: 'response',
      headers: this.GetHeaders()

    }), IsShowLoading).map(r => r.body);
  }
  GetTaskSummary(ReportType:number,PeriodType: number, PeriodValue: number, IsShowLoading?: boolean): Observable<ReportBaseResponseEntity[]> {
    return this.intercept(this.Http.get<ReportBaseResponseEntity[]>(`${this.url}/GetTaskSummary/${ReportType}/${PeriodType}/${PeriodValue}`,  {
      observe: 'response',
      headers: this.GetHeaders()

    }), IsShowLoading).map(r => r.body);
  }
  GetTaskDetail(ReportType: number, PeriodType: number, PeriodValue: number, IsShowLoading?: boolean): Observable<TaskTagEntity[]> {
    return this.intercept(this.Http.get<TaskTagEntity[]>(`${this.url}/GetTaskList/${ReportType}/${PeriodType}/${PeriodValue}`, {
      observe: 'response',
      headers: this.GetHeaders()

    }), IsShowLoading).map(r => r.body);
  }
  GetTaskByProject(ProjectID: string, IsShowLoading?: boolean): Observable<ReportBaseResponseEntity[]> {
    return this.intercept(this.Http.get<ReportBaseResponseEntity[]>(`${this.url}/GetTaskByProject/${ProjectID}`, {
      observe: 'response',
      headers: this.GetHeaders()

    }), IsShowLoading).map(r => r.body);
  }
  GetTaskEmployeeByProject(ProjectID: string, IsShowLoading?: boolean): Observable<ReportTaskEmployeeResponseEntity[]> {
    return this.intercept(this.Http.get<ReportTaskEmployeeResponseEntity[]>(`${this.url}/GetTaskEmployeeByProject/${ProjectID}`, {
      observe: 'response',
      headers: this.GetHeaders()

    }), IsShowLoading).map(r => r.body);
  }

  GetTaskEmployeeDetail(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<ReportTaskEmployeeDetailEntity[]> {
    return this.intercept(this.Http.post<ReportTaskEmployeeDetailEntity[]>(`${this.url}/GetTaskEmployeeDetail`, JSON.stringify(SearchParam), {
      observe: 'response',
      headers: this.GetHeaders()

    }), IsShowLoading).map(r => r.body);
  }

  GetTaskByEmployee(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<ReportTaskByEmployeeEntity[]> {
    return this.intercept(this.Http.post<ReportTaskByEmployeeEntity[]>(`${this.url}/GetTaskByEmployee`, JSON.stringify(SearchParam), {
      observe: 'response',
      headers: this.GetHeaders()

    }), IsShowLoading).map(r => r.body);
  }

  GetReportDeliveryOrderSummary(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<DeliveryOrderSummaryEntity[]> {
    return this.intercept(this.Http.get<DeliveryOrderSummaryEntity[]>(`${this.url}/ReportDeliveryOrderSummary`, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }
  GetReportDeliveryOrderDetail(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<DeliveryOrderDetailEntity[]> {
    return this.intercept(this.Http.get<DeliveryOrderDetailEntity[]>(`${this.url}/ReportDeliveryOrderDetail`, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }

  GetReportConversionRate(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<ReportConversionRateEntity[]> {
    return this.intercept(this.Http.get<ReportConversionRateEntity[]>(`${this.url}/ReportConversionRate`, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }

  DownloadReportDeliveryOrderSummary() {
    return window.open(`${this.url}/` + "DownloadDeliveryOrderSummary");
  }

  ExportReportDeliveryOrderSummary(body: SearchEntity, IsShowLoading?: boolean): Observable<boolean> {
    return this.intercept(this.Http.get(this.url + `/ExportDeliveryOrderSummary`, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: body.ToParams()
    }), IsShowLoading).map(r => r.body);
  }



  public intercept(observable: Observable<HttpResponse<any>>, isShowLoading?: boolean): Observable<HttpResponse<any>> {
    if (isShowLoading == null) {
      //console.warn('isShowLoading not setted!');
      isShowLoading = true;
    }
    if (isShowLoading) this.turnOnModal();
    return observable
      .do((res: HttpResponse<any>) => {
        //console.log('Response: ' + res);
      }, (err: any) => {
        if (isShowLoading) this.turnOffModal();
        console.log('Caught error: ' + err);
      })
      .finally(() => {
        if (isShowLoading) this.turnOffModal();
      });
  }

  GetHeaders(): HttpHeaders {
    let headers = new HttpHeaders({ 'content-type': 'application/json; charset=utf-8;application/x-www-form-urlencoded' });
    return headers;
  }

  private turnOnModal() {
    this.PendingRequests++;
    if (!this.ShowLoading) {
      this.ShowLoading = true;
      document.body.classList.add("m-page--loading-non-block");
      //console.log('Turned on modal');
    }
    this.ShowLoading = true;
  }

  private turnOffModal() {
    this.PendingRequests--;
    if (this.PendingRequests <= 0) {
      if (this.ShowLoading) {
        this.PendingRequests = 0;
        document.body.classList.remove("m-page--loading-non-block");
      }
      this.ShowLoading = false;
    }
    console.log('Turned off modal');
  }
}
