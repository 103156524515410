/// <reference path="modules/node/node.component.ts" />
import { HttpService } from './Modules/HttpService';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { HeaderbottomComponent } from './headerbottom/headerbottom.component';
import { ToastrModule } from 'ng6-toastr-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SafeHtmlModule } from "./shared/pipe.safehtml";
import { CustomInputModule } from './shared/controls/custominput/custominput.component';
import { SignalRService } from './Modules/SignalRMessage/Services/SignalR.Service';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { NgbDateParserFormatterEsMX } from './shared/date-formater';
import { LoginComponent } from './Modules/Admin/login/login.component';
import { HomeComponent } from './Modules/home/home.component';
import { globals } from './globals';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
//import { DatetimeComponent } from './shared/controls/datetime/datetime.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChartsModule } from 'ng2-charts';
import { GeneralInfoComponent } from './Modules/Dashboard/Views/general-info/general-info.component';
import { SaleByCustomerGroupComponent } from './Modules/Dashboard/Views/sale-by-customer-group/sale-by-customer-group.component';
import { SaleByProductGroupComponent } from './Modules/Dashboard/Views/sale-by-product-group/sale-by-product-group.component';
import { TaskListComponent } from './Modules/Dashboard/Views/task-list/task-list.component';
import { EventListComponent } from './Modules/Dashboard/Views/event-list/event-list.component';
import { NewDiscussionComponent } from './Modules/Dashboard/Views/new-discussion/new-discussion.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CKEditorModule } from 'ng2-ckeditor';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { TreeModule } from 'primeng/components/tree/tree';
import { OnReturnDirective, OnReturnModule } from './shared/OnReturnDirective';
import { QuillModule } from 'ngx-quill'
import { SalebymarketComponent } from './Modules/Dashboard/Views/salebymarket/salebymarket.component';
import { Ng5SliderModule } from 'ng5-slider';
import { SliderModule } from 'primeng/slider';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { NgSelectCustomModule } from 'src/app/shared/controls/customselect/customselect.component';
import { NgXSelectMultipleModule } from 'src/app/shared/controls/ngx-select-multiple/ngx-select-multiple.component';
import { NgXSelectModule } from 'src/app/shared/controls/ngx-select/ngx-select.component';
import { RatingModule } from 'primeng/rating';
import { CustomDiscussionModule } from 'src/app/shared/controls/customdiscussion/Views/customdiscussion.component';
import { ComposeemailModule } from 'src/app/shared/controls/composeemail/composeemail.component';
import { DatetimeModule } from 'src/app/shared/controls/datetime/datetime.component';
import { ServedComponent } from './Modules/Sale/Views/PointOfSale/served/served.component';
//import { MegacrmSearchinputComponent } from './shared/controls/megacrm-searchinput/megacrm-searchinput.component';
//import { NodeWorkflowModule } from './Modules/Node/node.component';
//import { NodeComponent } from './Modules/Node/node.component';
//import { NodesContainerComponent } from './Modules/Node/nodes-container/nodes-container.component';
//import { NodeService } from './Modules/Node/node.service';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    HeaderbottomComponent,
    GeneralInfoComponent,
    LoginComponent,
    SaleByCustomerGroupComponent,
    SalebymarketComponent,
    SaleByProductGroupComponent,
    TaskListComponent,
    EventListComponent,
    NewDiscussionComponent,
    ServedComponent,
   // MegacrmSearchinputComponent
   // NodesContainerComponent
    //DatetimeComponent
    //custominputcomponent
    //NgSelectCustomComponent,
    //DatetimeComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgSelectCustomModule,
    NgXSelectMultipleModule,
    RatingModule,
    FormsModule,
    DatetimeModule,
    //NodeWorkflowModule,
    CustomDiscussionModule,
    ComposeemailModule,
    NgXSelectModule,
    SliderModule,
    ConfirmationPopoverModule,
    AppRoutingModule,
    NgxSmartModalModule.forRoot(),
    DragDropModule,
    BrowserModule,
    CKEditorModule,
    Ng5SliderModule,
    BrowserAnimationsModule,
    TreeModule,
    NgSelectModule,
    ChartsModule,
    CustomInputModule,
    ToastrModule.forRoot(),
    NgbModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SafeHtmlModule, QuillModule.forRoot(), OnReturnModule

  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateParserFormatterEsMX },
    globals,
    SignalRService, HttpService,
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
