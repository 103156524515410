/// <reference path="tasklist.entity.ts" />
import { IEntity } from "../../IEntity.Entity";

export class TaskTagUserEntity extends IEntity {
  ID: string;
  TaskTagID: string;
  UserID: string;
  FullName: string;
  UserName: string;
  CreatedUserID: string;
  CreatedDate: string;
  TotalTagComplete: number;
  TotalTag: number;
  OrderNumberByUser: number;
  public constructor() {
    super();

  }
}
