import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailReceiptEntity } from './EmailReceipt.Entity';
import { HttpService } from "../../HttpService";
import { SearchEntity } from '../../Search.Entity';
import { IEntity } from '../../IEntity.Entity';
import { EmailEntity } from './Email.Entity';
@Injectable({
  providedIn: 'root'
})

export class EmailReceiptService extends HttpService<EmailReceiptEntity>{
  public url: string;

  constructor(private Http: HttpClient) {
    super(Http);

    this.url = "api/EmailReceipts";
  }

  LoadEmail(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<IEntity> {
    SearchParam = SearchParam === undefined ? new SearchEntity() : SearchParam;
    return this.intercept(this.http.get<IEntity>(this.url + '/LoadEmail', {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }
  MaskAsRead(body: Array<EmailReceiptEntity>, IsShowLoading?: boolean): Observable<boolean> {
    return this.intercept(this.http.put<boolean>(`${this.url}/MaskReadEmail`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  DeleteEmailReceipt(body: Array<EmailReceiptEntity>, IsShowLoading?: boolean): Observable<boolean> {
    return this.intercept(this.http.put<boolean>(`${this.url}/DeleteEmailReceipt`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  SendEmail(body: EmailEntity, IsShowLoading?: boolean): Observable<boolean> {
    return this.intercept(this.http.post<boolean>(`${this.url}/SendEmail`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  Download(data: any) {
    return window.open(`${this.url}/${data.ID}` + "/DownloadAttach/" + data.EmailID );
  }
  Reply(body: EmailEntity, IsShowLoading?: boolean): Observable<boolean> {
    return this.intercept(this.http.post<boolean>(`${this.url}/Reply`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
}
