
import { IEntity } from "../../IEntity.Entity";

export class GeneralInfoEntity extends IEntity {
  NewCustomer: number;
  NewOrder: number;
  NewContract: number;
  NewTask: number;
  MonthRevenue: number;
  MonthCost: number;

  public constructor() {
    super();
    this.NewContract = 0;
    this.NewCustomer = 0;
    this.NewOrder = 0;
    this.NewTask = 0;
    this.MonthRevenue = 0;
    this.MonthCost = 0;
  }
}
