import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from "../../HttpService";
import { IEntity } from '../../IEntity.Entity';
import { SearchEntity } from '../../Search.Entity';
import { ToDoListEntity } from './ToDoList.Entity';
import { EventInMonthEntity } from './EventInMonth.Entity';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  public url: string;
  public PendingRequests: number = 0;
  public ShowLoading: boolean = false;
  constructor(private Http: HttpClient) {

    this.url = "api/Dashboards";
  }
  GetGeneralInfo(Type: number, IsShowLoading?: boolean): Observable<number> {
    return this.intercept(this.Http.get<number>(`${this.url}/GetLatestTotal/${Type}`, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: null
    }), IsShowLoading).map(r => r.body);
  }
  GetReportIncreamentSaleAmount(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<ToDoListEntity[]> {
    return this.intercept(this.Http.get<ToDoListEntity[]>(`${this.url}/ReportIncreamentSaleAmount`, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }
  GetToDoList(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<any> {
    return this.intercept(this.Http.get<any>(`${this.url}/GetToDoList`, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }
  GetEventInMonth(SearchParam?: SearchEntity, IsShowLoading?: boolean): Observable<EventInMonthEntity[]> {
    return this.intercept(this.Http.get<EventInMonthEntity[]>(`${this.url}/GetEventInMonth`, {
      observe: 'response',
      headers: this.GetHeaders(),
      params: SearchParam.ToParams()
    }), IsShowLoading).map(r => r.body);
  }
  public intercept(observable: Observable<HttpResponse<any>>, isShowLoading?: boolean): Observable<HttpResponse<any>> {
    if (isShowLoading == null) {
      //console.warn('isShowLoading not setted!');
      isShowLoading = true;
    }
    if (isShowLoading) this.turnOnModal();
    return observable
      .do((res: HttpResponse<any>) => {
        //console.log('Response: ' + res);
      }, (err: any) => {
        if (isShowLoading) this.turnOffModal();
        console.log('Caught error: ' + err);
      })
      .finally(() => {
        if (isShowLoading) this.turnOffModal();
      });
  }

  GetHeaders(): HttpHeaders {
    let headers = new HttpHeaders({ 'content-type': 'application/json; charset=utf-8;application/x-www-form-urlencoded' });
    return headers;
  }

  private turnOnModal() {
    this.PendingRequests++;
    if (!this.ShowLoading) {
      this.ShowLoading = true;
      document.body.classList.add("m-page--loading-non-block");
      //console.log('Turned on modal');
    }
    this.ShowLoading = true;
  }

  private turnOffModal() {
    this.PendingRequests--;
    if (this.PendingRequests <= 0) {
      if (this.ShowLoading) {
        this.PendingRequests = 0;
        document.body.classList.remove("m-page--loading-non-block");
      }
      this.ShowLoading = false;
    }
    console.log('Turned off modal');
  }
}
