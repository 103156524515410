/// <reference path="../../../report/models/report.service.ts" />
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ReportService } from '../../../Report/Models/Report.Service';
import { TotalCustomerRequestEntity } from 'src/app/Modules/Report/Models/TotalCustomerRequest.Entity';
import { window } from 'rxjs/operators';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-sale-by-customer-group',
  templateUrl: './sale-by-customer-group.component.html',
  styleUrls: ['./sale-by-customer-group.component.css']
})
export class SaleByCustomerGroupComponent implements OnInit {

  @ViewChild("divchart") divchart: ElementRef;
  ChartHeight: number = 170;
  LoadChart: boolean = false;
  doughnutChartLabels: string[] = ['', '', '', '', '', '', '', '', '', '', '', ''];
  doughnutChartData: any[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  

  doughnutChartType = 'doughnut';
  
  ChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    responsiveAnimationDuration: 0,
    legend: {
      display: true,
      position: 'top',
      align: 'center'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          label += ': ' + data.datasets[0].data[tooltipItem.index].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return label;
        },
      }
    }
  };

  constructor(public ReportService: ReportService) {
    this.LoadData();
    
    //this.ChartHeight = this.divchart.nativeElement.offsetHeight;
  }
  ngAfterViewInit() {   
    
  }
  ngOnInit() {
    
  }
  
  LoadData() {
    this.ReportService.GetReportSaleByContactCategory(new TotalCustomerRequestEntity()).subscribe(p => {
      this.doughnutChartLabels = [];
      this.doughnutChartData = [];
      p.forEach(data => {
        this.doughnutChartLabels.push(data.name);
        this.doughnutChartData.push(data.y.toString() );
      });
      
      this.LoadChart = true;
    })
  }
}
