import { Component, OnInit, Input, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'megacrm-wysiwyg',
  templateUrl: './megacrm-wysiwyg.component.html',
  styleUrls: ['./megacrm-wysiwyg.component.css']
})
export class MegacrmWysiwygComponent implements OnInit {
  RandomString: string = this.MakeRandomID();
  @Input() ID: string = 'wysiwyg-ContentEditable_' + this.RandomString;
  ButtonBoldID: string = 'wysiwygBold_'+this.RandomString;
  ButtonItalicID: string = 'wysiwygItalic_'+this.RandomString;
  ButtonUnderlineID: string = 'wysiwygUnderline_'+this.RandomString;
  constructor() { }

  ngOnInit() {
  }

  MakeRandomID(){
    let allLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let text = '';
    for(let i = 0; i < 5; i++){
      text += allLetters.charAt(Math.floor(Math.random() * allLetters.length));
    }
    return text;
  }
  WysiwygAction(Action: string, ElementId){
    document.execCommand(Action, false, '');
    this.ActiveBtn(ElementId);
  }
  ActiveBtn(ElementId){
    let elementBtn = document.getElementById(ElementId);
    if(elementBtn.classList.contains("wysiwyg-btn-active")){
      elementBtn.classList.remove("wysiwyg-btn-active");
    }
    else{
      elementBtn.classList.add("wysiwyg-btn-active");
    }
  }
  // Bold(){
  //   document.execCommand('bold', false, '');
  //   let ButtonBoldElement = document.getElementById(this.ButtonBoldID);
  //   if(ButtonBoldElement.classList.contains("wysiwyg-btn-active")){
  //     ButtonBoldElement.classList.remove("wysiwyg-btn-active");
  //   }
  //   else{
  //     ButtonBoldElement.classList.add("wysiwyg-btn-active");
  //   }
  // }

  onKeyPress(event){
    console.log(event);
    if(event.keyCode == 66 || event.keyCode == 98){
      console.log('true');
    }
  }

  OnKeyUp(event){
    if(event.ctrlKey){
      switch(event.keyCode){
        case 66:
            this.ActiveBtn(this.ButtonBoldID);
          return;
        case 73:
          this.ActiveBtn(this.ButtonItalicID);
          return;
        case 85:
          this.ActiveBtn(this.ButtonUnderlineID);
          return;
        default:
          break;
      }
    }
  }
}

@NgModule({
  declarations: [
    MegacrmWysiwygComponent
  ],
  imports: [
    FormsModule, CommonModule, ReactiveFormsModule
  ],
  exports: [
    MegacrmWysiwygComponent
  ]
})
export class MegacrmWysiwygModule { }
