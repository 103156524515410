import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../Models/Dashboard.Service';
import { GeneralInfoEntity } from '../../Models/GeneralInfo.Entity';
@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.css']
})
export class GeneralInfoComponent implements OnInit {
  Entity: GeneralInfoEntity = new GeneralInfoEntity();
  constructor(public DashboardService: DashboardService) {
    this.LoadNewContract();
    this.LoadNewCustomer();
    this.LoadNewOrder();
    this.LoadNewTask();
    this.LoadMonthRevenue();
  }

  ngOnInit() {
  }

  LoadNewCustomer() {
    this.DashboardService.GetGeneralInfo(1).subscribe(p => {
      this.Entity.NewCustomer = p;
    })
  }
  LoadNewOrder() {
    this.DashboardService.GetGeneralInfo(3).subscribe(p => {
      this.Entity.NewOrder = p;
    })
  }
  LoadNewTask() {
    this.DashboardService.GetGeneralInfo(2).subscribe(p => {
      this.Entity.NewTask = p;
    })
  }
  LoadNewContract() {
    this.DashboardService.GetGeneralInfo(4).subscribe(p => {
      this.Entity.NewContract = p;
    })
  }
  LoadMonthRevenue() {
    this.DashboardService.GetGeneralInfo(5).subscribe(p => {
      this.Entity.MonthRevenue = p;
    })
  }
  LoadMonthCost() {
    this.DashboardService.GetGeneralInfo(6).subscribe(p => {
      this.Entity.NewContract = p;
    })
  }
}
