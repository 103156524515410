import { IEntity } from "../../IEntity.Entity";
export class TaskTagAttachmentEntity extends IEntity {
  ID: string;
  UserID: string;
  ProjectID: string;
  TaskTagID: string;
  FileName: string;
  FullPath: string;
  FileData: any;
  AttType: string;
  ShowOnTag: boolean;
  DisplayText: string;

  public constructor() {
    super();
  }
}
