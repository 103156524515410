/// <reference path="../../models/chartbaserequest.entity.ts" />
import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../Models/Dashboard.Service';
import { ToDoListEntity } from '../../Models/ToDoList.Entity';
import { SearchEntity } from 'src/app/Modules/Search.Entity';
import { TaskTagEntity } from 'src/app/Modules/Task/Models/TaskTag.Entity';
import { TaskService } from 'src/app/Modules/Task/Models/Task.Service';
import { globals } from 'src/app/globals';
import { UserEntity } from 'src/app/Modules/Dictionary/Models/User/User.Entity';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToDoStepEntity } from 'src/app/Modules/Task/Models/ToDoStep.Entity';
import { ToDoEntity } from 'src/app/Modules/Task/Models/ToDo.Entity';
import { TaskTagUserEntity } from 'src/app/Modules/Task/Models/TaskTagUser.Entity';
import { MessageEntity } from 'src/app/Modules/SignalRMessage/Models/Message.Entity';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NewFeedService } from 'src/app/Modules/NewFeed/NewFeed.Service';
import { SignalRService } from 'src/app/Modules/SignalRMessage/Services/SignalR.Service';
//import { ChartBaseRequestEntity } from '../../Models/ChartBaseRequest.Entity';
import { formatDate } from '@angular/common';
import { TaskTagManagerEntity } from 'src/app/Modules/Task/Models/TaskTagManager.Entity';
import { TaskTagAttachmentEntity } from 'src/app/Modules/Task/Models/TaskTagAttachment.Entity';
import { AccountService } from 'src/app/Modules/Account/Models/Account.Service';
import { BaseRequestOptions } from '@angular/http';
import { ReportBaseRequestEntity } from 'src/app/Modules/Report/Models/ReportBaseRequest.Entity';

import { WorkflowService } from '../../../Task/Models/Workflow.Service';
import { TaskListEntity } from '../../../task/models/tasklist.entity';
import { WorkflowConnEntity } from 'src/app/Modules/Task/Models/WorkflowConn.Entity';
@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css']
})
export class TaskListComponent implements OnInit {
  listTaskListForChange: Array<TaskListEntity> = [];
  nodes: Array<any> = [];
  connections: Array<any> = [];
  Count: number = 0;
  page: number = 1;
  CurrentReview: string;
  CurrentStar: number;
  Entities: Array<ToDoListEntity> = [];
  IsFollowName: string = "Theo dõi";
  IsReviewer: boolean = true;
  IsSendMailName: string = "Nhắc việc";
  ArraySteps: Array<Array<ToDoStepEntity>> = [];
  SelectedToDoEntity: ToDoListEntity = null;
  SelectedTaskTagEntity: TaskTagEntity = new TaskTagEntity();
  ShowAddNew: boolean = false;
  IsAddNewToDo: boolean = false;
  Droping: boolean = false;
  IsEditDescription: boolean = false;
  today = new Date();
  NewAttachmentEntity: TaskTagAttachmentEntity = new TaskTagAttachmentEntity();
  eSearchEntity: ReportBaseRequestEntity = new ReportBaseRequestEntity();
  listType: Array<any> = [{ Name: "Tất cả", CaseGet: -1 },
    { Name: "Đã hoàn thành", CaseGet: 1 },
    { Name: "Chưa hoàn thành", CaseGet: 2 }
  ];
  TaskLists: Array<TaskListEntity> = [];
  WorkflowConns: Array<WorkflowConnEntity> = [];
  CurrentStepName: string;
  ListPriority: Array<any> = [{ Priority: "LOW", Name: "Thấp", Selected: false, ShowAddNew: false, TotalTagComplete: 0, TotalTag: 0 }, { Priority: "MEDIUM", Name: "Trung bình", Selected: true, ShowAddNew: false, TotalTagComplete: 0, TotalTag: 0 }, { Priority: "IMPORTANT", Name: "Quan trọng", Selected: false, ShowAddNew: false, TotalTagComplete: 0, TotalTag: 0 }, { Priority: "EMERGENCY", Name: "Khẩn cấp", Selected: false, ShowAddNew: false, TotalTagComplete: 0, TotalTag: 0 }];
  //Entity: ChartBaseRequestEntity = new ChartBaseRequestEntity();
  constructor(public DashboardService: DashboardService,
    public TaskService: TaskService,
    public ToastrManager: ToastrManager,
    public NewFeedService: NewFeedService,
    public AccountService: AccountService,
    public ManagerService: AccountService,
  
    private signalrService: SignalRService,
    public WorkflowService: WorkflowService,
    public ngxSmartModalService: NgxSmartModalService,
    private globalparam: globals) {
    this.eSearchEntity.CaseGet = 2;
    this.LoadData();
    
  }

  ngOnInit() {
  }
  PageChange(page: number) {
  this.eSearchEntity.PageIndex = page;
    this.LoadData();
  }
  Refresh() {
    this.eSearchEntity = new ReportBaseRequestEntity();
    this.eSearchEntity.CaseGet = 2;
    this.LoadData();
  }
  LoadData() {
    this.DashboardService.GetToDoList(this.eSearchEntity).subscribe(p => {
      this.Entities = p.ListEntity; 
      this.Count = p.Count;
    })
  }
  ShowTagModal(tag: ToDoListEntity) {
    //this.SelectedTaskTagEntity = tag;
    //Get list TaskList
   
    this.SelectedToDoEntity = tag;
    this.TaskService.GetTaskTagDetail(tag).subscribe(p => {
      if (p.IsFollow)
        this.IsFollowName = "Đang theo dõi";
      else
        this.IsFollowName = "Theo dõi";
      if (p.IsSendMail)
        this.IsSendMailName = "Có nhắc việc";
      else
        this.IsSendMailName = "Nhắc việc";
      Object.assign(this.SelectedTaskTagEntity, p);
      this.CurrentReview = this.SelectedTaskTagEntity.ReviewContent;
      this.CurrentStar = this.SelectedTaskTagEntity.StarNumber;
      this.SelectedTaskTagEntity.UserEntities = [];
      this.SelectedTaskTagEntity.OriginUserEntities = [];
      p.ListUser.forEach(p => {
        let a: UserEntity = new UserEntity();
        a.ID = p.UserID;
        a.FullName = p.FullName;
        a.UserName = p.UserName;
        this.SelectedTaskTagEntity.UserEntities.push(a);
        this.SelectedTaskTagEntity.OriginUserEntities.push(a);
      });
      this.SelectedTaskTagEntity.ManagerEntities = [];
      this.SelectedTaskTagEntity.OriginManagerEntities = [];
      p.ListManager.forEach(p => {
        let a: UserEntity = new UserEntity();
        a.ID = p.UserID;
        a.FullName = p.FullName;
        a.UserName = p.UserName;
        this.SelectedTaskTagEntity.ManagerEntities.push(a);
        this.SelectedTaskTagEntity.OriginManagerEntities.push(a);
        if (p.UserID == this.globalparam.UserEntity.ID)
          this.IsReviewer = true;
      });
      this.UpdateArrayTodoStep();
      this.UpdateStatus();

      this.TaskService.GetWorkflowConn(tag.TaskTableID).subscribe(p => {
        this.WorkflowConns = p;
        this.TaskService.GetTaskList(tag.TaskTableID).subscribe(p => {
          this.TaskLists = p;
          this.GetTaskListForChange();
        });
      });

      this.ngxSmartModalService.getModal('tasktagmodal').open();
    });
  }

  UpdateArrayTodoStep() {
    this.ArraySteps = [];
    let index: number = 0;
    this.SelectedTaskTagEntity.ListToDo.forEach(t => {
      let a: Array<ToDoStepEntity> = [];
      this.SelectedTaskTagEntity.ListToDoStep.forEach(step => {
        if (step.ToDoID == t.ID) {
          a.push(step);
        }
      });
      t.TotalStep = a.length;
      this.ArraySteps.push(a);

    });
  }

  UpdateStatus() {
    if (this.SelectedTaskTagEntity.ListToDo.some((item) => item.PercentComplete < 100 || item.PercentComplete == null)) {
      this.SelectedTaskTagEntity.State = 0;
      this.SelectedTaskTagEntity.IsComplete = false;
    }
    else {
      if (this.SelectedTaskTagEntity.ListToDo.length > 0) {
        this.SelectedTaskTagEntity.State = 1;
        //if (this.SelectedTaskTagEntity.ListToDoStep.length > 0)
        this.SelectedTaskTagEntity.IsComplete = true;
      }
      else
        this.SelectedTaskTagEntity.State = 2;
    }
  }
  //GetError(Dic, Key: string) {
  //  if (this.HasOwnProperty(Dic, Key))
  //    return Dic[Key];
  //  else
  //    return null;
  //}

  //HasOwnProperty(Dic, Key: string) {
  //  if (Dic !== null && Dic != undefined) {
  //    return Dic.hasOwnProperty(Key);
  //  } else {
  //    return false;
  //  }
  //}
  EditDescription() {
    this.IsEditDescription = true;
  }
  CancelEditDescription() {
    this.IsEditDescription = false;
  }
  SaveChangeDescription() {
    this.IsEditDescription = false;
    this.TaskService.TaskTagChange(this.SelectedTaskTagEntity).subscribe(p => { });
  }

  AddNewToDoStep(entity: ToDoEntity) {
    this.SelectedTaskTagEntity.ListToDo.forEach(p => {
      p.IsAddNew = false;
      if (p.ToDoStepEntity != null)
        p.ToDoStepEntity = new ToDoStepEntity();
    })
    entity.ToDoStepEntity = new ToDoStepEntity();
    entity.ToDoStepEntity.TaskTagID = this.SelectedTaskTagEntity.ID;
    entity.ToDoStepEntity.ToDoID = entity.ID;
    entity.IsAddNew = true;
  }
  SaveNewToDoStep(entity: ToDoEntity, i: number) {

    if (entity.ToDoStepEntity.StepName != null && entity.ToDoStepEntity.StepName.trim() != '') {
      entity.ToDoStepEntity.TaskTagID = this.SelectedTaskTagEntity.ID;
      entity.ToDoStepEntity.ToDoID = entity.ID;
      entity.ToDoStepEntity.Weight = 5;
      this.TaskService.CreateToDoStep(entity.ToDoStepEntity).subscribe(p => {
        this.SelectedTaskTagEntity.ListToDoStep.push(p);
        entity.ToDoStepEntity = new ToDoStepEntity();
        entity.IsAddNew = false;
        this.SelectedTaskTagEntity.NumberTotal = this.SelectedTaskTagEntity.NumberTotal + 1;
        this.UpdateArrayTodoStep();

        entity.TotalComplete = 0;
        entity.TotalWeight = 0;
        this.ArraySteps[i].forEach(p => {
          if (p.IsComplete)
            entity.TotalComplete += p.Weight;
          entity.TotalWeight += p.Weight;
        });
        entity.PercentComplete = Math.round((entity.TotalWeight > 0) ? (entity.TotalComplete * 100) / entity.TotalWeight : 0)

        this.UpdateStatus();
      });
    }
    else
      this.CancelAddNewToDoStep(entity);
  }
  UpdateToDoStep(entity: ToDoStepEntity, todo: ToDoEntity, i: number) {
    if (entity.Weight == null || entity.Weight == undefined || entity.Weight == 0) {
      this.ToastrManager.warningToastr("Bạn chưa chọn trọng số.", "Thông báo");
    }
    else {
      this.TaskService.UpdateToDoStep(entity).subscribe(p => {

        if (entity.IsComplete) {
          this.SelectedTaskTagEntity.NumberComplete++;
          //todo.TotalComplete = todo.TotalComplete + entity.Weight;
        }
        else {
          this.SelectedTaskTagEntity.NumberComplete--;
          //todo.TotalComplete = todo.TotalComplete - entity.Weight;
        }
        todo.TotalComplete = 0;
        todo.TotalWeight = 0;
        this.ArraySteps[i].forEach(p => {
          if (p.IsComplete)
            todo.TotalComplete += p.Weight;
          todo.TotalWeight += p.Weight;
        });
        todo.PercentComplete = Math.round((todo.TotalWeight > 0) ? (todo.TotalComplete * 100) / todo.TotalWeight : 0)
        this.UpdateStatus();
       // this.UpdateTaskListInfo();
      });
    }

  }
  UpdateToDoStep1(entity: ToDoStepEntity, todo: ToDoEntity, i: number, event) {

    if (event == null || event.Weight == null || event.Weight == undefined || event.Weight == 0) {
      this.ToastrManager.warningToastr("Bạn chưa chọn trọng số.", "Thông báo");
    }
    else {
      entity.Weight = event.Weight
      this.TaskService.UpdateToDoStep(entity).subscribe(p => {
        todo.TotalComplete = 0;
        todo.TotalWeight = 0;
        this.ArraySteps[i].forEach(p => {
          if (p.IsComplete)
            todo.TotalComplete += p.Weight;
          todo.TotalWeight += p.Weight;
        });
        todo.PercentComplete = Math.round((todo.TotalWeight > 0) ? (todo.TotalComplete * 100) / todo.TotalWeight : 0)
        this.UpdateStatus();
      });
    }
  }
  UpdateStatusSpecial(entity: TaskTagEntity) {
    if (entity.ListToDo.some((item) => item.PercentComplete < 100 || item.PercentComplete == null)) {
      entity.State = 0;
      entity.IsComplete = false;
    }
    else {
      if (entity.ListToDo.length > 0) {
        entity.State = 1;
        //if (this.SelectedTaskTagEntity.ListToDoStep.length > 0)
        entity.IsComplete = true;
      }
      else
        entity.State = 2;
    }
  }
  
  DeleteToDoStep(entity: ToDoStepEntity, todo: ToDoEntity, i: number) {
    this.TaskService.DeleteToDoStep(entity.ID, entity.ToDoID).subscribe(p => {
      this.SelectedTaskTagEntity.ListToDoStep.splice(this.SelectedTaskTagEntity.ListToDoStep.indexOf(entity), 1);
      this.UpdateArrayTodoStep();

      todo.TotalComplete = 0;
      todo.TotalWeight = 0;
      this.ArraySteps[i].forEach(p => {
        if (p.IsComplete)
          todo.TotalComplete += p.Weight;
        todo.TotalWeight += p.Weight;
      });
      todo.PercentComplete = Math.round((todo.TotalWeight > 0) ? (todo.TotalComplete * 100) / todo.TotalWeight : 0)

      this.UpdateStatus();
    })
  }
  CancelAddNewToDoStep(entity: ToDoEntity) {
    entity.ToDoStepEntity = new ToDoStepEntity();
    entity.IsAddNew = false;
  }
  AddNewToDo() {
    this.SelectedTaskTagEntity.ToDoEntity = new ToDoEntity();
    this.SelectedTaskTagEntity.ToDoEntity.TaskTagID = this.SelectedTaskTagEntity.ID;

    this.IsAddNewToDo = true;
  }
  SaveNewToDo() {
    if (this.SelectedTaskTagEntity.ToDoEntity.ToDoName != null && this.SelectedTaskTagEntity.ToDoEntity.ToDoName.trim() != '') {
      this.SelectedTaskTagEntity.ToDoEntity.TaskTagID = this.SelectedTaskTagEntity.ID;
      this.TaskService.CreateToDo(this.SelectedTaskTagEntity.ToDoEntity).subscribe(p => {
        this.SelectedTaskTagEntity.ListToDo.push(p);
        this.SelectedTaskTagEntity.ToDoEntity = new ToDoEntity();
        this.IsAddNewToDo = false;
        this.UpdateArrayTodoStep();
        this.UpdateStatus();
      });
    }
    else
      this.CancelAddNewToDo();
  }
  CancelAddNewToDo() {
    this.IsAddNewToDo = false;
  }
  ShowToDoDetail(entity: ToDoEntity) {
    entity.ShowDetail = !entity.ShowDetail;
  }

  AddMultipleUser(UserEntities: UserEntity[]) {
    this.SelectedTaskTagEntity.ListUser = [];
    this.SelectedTaskTagEntity.UserEntities = UserEntities;
    this.SelectedTaskTagEntity.UserEntities.forEach(p => {
      let a: TaskTagUserEntity = new TaskTagUserEntity();
      a.UserID = p.ID;
      a.TaskTagID = this.SelectedTaskTagEntity.ID;
      this.SelectedTaskTagEntity.ListUser.push(a);
    });
    //Call API
    this.TaskService.UpdateTaskTagUser(this.SelectedTaskTagEntity).subscribe(() => {
      this.sendMessage();
    });
  }
  sendMessage() {
    //if (this.canSendMessage) {
    this.SelectedTaskTagEntity.UserEntities.forEach(p => {
      if (!this.SelectedTaskTagEntity.OriginUserEntities.some((item) => item.ID == p.ID)) {
        let Message = new MessageEntity();
        Message.CreatedUserID = this.globalparam.UserEntity.ID;
        Message.CreatedUserName = this.globalparam.UserEntity.UserName;
        Message.UserID = p.ID;
        Message.UserName = p.UserName;
        Message.Action = "CREATE";
        Message.ActionName = "thêm quyền thực hiện";
        Message.IsRead = false;
        Message.TypeName = "Thẻ công việc";
        Message.TypeLink = "";
        Message.RefID = this.SelectedTaskTagEntity.ID;
        Message.RefNo = this.SelectedTaskTagEntity.TaskTagName;
        Message.RefName = this.SelectedTaskTagEntity.TaskTagName;
        Message.CompanyID = this.globalparam.UserEntity.CompanyID;
        Message.Type = 99;
        Message.MessageTime = formatDate(this.today, 'dd/MM/yyyy hh:mm:ss', 'en-US', '+7');
        this.NewFeedService.Create(Message).subscribe(p => {
          this.signalrService.sendMessage(Message);
        });
      }

    });
    this.SelectedTaskTagEntity.OriginUserEntities.forEach(p => {
      if (!this.SelectedTaskTagEntity.UserEntities.some((item) => item.ID == p.ID)) {
        let Message = new MessageEntity();
        Message.CreatedUserID = this.globalparam.UserEntity.ID;
        Message.CreatedUserName = this.globalparam.UserEntity.UserName;
        Message.UserID = p.ID;
        Message.UserName = p.UserName;
        Message.Action = "DELETE";
        Message.ActionName = "bỏ quyền thực hiện";
        Message.IsRead = false;
        Message.TypeName = "Thẻ công việc";
        Message.TypeLink = "";
        Message.RefID = this.SelectedTaskTagEntity.ID;
        Message.RefNo = this.SelectedTaskTagEntity.TaskTagName;
        Message.RefName = this.SelectedTaskTagEntity.TaskTagName;
        Message.CompanyID = this.globalparam.UserEntity.CompanyID;
        Message.Type = 99;
        Message.MessageTime = formatDate(this.today, 'dd/MM/yyyy hh:mm:ss', 'en-US', '+7');
        this.NewFeedService.Create(Message).subscribe(p => {
          this.signalrService.sendMessage(Message);
        });
      }

    });
    //update origin
    this.SelectedTaskTagEntity.OriginUserEntities = this.SelectedTaskTagEntity.UserEntities;
  }

  sendManagerMessage() {
    //if (this.canSendMessage) {
    this.SelectedTaskTagEntity.ManagerEntities.forEach(p => {
      if (!this.SelectedTaskTagEntity.OriginManagerEntities.some((item) => item.ID == p.ID)) {
        let Message = new MessageEntity();
        Message.CreatedUserID = this.globalparam.UserEntity.ID;
        Message.CreatedUserName = this.globalparam.UserEntity.UserName;
        Message.UserID = p.ID;
        Message.UserName = p.UserName;
        Message.Action = "CREATE";
        Message.ActionName = "thêm quyền theo dõi";
        Message.IsRead = false;
        Message.TypeName = "Thẻ công việc";
        Message.TypeLink = "";
        Message.RefID = this.SelectedTaskTagEntity.ID;
        Message.RefNo = this.SelectedTaskTagEntity.TaskTagName;
        Message.RefName = this.SelectedTaskTagEntity.TaskTagName;
        Message.CompanyID = this.globalparam.UserEntity.CompanyID;
        Message.Type = 99;
        Message.MessageTime = formatDate(this.today, 'dd/MM/yyyy hh:mm:ss', 'en-US', '+7');
        this.NewFeedService.Create(Message).subscribe(p => {
          this.signalrService.sendMessage(Message);
        });
      }

    });

    this.SelectedTaskTagEntity.OriginManagerEntities.forEach(p => {
      if (!this.SelectedTaskTagEntity.ManagerEntities.some((item) => item.ID == p.ID)) {
        let Message = new MessageEntity();
        Message.CreatedUserID = this.globalparam.UserEntity.ID;
        Message.CreatedUserName = this.globalparam.UserEntity.UserName;
        Message.UserID = p.ID;
        Message.UserName = p.UserName;
        Message.Action = "DELETE";
        Message.ActionName = "bỏ quyền theo dõi";
        Message.IsRead = false;
        Message.TypeName = "Thẻ công việc";
        Message.TypeLink = "";
        Message.RefID = this.SelectedTaskTagEntity.ID;
        Message.RefNo = this.SelectedTaskTagEntity.TaskTagName;
        Message.RefName = this.SelectedTaskTagEntity.TaskTagName;
        Message.CompanyID = this.globalparam.UserEntity.CompanyID;
        Message.Type = 99;
        Message.MessageTime = formatDate(this.today, 'dd/MM/yyyy hh:mm:ss', 'en-US', '+7');
        this.NewFeedService.Create(Message).subscribe(p => {
          this.signalrService.sendMessage(Message);
        });
      }

    });

    //update origin
    this.SelectedTaskTagEntity.OriginManagerEntities = this.SelectedTaskTagEntity.ManagerEntities;
  }

  AddMultipleManager(UserEntities: UserEntity[]) {

    this.SelectedTaskTagEntity.ListManager = [];
    this.SelectedTaskTagEntity.ManagerEntities = UserEntities;
    this.SelectedTaskTagEntity.ManagerEntities.forEach(p => {
      let a: TaskTagManagerEntity = new TaskTagManagerEntity();
      a.UserID = p.ID;
      a.TaskTagID = this.SelectedTaskTagEntity.ID;
      this.SelectedTaskTagEntity.ListManager.push(a);
    });

    if (this.SelectedTaskTagEntity.ListManager.some((item) => item.UserID == this.globalparam.UserEntity.ID))
      this.IsReviewer = true;
    else
      this.IsReviewer = false;

    this.TaskService.UpdateTaskTagUser(this.SelectedTaskTagEntity).subscribe(() => {
      this.sendManagerMessage();
    });
  }
  ChangeFromDate(event) {
    this.SelectedTaskTagEntity.FromDate = event;
    this.TaskService.TaskTagChange(this.SelectedTaskTagEntity).subscribe(p => { });
  }
  ChangeFinishDate(event) {
    this.SelectedTaskTagEntity.FinishDate = event;
    this.TaskService.TaskTagChange(this.SelectedTaskTagEntity).subscribe(p => { });
  }
  ChangeExpiredDate(event) {
    this.SelectedTaskTagEntity.ExpiredDate = event;

    this.TaskService.TaskTagChange(this.SelectedTaskTagEntity).subscribe(p => {
      if (formatDate(this.today, 'dd/MM/yyyy hh:mm:ss', 'en-US', '+7') > this.SelectedTaskTagEntity.ExpiredDate && this.SelectedTaskTagEntity.ExpiredDate != "" && this.SelectedTaskTagEntity.ExpiredDate != undefined && this.SelectedTaskTagEntity.ExpiredDate != null)
        this.SelectedTaskTagEntity.IsExpired = true;
      else
        this.SelectedTaskTagEntity.IsExpired = false;
    });
  }
  HasOwnProperty(Dic, Key: string) {
    if (Dic !== null && Dic != undefined) {
      return Dic.hasOwnProperty(Key);
    } else {
      return false;
    }
  }

  UploadAttach(event) {
    if (this.SelectedTaskTagEntity != undefined && this.SelectedTaskTagEntity != null) {
      const target: DataTransfer = <DataTransfer>(event.target);

      let TotalFile: number;
      TotalFile = target.files.length + this.SelectedTaskTagEntity.ListAttachment.length;
      //if (target.files.length != 1) {
      //  event.target.parentNode.reset();
      //  throw new Error("Hệ thống không cho phép tải nhiều file.")
      //}


      for (let i = 0; i < target.files.length; i++) {
        const reader = new FileReader();
        let f = target.files[i];
        reader.readAsDataURL(f);
        reader.onloadend = (e => { //callback after files finish loading
          let eAttachment: TaskTagAttachmentEntity = new TaskTagAttachmentEntity();

          eAttachment.FileName = f.name;;
          eAttachment.FileData = reader.result;
          eAttachment.FileData = eAttachment.FileData.substr(eAttachment.FileData.indexOf(',') + 1);
          this.SelectedTaskTagEntity.ListAttachment.push(eAttachment);
          //Update
          if (this.SelectedTaskTagEntity.ListAttachment.length == TotalFile)
            this.TaskService.UpdateTaskTagInfo(this.SelectedTaskTagEntity).subscribe(p => {
              this.SelectedTaskTagEntity.ListAttachment = p.ListAttachment;
            });
          //Đoạn này gán soure base64 cho image tag
        });
      }



    }
    else
      this.ToastrManager.warningToastr("Bạn chưa chọn thẻ công việc.", "Thông báo");
  }

  

  Follow() {
    this.TaskService.UpdateTaskTagUserFollow(this.SelectedTaskTagEntity).subscribe(p => {
      this.SelectedTaskTagEntity.IsFollow = p.IsFollow;
      if (this.SelectedTaskTagEntity.IsFollow)
        this.IsFollowName = "Đang theo dõi";
      else
        this.IsFollowName = "Theo dõi";
    });
  }

  IsSendMail() {
    if ((this.SelectedTaskTagEntity.ExpiredDate == undefined || this.SelectedTaskTagEntity.ExpiredDate.trim() == "") && !this.SelectedTaskTagEntity.IsSendMail)
      this.ToastrManager.warningToastr("Bạn chưa chọn hạn hoàn thành.", "Thông báo");
    else {
      this.TaskService.UpdateTaskTagIsSendMail(this.SelectedTaskTagEntity).subscribe(p => {
        this.SelectedTaskTagEntity.IsSendMail = p.IsSendMail;
        if (this.SelectedTaskTagEntity.IsSendMail)
          this.IsSendMailName = "Có nhắc việc";
        else
          this.IsSendMailName = "Nhắc việc";
      });
    }

  }

  DeleteAtt(att: TaskTagAttachmentEntity) {
    let mes: string;
    mes = 'Bạn có chắc muốn xóa tệp đính kèm đã chọn không?';
    let ConfirmBox = confirm(mes);
    if (ConfirmBox) {
      this.TaskService.DeleteTaskTagAttachment(att.TaskTagID, att.ID, att.FileName).subscribe(p => {
        if (p)
          this.SelectedTaskTagEntity.ListAttachment.splice(this.SelectedTaskTagEntity.ListAttachment.indexOf(att), 1);
      })
    }
  }

  GetFileName(name: string) {
    if (name.length > 30)
      return name.substring(0, 30) + "...";
    else
      return name;
  }
  GetError(Dic, Key: string) {
    if (this.HasOwnProperty(Dic, Key))
      return Dic[Key];
    else
      return null;
  }


  SaveNewTagInList(ChoosenTaskListEntity) {

  }
  sliderEvent(event, entity: ToDoEntity) {

    entity.PercentComplete = event.value;
    this.TaskService.UpdateToDo(entity).subscribe(p => {
      this.UpdateStatus();
      this.ToastrManager.successToastr("Cập nhật tiến độ thành công.", "Thông báo");
    });

  }

  SaveReview() {
    if (this.CurrentReview != this.SelectedTaskTagEntity.ReviewContent || this.CurrentStar != this.SelectedTaskTagEntity.StarNumber) {
      this.TaskService.ReviewTaskTag(this.SelectedTaskTagEntity).subscribe(p => {
        if (p) {
          this.CurrentReview = this.SelectedTaskTagEntity.ReviewContent;
          this.CurrentStar = this.SelectedTaskTagEntity.StarNumber;
          this.ToastrManager.successToastr("Đánh giá kết quả công việc thành công.", "Thông báo");
        }
      });
    }
  }
  UpdateTaskTagStatus() {
    this.TaskService.TaskTagChange(this.SelectedTaskTagEntity).subscribe(p => { });
  }

  handleRate(event) {
    this.CurrentStar = event.value;
    this.SelectedTaskTagEntity.StarNumber = event.value;
    this.TaskService.ReviewTaskTag(this.SelectedTaskTagEntity).subscribe(p => {
      if (p) {

        this.ToastrManager.successToastr("Đánh giá kết quả công việc thành công.", "Thông báo");
      }
    });
  }

  GetUrl(att: TaskTagAttachmentEntity) {
    return window.location.origin + '/assets/CRMFiles/' + att.FullPath;
  }

  GetHost(att: TaskTagAttachmentEntity) {

    let host: string = "";
    if (att.AttType == "LINK") {
      var matches = att.FileName.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
      // extract hostname (will be null if no match is found)
      if (matches != null) {
        host = matches[0];

        return host + '/favicon.ico';
      }
      else
        return "";
    }
    else
      return "";

  }

  UpdateShowOnTag(att: TaskTagAttachmentEntity) {
    this.SelectedTaskTagEntity.ListAttachment.forEach(a => {
      if (a.ID != att.ID)
        a.ShowOnTag = false;
    });
    this.TaskService.UpdateTaskTagAttachment(att).subscribe(p => {
      //if (p) {

      //  this.ToastrManager.successToastr("Đánh giá kết quả công việc thành công.", "Thông báo");
      //}
    });
  }

  ShowAddAttachment() {
    this.NewAttachmentEntity = new TaskTagAttachmentEntity();
    this.NewAttachmentEntity.FileName = "";
    this.NewAttachmentEntity.DisplayText = "";
    this.ngxSmartModalService.getModal('addNewLinkAttachment').open();
  }

  SaveNewAttachment() {
    if ((this.NewAttachmentEntity.FileName == undefined || this.NewAttachmentEntity.FileName.trim() == "")) {
      this.ToastrManager.warningToastr("Tên liên kết không được để trống.", "Thông báo");
    }
    else {
      if (this.NewAttachmentEntity.DisplayText == undefined || this.NewAttachmentEntity.DisplayText.trim() == "")
        this.NewAttachmentEntity.DisplayText = this.NewAttachmentEntity.FileName;

      this.NewAttachmentEntity.AttType = 'LINK';
      this.NewAttachmentEntity.ShowOnTag = false;

      this.SelectedTaskTagEntity.ListAttachment.push(this.NewAttachmentEntity);
      //Update

      this.TaskService.UpdateTaskTagInfo(this.SelectedTaskTagEntity).subscribe(p => {
        this.SelectedTaskTagEntity.ListAttachment = p.ListAttachment;
        this.ToastrManager.successToastr("Thêm liên kết thành công.", "Thông báo");
        this.ngxSmartModalService.getModal('addNewLinkAttachment').close();
      });
    }
  }

  CheckIfFileIsImage(fileName: string) {
    if (fileName.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
      return true;
    } else {
      return false;
    }
  }
  deleteenpoint() {
   // this.nodeService.deleteAllEnpoint();
  }
  ShowWorkflow() {
    this.nodes = [];
    this.connections = [];
    //this.nodeService.deleteAllConnection();
    //this.nodeService.deleteAllEnpoint();
    //this.nodeService.clear();
    //this.GetWorkflowByID(this.SelectedToDoEntity.WorkflowID);
    //this.ngxSmartModalService.getModal('viewworkflow').open();
  }

  GetWorkflowByID(Id: string) {
    this.WorkflowService.GetId(Id).subscribe(p => {

      this.nodes = p.ListProcess;
      this.connections = p.ListConnection;

      //for (let i: number = 0; i < this.nodes.length; i++) {
      //  this.nodeService.addDynamicNode(this.nodes[i]);
      //}

      //setTimeout(() => {
      //  this.connections.forEach(connection => {
      //    this.nodeService.addConnection(connection);
      //  });
      //})

    });
  }

  GetTaskListForChange() {
    this.listTaskListForChange = [];
    //Get danh sach List de chuyen buoc
    this.TaskLists.forEach(t => {
      if (t.ID == this.SelectedTaskTagEntity.TaskListID)
        this.CurrentStepName = t.Name;
      if (this.WorkflowConns.find(x => (x.FromID == this.SelectedTaskTagEntity.StepID + "_bottom" || x.FromID == this.SelectedTaskTagEntity.StepID + "_top") && (x.ToID == t.StepID + "_left" || x.ToID == t.StepID + "_right")) != null) {
        this.listTaskListForChange.push(t);
      }
    })
  }

  ChangeStep(step: TaskListEntity) {

    let iPrevIndex: number = this.TaskLists.findIndex(x => x.ID == this.SelectedTaskTagEntity.TaskListID);
    let iCurrentIndex: number = this.TaskLists.findIndex(x => x.ID == step.ID);

    this.SelectedTaskTagEntity.TaskListID = step.ID;
    this.SelectedTaskTagEntity.StepID = step.StepID;
    this.SelectedTaskTagEntity.OrderNumber = 0;
    this.TaskService.UpdateTaskTag(this.SelectedTaskTagEntity).subscribe(t => {

    
      this.GetTaskListForChange();
     
    });

  }

}
