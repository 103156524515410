import { SearchEntity } from "../../Search.Entity";

export class ReportBaseRequestEntity extends SearchEntity {
  FromDate: string;
  ToDate: string;
  ContactID: string;
  StringContactID: boolean;
  ContactCode: string;
  ContactName: string;
  ContactCategoryID: string;
  ContactTypeID: string;
  StageID: string;
  ProductCategoryID: string;
  ProductID: string;
  ProductCode: string;
  ProductName: string;
  PeriodType: string;
  PeriodTypeName: string;
  PeriodValue: string;
  ContactSourceID: string;
  StringEmployeeID: string;
  EmployeeName: string;
  RefType: number;
  PageIndex: number;
  PageSize: number;
  CaseGet: number;
  CaseGetName: string;
  public constructor() {
    super();
  }
}
